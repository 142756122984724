import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { useEventListener } from "@jmc/utils/hooks/useEventListener";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import isEqual from "lodash/isEqual";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    name: string;
    type?: EventTypes;
    checkVisibility?: boolean;
}

export const NavigationImpression: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, name, type = EventTypes.NAVIGATION_IMPRESSION, checkVisibility = true } = props;
    const { interacted } = useCookieInteraction();
    const navigationContainer = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(navigationContainer);
    const pageImpressionTracked = useSelector(
        ({ analytics }: { analytics: { pageImpressionTracked: boolean } }) => analytics.pageImpressionTracked,
        isEqual,
    );

    useEffect(() => {
        if (isVisible && pageImpressionTracked && interacted) {
            analyticstracker().trackImpression(type, { checkVisibility: checkVisibility });
        }
    }, [navigationContainer, isVisible, pageImpressionTracked]);

    // track clicks registering the id of the clicked element
    useEventListener(
        "click",
        (e: Event) => {
            const path = (e as any)?.composedPath() || [];

            // keep the clicks within this navigation container
            for (let x = 0; x < path.length; x++) {
                const step = path[x];
                const container = step?.dataset?.trackingNavigationContainer;
                if (container && container === name) {
                    // if the first found container is this one then we continue
                    break;
                } else if (container && container !== name) {
                    // if the first found container is not this one then we exit
                    return;
                }
            }

            // look for the first id in  the click path to use as linkname
            let linkname: string = undefined;
            for (let x = 0; x < path.length; x++) {
                const step = path[x];

                // don't look outside of the navigation container
                if (step === navigationContainer.current) {
                    break;
                }
                // return the first found id
                if (step.getAttribute("id") !== null) {
                    linkname = step.getAttribute("id");
                    break;
                }
                // return data linkname if a data-linkname attribute is present
                if (step?.getAttribute("data-linkname") !== null) {
                    linkname = step.getAttribute("data-linkname");
                    break;
                }
            }
            // only if an id is found with the click path we track it
            if (linkname) {
                analyticstracker().trackInteraction(e, {
                    changeEvent: EventTypes.NAVIGATION_CLICK,
                    addData: { info: { linkname } },
                });
            }
        },
        navigationContainer,
    );

    return (
        <div
            role="none"
            data-tracking-event={type}
            data-tracking-info={JSON.stringify({ name })}
            data-tracking-navigation-container={name}
            style={{ height: "100%" }}
            ref={navigationContainer}
        >
            {children}
        </div>
    );
};
