import createApiModule from "../create-api-module";
/**
 * Obtain the currently authenticated users profile
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/get-hcps-codsId-profile
 */
const { reducer, actions } = createApiModule("profile", () => `/hcps/profile`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

interface PersonaSegmentation {
    product: string;
    personaSegment: string;
}
export interface Profile {
    codsId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    isValidated: boolean;
    locale: string;
    country: string;
    specialty: string;
    internationalSpecialty: string;
    individualType: string;
    isExpert: boolean;
    personaSegmentation: PersonaSegmentation[];
    webId: string;
}
