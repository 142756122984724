import React, { useContext } from "react";

export const localeContext = React.createContext("");
export const useLocale = (): string => useContext(localeContext);
export const useDefaultOrAppLocale = (): string => process.env.GATSBY_OVERRIDE_LOCALE ?? useContext(localeContext);

export const LocaleProvider = (props: { locale: string; children: React.ReactNode }): JSX.Element => {
    return <localeContext.Provider value={props.locale}>{props.children}</localeContext.Provider>;
};

export const withLocale = <T extends object>(Component: React.ReactNode): ((props: Partial<T>) => JSX.Element) => {
    const WithLocale = (props: Partial<T>): JSX.Element => (
        <localeContext.Consumer>
            {(locale: string): JSX.Element => <Component {...props} locale={locale} />}
        </localeContext.Consumer>
    );
    return WithLocale;
};
