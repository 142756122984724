import React from "react";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    locale: string;
}

const FontWrapper: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, locale } = props;
    /* Array of languages which has to include Verdana Pro font */
    const langArray = ["el-gr", "he-il"];
    const lang = locale?.split("-");

    const hasRebrand = process.env.GATSBY_JNJ_BRANDED === "full";
    if (hasRebrand) {
        if ((lang != undefined && lang?.[0] == "ru") || locale === "el-gr") {
            const variables = {
                "--text-font": "Noto Sans",
                "--title-font": "Noto Serif",
            } as React.CSSProperties;
            return (
                <div style={variables} data-test-id="font-family">
                    {children}
                </div>
            );
        }
        if (lang != undefined && lang?.[0] == "ar") {
            const variables = {
                "--text-font": `"Noto Sans Arabic", "Noto Sans"`,
                "--title-font": `"Noto Naskh Arabic", "Noto Serif"`,
            } as React.CSSProperties;
            return (
                <div style={variables} data-test-id="font-family">
                    {children}
                </div>
            );
        }
        if (locale === "he-il") {
            const variables = {
                "--text-font": `"Noto Sans Hebrew", "Noto Sans"`,
                "--title-font": `"Noto Serif Hebrew", "Noto Serif"`,
            } as React.CSSProperties;
            return (
                <div style={variables} data-test-id="font-family">
                    {children}
                </div>
            );
        }
    } else if ((lang != undefined && lang?.[0] == "ru") || langArray.includes(locale)) {
        const variables = {
            "--text-font": "Verdana Pro",
            "--title-font": "Verdana Pro",
        } as React.CSSProperties;
        return (
            <div style={variables} data-test-id="font-family">
                {children}
            </div>
        );
    }

    return <>{children}</>;
};

export default FontWrapper;
