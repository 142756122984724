import React from "react";
import style from "./style.module.scss";
import { Typography } from "../Typography/Typography";

interface PropTypes {
    children: JSX.Element | string;
    author?: string;
}

export const Quote = ({ children, author }: PropTypes) => {
    return (
        <q className={style.quote}>
            <div>
                <Typography variant="h4" color="inherit">
                    {children}
                </Typography>
                {author && (
                    <Typography variant="h5" color="inherit">
                        - {author}
                    </Typography>
                )}
            </div>
        </q>
    );
};

Quote.displayName = "Quote";
