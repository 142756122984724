import createApiModule from "../create-api-module";
/**
 * Obtain the courses available on moodle
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/get-lms-certificates
 */
const { reducer, actions } = createApiModule("privacyPolicy", ({ policyId }) => `/privacy-policy/${policyId}`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface PrivacyPolicy {
    lastPublishedDate: string;
}
