import { i18n } from "i18next";
import { CMSTranslationNode, CMSTranslations } from "types/CMSTranslations";

import createI18nTranslations from "./createI18nTranslations";

const {
    translations: { nsToContextMapping },
} = require("../../../content-stack-flat-config.json");

/**
 * A memoized function to do the reversed mapping of values to keys
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getKeyBasedOnValue = (() => {
    const mem: { [x: string]: string } = {};

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return (input: string): string => {
        if (mem[input]) return mem[input];

        const result = Object.keys(nsToContextMapping).find((key) => nsToContextMapping[key] === input);
        mem[input] = result;

        return result;
    };
})();

/**
 * Loads translations for a given component
 *
 * @param nodes array of CMSTranslationNodes
 */
export const loadTranslations = (i18n: i18n, nodes: CMSTranslationNode[]): void => {
    const mapping: { [lng: string]: { [context: string]: CMSTranslations[] } } = {};

    // assign the nodes on their respective key based on the context
    nodes.forEach((v) => {
        if (!v.context || v.context.length === 0) return;

        const lng = v.locale;
        v.context.forEach((ctx) => {
            const key = getKeyBasedOnValue(ctx);

            if (!mapping[lng]) mapping[lng] = {};
            if (!mapping[lng][key]) mapping[lng][key] = [];
            mapping[lng][key].push(v as unknown as CMSTranslations);
        });
    });

    // load the mapping into i18n using the key of the mapping as the namespace
    Object.keys(mapping).forEach((lng) => {
        Object.keys(mapping[lng]).forEach((ctx) => {
            const pieces = lng.split("-");
            const lngCode = pieces.length > 1 ? `${pieces[0]}-${pieces[1].toUpperCase()}` : pieces[0];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            i18n.addResourceBundle(lngCode, ctx, createI18nTranslations(mapping[lng][ctx] as any), true, true);
        });
    });
};
