import cleanCSSIdentifier from "@jmc/utils/utils/clean-css-identifier";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { InterestingLinks } from "./Components/InterestingLinks";
import { MegaMenuContextProvider } from "./MegaMenuContext";
import { MenuItem } from "./Components/MenuItem";
import { SubMenu } from "./Components/SubMenu";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { keyHandler } from "@jmc/utils/utils/keyHandler";
import { Icon, jnjChevronLeft, jnjChevronRight } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { getLanguage } from "@jmc/solid-design-system/src/utils/languages";

import style from "./style.module.scss";
import classnames from "classnames";
import { Wrapper } from "./Components/Wrapper";
import { useScreenSize } from "@jmc/utils/hooks/useScreenSize";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

interface MegaMenuProps {
    interestingLinks?: JSX.Element;
    languageSelector?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    locale: string;
    backLiteral?: string;
    scrollLeftLiteral?: string;
    scrollRightLiteral?: string;
}

/**
 * Megamenu
 */
export const MegaMenu = (props: MegaMenuProps): JSX.Element => {
    const {
        interestingLinks,
        languageSelector,
        backLiteral = "Back",
        scrollLeftLiteral = "Scroll left",
        scrollRightLiteral = "Scroll right",
        children,
        locale,
    } = props;
    const [scrollLeft, setScrollLeft] = useState(false);
    const [scrollRight, setScrollRight] = useState(false);
    const [scrollShowing, setScrollShowing] = useState(false);
    const [current, setCurrent] = useState(0);
    const languageDirection = locale && getLanguage(locale?.split("-").shift())?.dir;
    const { jnjFullBranded } = useJnjBranding();

    const handleKeyPress = (event: React.KeyboardEvent<HTMLLIElement>): void => {
        let elem = null;
        const targetElement = event.target as Element;
        const parentLiElement = targetElement.parentElement?.closest("li");

        if (event.code === "ArrowRight" || event.code === "ArrowDown") {
            elem = parentLiElement?.nextElementSibling?.hasChildNodes()
                ? parentLiElement?.nextElementSibling
                : parentLiElement?.nextElementSibling?.nextElementSibling;
        }

        if (event.code === "ArrowLeft" || event.code === "ArrowUp") {
            elem = parentLiElement?.previousElementSibling?.hasChildNodes()
                ? parentLiElement?.previousElementSibling
                : parentLiElement?.previousElementSibling?.previousElementSibling;
        }

        if (event.code === "ArrowUp" || event.code === "ArrowDown") {
            event.stopPropagation();
            event.preventDefault();
        }

        if (elem) {
            const anchorElement = elem.querySelector("a,button") as HTMLAnchorElement | HTMLButtonElement;
            anchorElement?.focus();
        }
    };

    const addChildrenLevels = (
        children: React.ReactNode | React.ReactNode[],
        level: number,
        parentIndex?: string,
    ): React.ReactNode | React.ReactNode[] => {
        return React.Children.map(children, (child, index) => {
            if (!React.isValidElement(child)) return child;

            // Create some keys to avoid hydration problems
            const title = typeof child?.props?.children === "string" ? child?.props?.children : child?.props?.title;
            const key = title ? cleanCSSIdentifier(`${title}.${index}`) : index;

            if (child?.type?.name == MenuItem.name || child?.type?.name == SubMenu.name) {
                const currentIndex = parentIndex ? `${parentIndex}.${index}` : index.toString();
                return React.cloneElement(child, {
                    ...child.props,
                    level: level,
                    index: currentIndex,
                    key: key,
                    children: addChildrenLevels(child.props.children, level + 1, currentIndex),
                    megaMenuRef: menuRef,
                });
            } else {
                return React.cloneElement(child, {
                    ...child.props,
                    key: key,
                    children: addChildrenLevels(child.props.children, level),
                });
            }
        });
    };

    const useHookWithRefCallback = (): [menuRef: any, setMenuRef: (node: any) => void] => {
        //https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
        const menuRef = useRef(null);
        const setMenuRef = useCallback((node: HTMLDivElement) => {
            if (node) {
                const scrollWidth = node?.scrollWidth;
                const clientWidth = node?.clientWidth;
                setScrollShowing(scrollWidth > clientWidth);
            }
            menuRef.current = node;
        }, []);

        return [menuRef, setMenuRef];
    };

    const [menuRef, setMenuRef] = useHookWithRefCallback();
    const screenSize = useScreenSize();

    useEffect(() => {
        if (menuRef) {
            const scrollWidth = menuRef?.current?.scrollWidth;
            const clientWidth = menuRef?.current?.clientWidth;
            setScrollShowing(scrollWidth > clientWidth);
        }
    }, [children, screenSize?.width]);

    //Hacky workaround for bugfix AFWN-15588. In some edge cases the scrollWidth and the clientWidth are equal during the first render.
    //There doesn't seem to be any good way to observe the scrollWidth for updates.
    useEffect(() => {
        if (menuRef?.current?.scrollWidth === menuRef?.current?.clientWidth && children?.length > 0) {
            setTimeout(() => {
                const scrollWidth = menuRef?.current?.scrollWidth;
                const clientWidth = menuRef?.current?.clientWidth;
                setScrollShowing(scrollWidth > clientWidth);
            }, 2500);
        }
    }, []);

    useEffect(() => {
        setCurrent(0);
        if (languageDirection) {
            setScrollLeft(false);
            setScrollRight(scrollShowing);
        } else {
            setScrollRight(false);
            setScrollLeft(scrollShowing);
        }
    }, [scrollShowing]);

    const handleArrowClick = (arrow: "left" | "right") => {
        const items = menuRef.current.querySelectorAll("ul#megamenu-level-0 > li");
        if (languageDirection) {
            // Left-to-Right
            arrow === "left" ? scrollStartToEnd(items) : scrollEndToStartLtr(items);
        } else {
            // Right-to-Left
            arrow === "left" ? scrollEndToStartRtl(items) : scrollStartToEnd(items);
        }
    };

    const scrollStartToEnd = (items: HTMLDListElement[]) => {
        const newIndex = Math.max(current - 1, 0);
        const elem = items[newIndex];
        setCurrent(newIndex);

        const scrollToLeft = languageDirection
            ? elem?.offsetLeft
            : elem?.offsetLeft + elem?.clientWidth - menuRef?.current?.clientWidth;

        menuRef.current.scrollTo({
            left: scrollToLeft,
            behavior: "smooth",
        });

        languageDirection ? setScrollRight(true) : setScrollLeft(true);

        if (newIndex === 0) {
            languageDirection ? setScrollLeft(false) : setScrollRight(false);
        }
    };

    const scrollEndToStartLtr = (items: HTMLDListElement[]) => {
        const newIndex = Math.min(current + 1, items.length - 1);
        const elem = items[newIndex];

        const isLeftEdgeVisible =
            elem?.offsetLeft < menuRef?.current?.scrollWidth - menuRef?.current?.clientWidth + elem?.clientWidth;

        if (isLeftEdgeVisible) {
            menuRef.current.scrollTo({
                left: elem?.offsetLeft,
                behavior: "smooth",
            });

            setCurrent(newIndex);
            setScrollLeft(true);

            // Check next element to see if we need to disable the arrow
            const nextElem = items[newIndex + 1];

            const isNextElementVisible =
                nextElem?.offsetLeft >
                menuRef?.current?.scrollWidth - menuRef?.current?.clientWidth + nextElem?.clientWidth;

            if (isNextElementVisible) {
                setScrollRight(false);
            }
        } else {
            setScrollRight(false);
        }
    };

    const scrollEndToStartRtl = (items: HTMLDListElement[]) => {
        const newIndex = Math.min(current + 1, items.length - 1);
        const elem = items[newIndex];
        const nextElement = items[current];

        if (elem?.offsetLeft > 0) {
            menuRef.current.scrollTo({
                left: menuRef?.current?.scrollLeft - nextElement?.clientWidth,
                behavior: "smooth",
            });

            setCurrent(newIndex);
            setScrollRight(true);

            const isLastElementReached =
                menuRef?.current?.scrollWidth <=
                menuRef?.current?.clientWidth - (menuRef?.current?.scrollLeft - nextElement?.clientWidth);

            if (isLastElementReached) {
                setScrollLeft(false);
            }
        } else {
            setScrollLeft(false);
        }
    };

    return (
        <MegaMenuContextProvider interestingLinks={interestingLinks} backLiteral={backLiteral}>
            <Wrapper>
                <nav
                    className={style.container}
                    onKeyDown={handleKeyPress}
                    id="megamenu"
                    data-test-id="MegaMenu"
                    role="presentation"
                >
                    <div
                        tabIndex={0}
                        className={classnames(style.scroll, style.scrollLeft, scrollLeft ? style.visible : null)}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>): void =>
                            keyHandler(e.key, () => handleArrowClick("left"))
                        }
                        onClick={() => handleArrowClick("left")}
                        aria-label={scrollLeftLiteral}
                        data-test-id="MegaMenu.LeftArrow"
                        role="button"
                    >
                        <Icon
                            color="black"
                            icon={jnjFullBranded ? jnjChevronLeft : mdiChevronLeft}
                            type={jnjFullBranded ? "jnj" : "mdi"}
                            verticalAlignMiddle
                            size={jnjFullBranded ? "xs" : "medium"}
                        />
                    </div>
                    <div
                        className={classnames(
                            style.megamenu,
                            scrollLeft ? style.leftFade : null,
                            scrollRight ? style.rightFade : null,
                            languageDirection ? null : style.rtl,
                        )}
                        ref={setMenuRef}
                        data-test-id="MegaMenu.Menu"
                        id="MegaMenu.Menu"
                    >
                        <ul id="megamenu-level-0" role="menu">
                            {addChildrenLevels(children, 1)}
                        </ul>

                        <div className={style.mobileContent} data-test-id="MegaMenu.Menu.MobileContent">
                            {interestingLinks}
                            {languageSelector}
                        </div>
                    </div>
                    <div
                        tabIndex={0}
                        className={classnames(style.scroll, style.scrollRight, scrollRight ? style.visible : null)}
                        onClick={() => handleArrowClick("right")}
                        aria-label={scrollRightLiteral}
                        data-test-id="MegaMenu.RightArrow"
                        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>): void =>
                            keyHandler(e.key, () => handleArrowClick("right"))
                        }
                        role="button"
                    >
                        <Icon
                            color="black"
                            icon={jnjFullBranded ? jnjChevronRight : mdiChevronRight}
                            type={jnjFullBranded ? "jnj" : "mdi"}
                            verticalAlignMiddle
                            size={jnjFullBranded ? "xs" : "medium"}
                        />
                    </div>
                </nav>
            </Wrapper>
        </MegaMenuContextProvider>
    );
};

export { MegaMenu as default, MenuItem, SubMenu, InterestingLinks };
