import createApiModule from "../create-api-module";
const { reducer, actions } = createApiModule("requestMaterialsOrder", () => `/materials/requests`, {
    method: "POST",
});
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface RequestMaterialsOrder {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    street: string;
    number: string;
    city: string;
    postalCode: string;
    materialOrders: RequestMaterialsOrderItem[];
    country: string;
    gender: string;
    terms?: boolean;
}

export interface RequestMaterialsOrderItem {
    key: string;
    amount: number;
    type: string;
    salesRepId: string;
    businessUnit: string;
    supplier: string;
    deliveryMethod: string;
}
