export enum AccessLevel {
    level1 = "Anonymous (L1)",
    level2 = "Unverified HCP (L2)",
    level3 = "Verified HCP (L3)",
}

/**
 * Returns a AccessLevel for the given numeric level
 * @param level the level as a number
 */
export const numLevelToAccessLevel = (level: number): AccessLevel => {
    switch (level) {
        case 2:
            return AccessLevel.level2;
        case 3:
            return AccessLevel.level3;
        default:
            return AccessLevel.level1;
    }
};

/**
 * Returns a numeric value for the given AccessLevel
 * @param level the AccessLevel to return a number for
 */
export const accessLevelToNumLevel = (level: AccessLevel): number => {
    switch (level) {
        case AccessLevel.level3:
            return 3;
        case AccessLevel.level2:
            return 2;
        default:
            return 1;
    }
};

/**
 * Checks if the given level is equal or higher then the target level
 * @param level the level that you want to check
 * @param targetLevel the level it should be greater then or equal to
 */
export const checkLevelHigherOrEqualTo = (level: AccessLevel, targetLevel: AccessLevel): boolean => {
    return accessLevelToNumLevel(level) >= accessLevelToNumLevel(targetLevel);
};
