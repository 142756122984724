import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import { default as path } from "@jmc/utils/utils/path";
import React, { useEffect } from "react";

import { getRedirectUrl } from "../../auth/auth";

/**
 * Redirects the user to the given url
 *
 * @param url the url to redirect to
 */
export const redirect = (url: string): void => {
    window.location.replace(url);
};

/**
 * Get a key from an object without case sensitivity
 *
 * @param key the key that you want to get from the target object
 * @param target the target object
 */
export const getCaseInsensitiveKey = (key: string, target: { [key: string]: string }): string | undefined => {
    const keys = Object.keys(target);
    for (const k of keys) {
        if (k.toLowerCase() === key.toLowerCase()) {
            const result = target[k];
            target[k] = null;
            return result;
        }
    }

    return undefined;
};

const copyQueryStringWithoutCodsId = (key: string, target: { [key: string]: string }): Record<string, string> => {
    const result = {} as Record<string, string>;
    const keys = Object.keys(target);
    for (const k of keys) {
        if (k.toLowerCase() !== key.toLowerCase()) {
            result[k] = target[k];
        }
    }

    return result;
};

/**
 * Fetches a marketingToken cookie, if one is provided as a query parameter.
 */
export const fetchMarketingToken = (): void => {
    const queryString = new URLSearchParams(location.search);
    const qsObject = {} as Record<string, string>;
    queryString.forEach((value, key) => {
        qsObject[key] = value;
    });

    const { token, ea, ...queryStringWithoutToken } = qsObject;
    if (!token && !ea) {
        return;
    }

    // keep all query string parameters in the redirect url except the token and the codsid itself (e.g. utm parameters)
    const queryStringWithoutTokenOrCodsId = copyQueryStringWithoutCodsId("codsid", queryStringWithoutToken);
    const redirectUrl = encodeURIComponent(
        `${getRedirectUrl()}${
            Object.keys(queryStringWithoutTokenOrCodsId).length
                ? "?" + new URLSearchParams(queryStringWithoutTokenOrCodsId).toString()
                : ""
        }`,
    );

    let url = path.join(urlHelper.getApiBaseUrl(), `/auth/marketing?token=${token || ea}&redirectUrl=${redirectUrl}`);

    const codsId = getCaseInsensitiveKey("codsId", qsObject);
    if (codsId) {
        url += `&codsId=${codsId}`;
    }

    // eslint-disable-next-line no-console
    console.log(`${url}${location.hash ?? ""}`);

    redirect(`${url}${location.hash ?? ""}`);
};

export const MarketingToken = (): JSX.Element => {
    useEffect(() => {
        fetchMarketingToken();
    }, []);
    return <></>;
};
