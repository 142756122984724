export enum EventTypes {
    ACCORDIONPANE_CLICK = "accordionpane-click",
    ACCORDIONPANE_IMPRESSION = "accordionpane-impression",
    BUTTON_CLICK = "button-click",
    BUTTON_IMPRESSION = "button-impression",
    CARD_CLICK = "card-click",
    CARD_IMPRESSION = "card-impression",
    CARD_PLAYER_CONTROL_CLICK = "card-player-control-click",
    CONTENTBLOCK_CLICK = "contentblock-click",
    CONTENTBLOCK_IMPRESSION = "contentblock-impression",
    FILE_DOWNLOAD = "file-download",
    FILE_IMPRESSION = "file-impression",
    FOOTER_IMPRESSION = "footer-impression",
    HEADER_IMPRESSION = "header-impression",
    IFRAME_IMPRESSION = "iframe-impression",
    LINK_CLICK = "link-click",
    MAP_CLICK = "map-click",
    MAP_IMPRESSION = "map-impression",
    MAP_QUERY = "map-query",
    MEDIA_25PCT = "media-25pct",
    MEDIA_50PCT = "media-50pct",
    MEDIA_75PCT = "media-75pct",
    MEDIA_CLICK = "media-click",
    MEDIA_COMPLETE = "media-complete",
    MEDIA_IMPRESSION = "media-impression",
    MEDIA_LIVE = "media-live",
    MEDIA_LIVE_END = "media-live_end",
    MEDIA_MINUTES_WATCHED = "media-minuteswatched",
    MEDIA_PAUSE = "media-pause",
    MEDIA_PLAY = "media-play",
    MEDIA_PLAYER_CONTROL_CLICK = "media-player-control-click",
    MEDIA_STOP = "media-stop",
    NAVIGATION_CLICK = "navigation-click",
    NAVIGATION_IMPRESSION = "navigation-impression",
    NOTIFICATION_CLICK = "notification-click",
    NOTIFICATION_IMPRESSION = "notification-impression",
    PAGE_IMPRESSION = "page-impression",
    SCROLL_IMPRESSION = "scroll-impression",
    SEARCH_CLICK = "sitesearch-clickonresult",
    SEARCH_QUERY = "sitesearch-query",
    SEARCH_RESULT = "sitesearch-result",
    SECTION_IMPRESSION = "section-impression",
    SHELL_IMPRESSION = "shell-impression",
    TAB_CLICK = "tab-click",
    TAB_IMPRESSION = "tab-impression",
    TOOL_ERROR = "tool-error",
    TOOL_LASTSTEP = "tool-laststep",
    TOOL_STEP = "tool-step",
    WEBVITALS_METRIC = "webvital-metric",
}
