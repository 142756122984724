import { AuthProviders, LoginTypes, NumericAccessLevel } from "@types";

import createApiModule from "../create-api-module";
/**
 * Obtain the currently authenticated users auth state
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/get-auth-valid
 */
const { reducer, actions } = createApiModule("authValid", (params) => `/auth/valid?language=${params.language}`);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface ValidAuth {
    codsId?: string;
    level: NumericAccessLevel;
    loginType: LoginTypes;
    services?: string[];
    urls?: string[];
    articles?: string[];
    events?: string[];
    materials?: string[];
    media?: string[];
    publications?: string[];
    podcasts?: string[];
    isMasterToken?: boolean;
    provider: AuthProviders;
    authenticationType?: "doccheck" | "swissrx";
}

export interface ValidAuthResults {
    results: ValidAuth[];
}
