import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import { createLocale } from "@jmc/utils/utils/createLocale";
import { default as path } from "@jmc/utils/utils/path";
import { default as stripSlash } from "@jmc/utils/utils/strip-slash";
import { AuthProviders } from "@types/Auth";
import axios from "axios";
import yn from "yn";

/**
 * Sets the localhost header when the proxy is enabled.
 */
export const getDefaultHeaders = (): Record<string, unknown> => {
    const defaultHeaders = {
        "Content-Type": "application/json",
        "X-Country": process.env.GATSBY_COUNTRY_CODE,
        "X-ApiVersion": process.env.GATSBY_API_VERSION || "api",
    };
    if (!yn(process.env.GATSBY_PROXY_ENABLED)) {
        return defaultHeaders;
    }

    return {
        "x-localhost": stripSlash.stripTrailing(process.env.GATSBY_APP_DOMAIN),
        ...defaultHeaders,
    };
};

const getIdPClientIdFromEnv = (countryCode: string = null, provider?: string): string => {
    let clientIds = JSON.parse(process.env.GATSBY_JANRAIN_CLIENT_IDS);
    if (provider && provider === AuthProviders.okta_hcp && process.env.GATSBY_OKTA_CLIENT_IDS) {
        clientIds = JSON.parse(process.env.GATSBY_OKTA_CLIENT_IDS);
    }
    if (provider && provider === AuthProviders.tcp && process.env.GATSBY_TCP_JANRAIN_CLIENT_IDS) {
        clientIds = JSON.parse(process.env.GATSBY_TCP_JANRAIN_CLIENT_IDS);
    }
    if (provider && provider === AuthProviders.okta_tcp && process.env.GATSBY_TCP_OKTA_CLIENT_IDS) {
        clientIds = JSON.parse(process.env.GATSBY_TCP_OKTA_CLIENT_IDS);
    }
    return countryCode && clientIds[countryCode] ? clientIds[countryCode] : Object.values(clientIds)[0];
};

export const getRedirectUrl = (keepSearchParams?: boolean): string => {
    if (keepSearchParams) {
        return window.location.href.replace(/\/$/, "");
    }
    return (window.location.origin + window.location.pathname).replace(/\/$/, "");
};

/**
 * Starts the registration flow, by returning the registrationUrl. Specify the redirect URL to the location that the user should be
 * sent to after a successful registration.
 *
 * > this flow doesn't authenticate the user after registration, so a login is still required!
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v5/index.html#operation/post-auth-register-init
 */

export const register = async (
    countryCode: string = null,
    url: string = null,
    provider: string = null,
    locale: string = null,
): Promise<string> => {
    const idpClientId = getIdPClientIdFromEnv(countryCode, provider);

    const response = await axios.post(
        path.join(urlHelper.getApiBaseUrl(), "/auth/register/init"),
        {
            redirectUrl: url || getRedirectUrl(),
            ...(provider && { provider }),
            ...(locale && { locale }),
            janrainClientId: idpClientId, // used by CIAM 1.0
            entryId: idpClientId, // used by CIAM 2.0
        },
        {
            headers: {
                ...getDefaultHeaders(),
            },
        },
    );
    if (url) {
        return response.data.registrationUrl;
    } else {
        window.location.href = response.data.registrationUrl;
    }
};
/**
 * Initializes the login flow and passes the current location to redirect back to after login.
 * Reason is that a user is "leveling up" from here.
 * During the redirect, secure cookies will be set to hold the access_token and refresh_token
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/post-auth-init
 */
export const login = async (
    countryCode: string = null,
    url: string = null,
    provider: string = null,
): Promise<string | void> => {
    const janrainClientId = getIdPClientIdFromEnv(countryCode, provider);
    const allLanguages = process.env.GATSBY_LOCALES?.split(",");

    const response = await axios.post(
        path.join(urlHelper.getApiBaseUrl(), "/auth/init"),
        {
            redirectUrl: url || getRedirectUrl(true),
            ...(provider && { provider }),
            country: countryCode, //this we store when creating a new profile
            locale: createLocale(location.pathname, 1, allLanguages),
            janrainClientId,
        },
        {
            headers: {
                ...getDefaultHeaders(),
            },
        },
    );
    if (url) {
        return response.data.authorizationUrl;
    } else {
        window.location.href = response.data.authorizationUrl;
    }
};

/**
 * Redirects the user back to the referer but with a level-2 anonymous HCP authentication token set as accessToken in the set-cookie header.
 * When a user is authenticated as level-3 no level-2 token will be set as level-3 is higher than level-2.
 * The backend isn't aware of front-end configuration and wouldn't know if the front-end has level-2 auth enabled and because of this always
 * return a token. It's up to the frontend to supply pages accessable with a level-2 token or not.
 * The user is redirected to the referer set in the header by the browser, if using some kind of api call the browser might overwrite the referer.
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/v0/index.html#operation/get-auth-anonymous
 */
export const loginAnonymously = (url: string = null, forceNewTab = false): void => {
    if (forceNewTab) {
        const newWindow = window.open(
            path.join(
                urlHelper.getApiBaseUrl(),
                `/auth/anonymous?redirectUrl=${encodeURIComponent(url || getRedirectUrl(true))}`,
            ),
        );
        if (newWindow) {
            const checkWindowLoaded = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(checkWindowLoaded);
                } else {
                    if (newWindow.document.readyState === "complete") {
                        clearInterval(checkWindowLoaded);
                        window.location.reload();
                    }
                }
            }, 100);
        }
    } else {
        window.location.href = path.join(
            urlHelper.getApiBaseUrl(),
            `/auth/anonymous?redirectUrl=${encodeURIComponent(url || getRedirectUrl(true))}`,
        );
    }
};

/**
 *
 * Destroys the credentials set for a anonymous authenticated user with an access level of 2, when his authentication is valid and level correct.
 * The redirect will be towards the referer set in the headers.
 * Only works for level-2 authenticated users
 * spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/get-auth-logout-anonymous
 */
export const logoutAnonymously = (): void => {
    window.location.href = path.join(
        urlHelper.getApiBaseUrl(),
        `/auth/logout/anonymous?redirectUrl=${getRedirectUrl()}`,
    );
};

const logoutDefaults = {
    provider: AuthProviders.hcp,
};

/**
 * Logout and redirect to homepage.
 * TODO: Find a better way, (add parameter) to determine the target page.
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/post-auth-logout
 */
export const logout = (options: { redirectUrl?: string; provider?: AuthProviders } = {}): Promise<void> => {
    // prefilling with logoutDefaults in case of partial options object
    // redirectUrl not in logoutDefault because of global window.location
    const allLanguages = process.env.GATSBY_LOCALES?.split(",");
    const pathURL = window.location.pathname;
    const locale = createLocale(pathURL, 1, allLanguages);
    const logoutOptions = { redirectUrl: `${window.location.origin}/${locale}`, ...logoutDefaults, ...options };
    return axios
        .post(
            path.join(urlHelper.getApiBaseUrl(), "/auth/logout"),
            {
                provider: logoutOptions?.provider,
                redirectUrl: logoutOptions?.redirectUrl,
            },

            {
                headers: {
                    ...getDefaultHeaders(),
                },
            },
        )
        .then((response) => (window.location.href = response.data.logoutUrl));
};

/**
 * Logout and redirect to same page.
 *
 */
export const logoutAutomatically = async (providers: AuthProviders[]): Promise<void> => {
    const redirectUrl = (window.location.origin + window.location.pathname).replace(/\/$/, "");

    if (providers.length > 1) {
        const logoutResults = await axios.all(
            /*
             * providers.slice(-1): we exclude the first one because that one will be used later on to do the "main" call
             * the calls here are "secondary" calls which happen first and silently
             */
            providers.slice(-1).map((provider) =>
                axios.post(
                    path.join(urlHelper.getApiBaseUrl(), "/auth/logout"),
                    {
                        redirectUrl,
                        provider,
                    },

                    {
                        headers: {
                            ...getDefaultHeaders(),
                        },
                    },
                ),
            ),
        );
        // return null when error otherwise next call is not happening
        await axios.all(logoutResults.map((result) => axios.request(result.data.logoutUrl))).catch(() => null);
    }

    return axios
        .post(
            path.join(urlHelper.getApiBaseUrl(), "/auth/logout"),
            {
                redirectUrl,
                provider: providers[0],
            },

            {
                headers: {
                    ...getDefaultHeaders(),
                },
            },
        )
        .then((response) => (window.location.href = response.data.logoutUrl));
};

/**
 * Switches the user when logged in with ping id. (J&J employees)
 * Can switch to dummy user accounts defined in the env var GATSBY_IMITATION_USERS
 * The response will contain an updated accessToken.
 * A page refresh is then needed to ensure the site continues to work normally.
 */
export const imitate = async (codsIdToImitate: string): Promise<string | void> => {
    return axios
        .post(
            path.join(urlHelper.getApiBaseUrl(), "/auth/imitate"),
            {
                codsIdToImitate,
            },
            {
                headers: {
                    ...getDefaultHeaders(),
                },
            },
        )
        .then(() => {
            const url = new URL(location.href);
            url.searchParams.set("action", "pingLogin");
            location.assign(url.search);
        });
};

export default { login, logout };
