import createApiModule from "../create-api-module";
/**
 * Obtains the MSLs available to the given HCP
 * The codsId can be obtained from the /hcps/profile endpoint. See [[profile.ts]]
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/get-hcps-codsId-msls
 */
const { reducer, actions } = createApiModule("msl", ({ codsId }) => `/hcps/${codsId}/msls`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
