import { default as jsonMapper } from "@jmc/utils/utils/json-mapper";
import { CMSMaterialProps } from "@types";
import { Action } from "redux";

import createValueModule from "../create-value-module";

const STORAGE_KEY = "jmc.collection";

const { reducer, actions } = createValueModule(
    "materialCollection",
    typeof localStorage !== "undefined" && localStorage.getItem(STORAGE_KEY)
        ? jsonMapper.jsonToMap(localStorage.getItem(STORAGE_KEY))
        : new Map<string, { material: CMSMaterialProps; amount: number }>(),
);

export default reducer;

export const clear = (): Action => {
    if (typeof localStorage !== "undefined") {
        localStorage.removeItem(STORAGE_KEY);
    }
    return actions.clear();
};

export type MaterialSetType = Map<string, { material: CMSMaterialProps; amount: number; salesRepId?: string }>;

export const set = (value: MaterialSetType): Action => {
    if (typeof localStorage !== "undefined") {
        localStorage.setItem(
            STORAGE_KEY,
            jsonMapper.mapToJson(value as unknown as Map<Record<string, unknown>, Record<string, unknown>>),
        );
    }
    return actions.set(new Map(value));
};

export const update = actions.update;
