/**
 * Removes leading
 */
export const stripLeading = (path: string): string => (path && path.startsWith("/") ? path.slice(1) : path);

/**
 * Removes trailing
 */
export const stripTrailing = (path: string): string => (path && path.endsWith("/") ? path.slice(0, -1) : path);

/**
 * Strips slashes from a path
 */
export const strip = (path: string): string => stripLeading(stripTrailing(path));

export default {
    strip,
    stripTrailing,
    stripLeading,
};
