import { Whitelists } from "./countries";

const whitelists: Whitelists = {
    EMEA: [
        "AF",
        "AL",
        "DZ",
        "AD",
        "AO",
        "AM",
        "AT",
        "AZ",
        "BH",
        "BY",
        "BE",
        "BJ",
        "BA",
        "BW",
        "BG",
        "BF",
        "BI",
        "CM",
        "CV",
        "CF",
        "TD",
        "KM",
        "CD",
        "CG",
        "CI",
        "HR",
        "CY",
        "CZ",
        "DK",
        "DJ",
        "EG",
        "GQ",
        "ER",
        "EE",
        "ET",
        "FK",
        "FO",
        "FI",
        "FR",
        "GA",
        "GM",
        "GE",
        "DE",
        "GH",
        "GI",
        "GR",
        "GL",
        "GG",
        "GN",
        "GW",
        "HU",
        "IS",
        "IR",
        "IQ",
        "IE",
        "IM",
        "IL",
        "IT",
        "JE",
        "JO",
        "KZ",
        "KE",
        "KW",
        "LV",
        "LB",
        "LS",
        "LR",
        "LY",
        "LI",
        "LT",
        "LU",
        "MK",
        "MG",
        "MW",
        "ML",
        "MT",
        "MR",
        "MU",
        "YT",
        "MD",
        "MC",
        "ME",
        "MA",
        "MZ",
        "NA",
        "NL",
        "NE",
        "NG",
        "NO",
        "OM",
        "PS",
        "PL",
        "PT",
        "QA",
        "RE",
        "RO",
        "RU",
        "RW",
        "SH",
        "SM",
        "ST",
        "SA",
        "SN",
        "RS",
        "SC",
        "SL",
        "SK",
        "SI",
        "SO",
        "ZA",
        "KR",
        "ES",
        "SD",
        "SJ",
        "SZ",
        "SE",
        "CH",
        "SY",
        "TZ",
        "TG",
        "TN",
        "TR",
        "UG",
        "UA",
        "AE",
        "GB",
        "VA",
        "EH",
        "YE",
        "ZM",
        "ZW",
        "AX",
    ],
};

export default whitelists;
