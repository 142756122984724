import { ValidAuth, ValidAuthResults } from "@redux/modules/authValid";
import { AccessLevel } from "@types";
import React, { createContext, useContext } from "react";

export const AuthDataContext = createContext(null);

type Props = {
    children: JSX.Element | JSX.Element[];
    authData: ValidAuthResults;
    accessLevel: AccessLevel;
};

export const AuthDataProvider = ({ children, authData, accessLevel }: Props): JSX.Element => {
    return <AuthDataContext.Provider value={{ authData, accessLevel }}>{children}</AuthDataContext.Provider>;
};

export const useAuthData = (): ValidAuth => {
    const data = useContext(AuthDataContext);
    return data?.authData?.results?.[0];
};

export const useAccessLevel = (): AccessLevel => {
    const data = useContext(AuthDataContext);
    return data?.accessLevel;
};

export default { AuthDataProvider, AuthDataContext, useAuthData, useAccessLevel };
