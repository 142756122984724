import React, { createContext, useMemo, useState } from "react";

interface FootnotesContextValue {
    footnoteCount: number;
    setFootnoteCount: (count: number) => void;
}

export const FootnotesContext = createContext<FootnotesContextValue>({
    footnoteCount: null,
    setFootnoteCount: () => null,
});

type Props = {
    children: JSX.Element | JSX.Element[];
};

export const FootnotesProvider = ({ children }: Props): JSX.Element => {
    const [footnoteCount, setFootnoteCount] = useState(0);
    const value = useMemo(() => ({ footnoteCount, setFootnoteCount }), [footnoteCount]);

    return <FootnotesContext.Provider value={value}>{children}</FootnotesContext.Provider>;
};

export default { FootnotesProvider, FootnotesContext };
