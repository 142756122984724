import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "podcastSubscription",
    ({ id, title }) => `/podcasts/${id}/private-subscription?podcastTitle=${title}`,
);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface PodcastSubscription {
    feedUrl: string;
}
