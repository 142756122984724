import { default as fileHelper } from "@jmc/utils/utils/file-helper";
import { CMSMaterialProps, MaterialEvent } from "@types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const mapMaterialImpression = (
    material: CMSMaterialProps,
    container: string,
    index: number | string = "unset",
    quantity = 0,
): MaterialEvent => {
    return {
        item_name: material?.title,
        item_id: material?.id,
        item_price: "1.00",
        item_type: material?.type,
        item_targetaudience: material?.target_audience?.join("|"),
        item_file_name: material?.file_asset && fileHelper.getFileName(material?.file_asset.url),
        item_file_type: material?.file_asset && fileHelper.getFileType(material?.file_asset.url),
        item_list_name: container,
        item_list_position: index,
        quantity: quantity,
    };
};

export default {
    mapMaterialImpression,
};
