import React from "react";
import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "@jmc/core/src/types/EventTypes";

export interface PropTypes {
    children: JSX.Element;
    name?: string;
    imageLink?: boolean;
    external?: boolean;
    targetUrl?: string;
    placement?: string;
    category?: string;
}

const LinkClickImpression: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, name, imageLink = false, external = false, targetUrl, placement, category } = props;

    const handleClick = (e: any): void => {
        if (name) {
            analyticstracker().trackInteraction(e, {
                changeEvent: EventTypes.LINK_CLICK,
            });
        }
    };
    return React.cloneElement(children, {
        "data-tracking-event": EventTypes.LINK_CLICK,
        "data-tracking-info": JSON.stringify({
            name: name,
            type: imageLink ? "image" : "text",
            category: category
                ? category
                : targetUrl?.startsWith("mailto")
                ? "mailto"
                : targetUrl?.startsWith("callto")
                ? "callto"
                : external
                ? "external"
                : "internal",
            placement: placement ? placement : !placement && targetUrl?.startsWith("callto") ? "" : "page",
            targeturl: targetUrl,
        }),
        onMouseDown: (e: any) => {
            handleClick(e);
        },
    });
};

export default LinkClickImpression;
