import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import { ValidAuth, ValidAuthResults } from "@redux/modules/authValid";
import { RequestMaterialMarketingUrl } from "@redux/modules/requestMaterialMarketingUrl";
import {
    AccessLevel,
    AuthProviders,
    checkLevelHigherOrEqualTo,
    LoginTypes,
    MaterialDeliveryMethod,
    numLevelToAccessLevel,
} from "@types";
import yn from "yn";

import {
    ALL_JMC_SERVICES,
    SERVICES_ACCESSIBLE_WITH_MARKETING_TOKEN,
    SERVICES_SUBPAGES_INACCESSIBLE_WITH_MARKETING_TOKEN,
    SERVICES_URL,
} from "../../../plugins/JMCPageCreator/Constants";

export const hasMarketingTokenForCurrentUrl = (
    marketingToken: ValidAuth,
    gatedUrl?: string,
    eventNumber?: string,
    podcastId?: string,
): boolean => {
    if (
        marketingToken &&
        !marketingToken.services?.length &&
        !marketingToken.urls?.length &&
        !marketingToken.articles?.length &&
        !marketingToken.events?.length &&
        !marketingToken.materials?.length &&
        !marketingToken.publications?.length &&
        !marketingToken.media?.length &&
        !marketingToken.podcasts?.length &&
        !marketingToken?.isMasterToken
    ) {
        return false;
    }

    const stripPath = (gatedUrl: string): string => {
        const parsed = urlHelper.parseUrl(gatedUrl);
        if (!parsed) {
            // happens when the url isn't a valid gatedUrl (or url overall)
            return gatedUrl;
        }
        // removes the level and locale from the path
        // so that we can match it with the backend response of auth/valid.
        const [, , , ...relevantPath] = parsed.pathname.replace(".json", "").split("/");
        return `/${relevantPath.join("/")}`;
    };

    const strippedPath = decodeURI(stripPath(gatedUrl));
    if (!strippedPath) {
        // happens when the url isn't a valid gatedUrl (or url overall)
        return false;
    }

    // master tokens grant you access everywhere, except some services.
    if (marketingToken?.isMasterToken) {
        if (
            strippedPath.startsWith(SERVICES_URL) &&
            ALL_JMC_SERVICES.some((serviceUrl: string) => (strippedPath + "/").startsWith(serviceUrl)) &&
            (!SERVICES_ACCESSIBLE_WITH_MARKETING_TOKEN.some((serviceUrl: string) =>
                (strippedPath + "/").startsWith(serviceUrl),
            ) ||
                SERVICES_SUBPAGES_INACCESSIBLE_WITH_MARKETING_TOKEN.some((serviceUrl: string) =>
                    (strippedPath + "/").startsWith(serviceUrl),
                ))
        ) {
            return false;
        }
        return true;
    }

    const hasCodsId = marketingToken?.codsId;

    const matchesService = marketingToken?.services.some((serviceUrl: string) => {
        const isAccessible = !SERVICES_SUBPAGES_INACCESSIBLE_WITH_MARKETING_TOKEN.some((serviceUrl: string) =>
            (strippedPath + "/").startsWith(serviceUrl),
        );
        if (serviceUrl === "/services/podcast-center/") {
            return hasCodsId && (strippedPath + "/").startsWith(serviceUrl) && isAccessible;
        }
        return (strippedPath + "/").startsWith(serviceUrl) && isAccessible;
    });
    const matchesPageUrl = !!marketingToken?.urls.find(
        (pageUrl: string) =>
            (strippedPath.endsWith("/") ? strippedPath.slice(0, -1) : strippedPath) ===
            (pageUrl.endsWith("/") ? pageUrl.slice(0, -1) : pageUrl),
    );
    const matchesArticles = !!marketingToken?.articles?.find((article: string) => strippedPath.includes(article));
    const matchesEvent = !!marketingToken?.events?.find((event: string) => event === eventNumber);
    const matchesMaterial = !!marketingToken?.materials?.some((material: string) => strippedPath.includes(material));
    const matchesMedia = !!marketingToken?.media?.find((media: string) => strippedPath.includes(media));
    const matchespublication = !!marketingToken?.publications?.some((publication: string) =>
        strippedPath.includes(publication),
    );
    const matchesPodcasts = hasCodsId && !!marketingToken?.podcasts?.find((podcast: string) => podcast === podcastId);
    return (
        matchesService ||
        matchesPageUrl ||
        matchesArticles ||
        matchesEvent ||
        matchesMaterial ||
        matchesMedia ||
        matchespublication ||
        matchesPodcasts
    );
};
/**
 * Is the user with given authData allowed to access the page
 *
 * @param authData the response of the /auth/valid call for the given user (undefined if user is not logged in in any way)
 * @param requiredLevel  the access level of the page being checked for access
 * @param gatedUrl the optional url of the page being checked for access (undefined for level 1 pages)
 * @returns boolean
 */
export const isAuthorized = (
    authData: ValidAuthResults,
    requiredLevel: AccessLevel,
    gatedUrl?: string,
    eventNumber?: string,
    podcastId?: string,
    isFileAsset = false,
): boolean => {
    if (yn(process.env.EVERYTHING_PUBLIC)) return true;
    if (requiredLevel === AccessLevel.level1) return true;
    if (!authData || authData.results?.length === 0) return false;

    return authData.results?.some((v) => {
        if (
            [LoginTypes.ANONYMOUS_LOGIN, LoginTypes.JANRAIN_2_0, LoginTypes.PING, LoginTypes.OKTA].includes(v.loginType)
        ) {
            return checkLevelHigherOrEqualTo(numLevelToAccessLevel(v.level), requiredLevel);
        }
        if (v.loginType === LoginTypes.EA_TOKEN)
            return isFileAsset ? true : hasMarketingTokenForCurrentUrl(v, gatedUrl, eventNumber, podcastId);
        return false;
    });
};

/**
 * Is the user with given authData allowed to see the product colors
 *
 * @param authData the response of the /auth/valid call for the given user (undefined if user is not logged in in any way)
 * @param requiredLevel  the access level of the page being checked for access
 * @param gatedUrl the optional url of the page being checked for access (undefined for level 1 pages)
 * @returns boolean
 */
export const isAuthorizedForProductColors = (
    authData: ValidAuthResults,
    requiredLevel: AccessLevel,
    isProductPage = false,
    gatedUrl?: string,
): boolean => {
    if (yn(process.env.EVERYTHING_PUBLIC)) return true;
    if (!authData || authData.results.length === 0)
        return requiredLevel === AccessLevel.level1 ? !isProductPage : false;

    return authData.results.some((v) => {
        if (
            [LoginTypes.ANONYMOUS_LOGIN, LoginTypes.JANRAIN_2_0, LoginTypes.PING, LoginTypes.OKTA].includes(v.loginType)
        ) {
            return checkLevelHigherOrEqualTo(numLevelToAccessLevel(v.level), requiredLevel);
        }
        if (v.loginType === LoginTypes.EA_TOKEN) {
            if (hasMarketingTokenForCurrentUrl(v, gatedUrl)) {
                return true;
            }
        }
        return requiredLevel === AccessLevel.level1 ? !isProductPage : false;
    });
};

/**
 * Is the user with given authData allowed to see the search results
 *
 * @param authData the response of the /auth/valid call for the given user (undefined if user is not logged in in any way)
 * @param requiredLevel  the access level of the page being checked for access
 * @returns boolean
 */
export const isAuthorizedForSearch = (authData: ValidAuthResults, requiredLevel: AccessLevel): boolean => {
    if (requiredLevel === AccessLevel.level1) return true;
    if (!authData || authData.results?.length === 0) return false;

    return authData.results?.some((v) => {
        if (
            [
                LoginTypes.ANONYMOUS_LOGIN,
                LoginTypes.JANRAIN_2_0,
                LoginTypes.EA_TOKEN,
                LoginTypes.PING,
                LoginTypes.OKTA,
            ].includes(v.loginType)
        ) {
            return checkLevelHigherOrEqualTo(numLevelToAccessLevel(v.level), requiredLevel);
        }
        return false;
    });
};

/**
 * Is the user with given authData allowed to order publications
 *
 * @param authData the response of the /auth/valid call for the given user (undefined if user is not logged in in any way)
 * @returns boolean
 */
export const isAuthorizedToOrder = (authData: ValidAuthResults): boolean => {
    if (!authData || authData.results.length === 0) return false;

    return authData.results.some((v) => {
        if ([LoginTypes.JANRAIN_2_0, LoginTypes.PING, LoginTypes.OKTA].includes(v.loginType)) {
            return numLevelToAccessLevel(v.level) === AccessLevel.level3;
        }
        return false;
    });
};

export const isAuthorizedForAccessControl = (
    authData: ValidAuthResults,
    requiredLevel: AccessLevel,
    gatedUrl?: string,
    eventNumber?: string,
    accessOnly = false,
    provider?: AuthProviders[],
): boolean => {
    if (yn(process.env.EVERYTHING_PUBLIC)) return true;
    if (!authData || authData.results?.length === 0) return false;
    return authData.results.some((v) => {
        if (!provider?.[0]) {
            provider = [AuthProviders.hcp, AuthProviders.okta_hcp, AuthProviders.ping, AuthProviders.anonymous];
        }
        if (v.loginType === LoginTypes.EA_TOKEN)
            return accessOnly ? false : hasMarketingTokenForCurrentUrl(v, gatedUrl, eventNumber);
        if (checkLevelHigherOrEqualTo(numLevelToAccessLevel(v.level), requiredLevel) && provider.includes(v.provider)) {
            return true;
        }
        return false;
    });
};

/**
 *
 * @param loginWebId webId from the profile (Redux)
 * @param requestMaterialMarketingUrlList array of webId / materialId from the requestMaterialMarketingUrl (Redux)
 * @param  {id, deliveryMethod} materialId / deliveryMethod from the Order copy toolbar
 * @returns true if the deliveryMethod is equal to salesrep and the webId matches with the one from the marketing URL
 */
export const webIdRequestMaterialMarketingMatches = (
    loginWebId: string,
    requestMaterialMarketingUrlList: RequestMaterialMarketingUrl[],
    { id, deliveryMethod }: { id: string; deliveryMethod: MaterialDeliveryMethod },
): boolean => {
    if (deliveryMethod === MaterialDeliveryMethod.salesrep) {
        const requestMaterialMarketingUrl = requestMaterialMarketingUrlList.find((req) => req.material.id === id);
        return requestMaterialMarketingUrl ? requestMaterialMarketingUrl?.webId === loginWebId : true;
    }
    return true;
};
