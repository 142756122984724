/**
 * The 4 states in which redux can be while doing an API request
 */
export enum API_STAGES_ENUM {
    RESET = "RESET",
    PENDING = "PENDING",
    MERGING = "MERGING",
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
}

/**
 * Used to create the reducer constants as a generic type
 * The string value is the action key for apiModules this would often be fi. api/profile/SUCCESS.
 * As it is created by the utils.ts#createReducer method.
 */
export interface ApiStageConstants {
    [API_STAGES_ENUM.FAILURE]: string;
    [API_STAGES_ENUM.SUCCESS]: string;
    [API_STAGES_ENUM.MERGING]: string;
    [API_STAGES_ENUM.PENDING]: string;
    [API_STAGES_ENUM.RESET]: string;
}

/**
 * Lower case array of the api stages used by the reducer to create the constants.
 */
export const LOWER_CASE_API_STAGES = Object.keys(API_STAGES_ENUM as Record<string, unknown>).map((v) =>
    v.toLowerCase(),
);
