import createApiModule from "../create-api-module";
/**
 * Obtain the eligible events for the logged in user
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/get-events-attendance
 */
const { reducer, actions } = createApiModule("eventsAttendance", () => `/events/attendance`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface EventAttendance {
    id: string;
    eventNumber: string;
    attended: boolean;
    invitationStatus: "Confirmed online";
    status: "Planned" | "Automatically Approved (meets criteria)";
}
