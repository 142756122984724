import { Icon, jnjCheck, jnjChevronDown, jnjChevronUp } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Menu, MenuItem } from "@jmc/solid-design-system/src/components/molecules/Menu/Menu";
import { useClickOutside } from "@jmc/solid-design-system/src/hooks/useClickOutside";
import { getLanguage } from "@jmc/solid-design-system/src/utils/languages";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { useLocation } from "@reach/router";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";

export interface Language {
    code: string;
    name: string;
    locale: string;
}

interface PropTypes {
    languages: Language[];
    currentLocale: string;
    navigate: (url: string) => void;
    eventTracking?: (linkName: string) => void;
}

export const LanguageSwitcher = (props: PropTypes): JSX.Element => {
    const { languages = [], eventTracking, currentLocale, navigate } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const currentLanguage = currentLocale?.split("-")?.shift().toUpperCase();

    const [language, setLanguage] = useState(currentLanguage);
    const [openMenu, setOpenMenu] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const langContainerRef = useRef(null);
    const { jnjFullBranded } = useJnjBranding();

    useEffect(() => {
        if (currentLanguage !== language) {
            setLanguage(currentLanguage);
        }
    }, [currentLanguage]);

    // set menu open/close status on click of outside
    useClickOutside(langContainerRef, () => {
        setIsMenuOpened(false);
        setOpenMenu(false);
    });

    const setCookie = (name: string, value: string, days: number) => {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    const switchLanguage = (code: string, newLocale: string, name: string): void => {
        eventTracking && eventTracking(name);
        setLanguage(code);
        setCookie("i18next", code, 90);
        const newUrl = location?.pathname?.replace(currentLocale, newLocale);
        navigate && navigate(newUrl);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (event.key === "Enter") {
            setOpenMenu(true);
            setIsMenuOpened(true);
        } else if (event.key === "Escape") {
            setOpenMenu(false);
            setIsMenuOpened(false);
        }
    };

    const handleMenuStatus = (status: boolean): void => {
        setIsMenuOpened(status);
    };

    const trigger = (
        <button
            data-test-id="LangSwitcher"
            className={style.langSwitcher}
            tabIndex={0}
            onKeyDown={handleKeyPress}
            onClick={() => eventTracking && eventTracking("language_selector")}
        >
            <div className={style.language}>
                <Typography
                    variant={jnjFullBranded ? "label-01" : "body"}
                    color={jnjFullBranded ? "light" : "primary"}
                    size={jnjFullBranded ? "" : "s"}
                    dataTestId="LangSwitcher.Language"
                >
                    {getLanguage(language)?.native}
                </Typography>
                <div className={style.language__icon}>
                    {jnjFullBranded ? (
                        <Icon
                            icon={!isMenuOpened ? jnjChevronDown : jnjChevronUp}
                            type="jnj"
                            size="0.75rem"
                            color="inherit"
                        />
                    ) : (
                        <Icon icon={!isMenuOpened ? mdiChevronDown : mdiChevronUp} size="small" color="inherit" />
                    )}
                </div>
            </div>
        </button>
    );

    return languages.length > 0 ? (
        <div className={style.langContainer} ref={langContainerRef}>
            <Menu
                trigger={trigger}
                data-test-id="LangSwitcher.MenuItem"
                label={t("Choose your language", { ns: "navigation" })}
                showAnimation
                openMenu={openMenu}
                setMenuStatus={handleMenuStatus}
                placement={jnjFullBranded ? "bottom-end" : null}
            >
                {languages?.map(({ code, name, locale }) => {
                    return (
                        <MenuItem
                            key={code}
                            onClick={() => switchLanguage(code, locale, name)}
                            data-test-id={`LangSwitcher.MenuItem.${code}`}
                            id={`${name} (${code})`}
                            suffix={
                                currentLocale === locale ? (
                                    <Icon
                                        className={style.check}
                                        color="black"
                                        icon={jnjCheck}
                                        size="small"
                                        type="jnj"
                                    />
                                ) : null
                            }
                        >
                            {getLanguage(code)?.native}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    ) : null;
};

export default LanguageSwitcher;
