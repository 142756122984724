/**
 * Creates constants based on the given namespace, prefix and stages fi. api/profile/SUCCESS
 *
 * @param stages
 * @param prefix
 * @param namespace
 */
export const createConstants = <T>(stages: string[], prefix: string, namespace: string): T => {
    return stages.reduce(
        (accumulator: Record<string, unknown>, current: string) => ({
            ...accumulator,
            [current.toUpperCase()]: `${prefix}/${namespace}/${current}`,
        }),
        {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
    );
};
