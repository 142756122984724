import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { VoiceIcon } from "@jmc/solid-design-system/src/components/atoms/VoiceIcon/VoiceIcon";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import style from "./style.module.scss";

interface Props extends AutocompleteOptionsWithMetadata<BaseItem> {
    voiceSearch?: {
        isBrowserSupported?: () => boolean;
        start?: () => void;
        stop?: () => void;
    };
}

export const VoiceButton = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { voiceSearch } = props;
    const [listening, setListening] = useState(false);

    const eventTracking = (): void => {
        analyticstracker().trackEvent({
            event: EventTypes.NAVIGATION_CLICK,
            info: { name: "header_navigation", linkname: "sitesearch_voice" },
        });
    };

    useEffect(() => {
        const targetNode = document.querySelector(".aa-VoiceSearch");
        const config = { attributes: true, childList: false, subtree: false };

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const { target } = mutation;
                if (mutation.attributeName === "class") {
                    const active = target?.classList?.contains("active");
                    setListening(active);
                }
            });
        });

        observer.observe(targetNode, config);

        return () => observer.disconnect();
    }, []);

    return (
        <button
            className={classnames("aa-VoiceSearchButton", style.voiceButton)}
            title={t("Voice search", { ns: "search" })}
            onClick={(event) => {
                eventTracking();
                if (listening) {
                    voiceSearch?.stop();
                } else {
                    voiceSearch?.start();
                }
                event.preventDefault();
            }}
            data-test-id={`search-box-voice-button`}
            // type button prevents button clicked when submitted
            type="button"
        >
            <VoiceIcon listening={listening} />
        </button>
    );
};
