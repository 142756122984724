import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import style from "./style.module.scss";
import { useMegaMenuContext } from "../MegaMenuContext";
import { activePath } from "./SubMenu";

interface MenuItemProps {
    level?: number;
    children: JSX.Element | JSX.Element[];
    index?: string;
}

export const MenuItem = (props: MenuItemProps): JSX.Element => {
    const { level, index } = props;
    const { activeMenuIndex, setActiveMenuIndex } = useMegaMenuContext();
    const elementRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    const checkIsActive = (): void => {
        setIsActive(activePath(elementRef, activeMenuIndex, setActiveMenuIndex, index));
    };

    useEffect(() => {
        window.addEventListener("hashchange", checkIsActive);

        return (): void => {
            window.removeEventListener("hashchange", checkIsActive);
        };
    });

    useEffect(() => {
        checkIsActive();
    });

    return (
        <li
            data-level={level}
            className={classnames(style[`menu-item-level-${level}`], isActive ? style[`active-item`] : null)}
            role="menuitem"
            ref={elementRef}
            data-test-id={`MenuItem.Level${level}`}
        >
            {props?.children}
        </li>
    );
};
