import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule("publications", ({ codsId }) => `/hcps/${codsId}/publications`);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Publication {
    id: string;
    pmId: string;
    amountOfAuthors: number;
    title: string;
    description: string;
    downloadId: string;
}
