import createApiModule from "../create-api-module";

/**
 * Register the user to the eligible list using the event number.
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/post-events-eventNumber-eligible
 */
const { reducer, actions } = createApiModule(
    "eventRegistration",
    ({ eventNumber }) => `/events/${eventNumber}/registration`,
    {
        method: "POST",
    },
);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface EventRegistration {
    data: { email: string };
    eventNumber: string;
}
