import React, { ReactNode } from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import { ModalConsumer } from "./Modal";

export interface PropTypes {
    children: ReactNode | ReactNode[];
    align?: "start" | "center" | "end";
}

export const Visual = ({ children, align = "start" }: PropTypes): JSX.Element => (
    <ModalConsumer>
        {() => <div className={classnames(style.modal_visual, style[`align_${align}`])}>{children}</div>}
    </ModalConsumer>
);
