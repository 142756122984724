// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (callback: Function, delay = 250) => {
    let timeoutId: number;
    return (...args: unknown[]): void => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            timeoutId = null;
            callback(...args);
        }, delay);
    };
};

export default debounce;
