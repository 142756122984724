//import styles
import style from "./style.module.scss";

//import libraries
import React from "react";
import classnames from "classnames";
interface PropTypes {
    notifications?: string;
    size?: "small" | "medium" | "large";
    children: JSX.Element | JSX.Element[];
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Notification = ({ size = "medium", notifications, children, onClick }: PropTypes): JSX.Element => (
    <div
        className={classnames(
            style.element,
            size === "small" ? style.small : null,
            size === "medium" ? style.medium : null,
            size === "large" ? style.large : null,
        )}
        data-test-id="Notification"
    >
        {notifications && (
            <span onClick={onClick} role="presentation">
                <span
                    className={classnames(
                        style.notifications,
                        size === "small" ? style.notificationsSmall : null,
                        size === "medium" ? style.notificationsMedium : null,
                        size === "large" ? style.notificationsLarge : null,
                    )}
                >
                    <p data-test-id="Notification.Number">{notifications}</p>
                </span>
                {children}
            </span>
        )}
    </div>
);
