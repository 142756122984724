import classnames from "classnames";
import React from "react";
import style from "./style.module.scss";

interface Props {
    code: string;
    size?: "sm" | "md" | "lg" | "xl";
}

export const Flag = ({ code, size = "sm" }: Props): JSX.Element => {
    return <div className={classnames([style.fflag, style[`fflag-${code}`], style[`ff-${size}`]])}></div>;
};
