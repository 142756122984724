export enum MaterialEventType {
    MATERIAL_IMPRESSION = "material-impression",
    MATERIAL_CLICK = "material-click",
    MATERIAL_DETAIL = "material-detail",
    MATERIAL_DOWNLOAD = "material-download",
    MATERIAL_ADD_TO_CART = "material-addtocart",
    MATERIAL_REMOVE_FROM_CART = "material-removefromcart",
    MATERIAL_CHECKOUT = "material-checkout",
    MATERIAL_PURCHASE = "material-purchase",
}

export interface MaterialEvent {
    item_name: string;
    item_id: string;
    item_price: string;
    item_type: string;
    item_targetaudience: "HCP" | "patient";
    item_file_name: string;
    item_file_type: string;
    item_list_name: string;
    item_list_position: number | string;
    quantity: number;
}
