import { Country } from "./countries";

const countries: Country[] = [
    {
        countryCode: "AF",
        dialCode: "93",
        countryName: "Afghanistan",
        localizedCountryName: "‫افغانستان‬‎",
    },
    {
        countryCode: "AX",
        dialCode: "358",
        countryName: "Åland Islands",
    },
    {
        countryCode: "AL",
        dialCode: "355",
        countryName: "Albania",
        localizedCountryName: "Shqipëri",
    },
    {
        countryCode: "DZ",
        dialCode: "213",
        countryName: "Algeria",
        localizedCountryName: "‫الجزائر‬‎",
    },
    {
        countryCode: "AS",
        dialCode: "1",
        countryName: "American Samoa",
    },
    {
        countryCode: "AD",
        dialCode: "376",
        countryName: "Andorra",
    },
    {
        countryCode: "AO",
        dialCode: "244",
        countryName: "Angola",
    },
    {
        countryCode: "AI",
        dialCode: "1",
        countryName: "Anguilla",
    },
    {
        countryCode: "AG",
        dialCode: "1",
        countryName: "Antigua and Barbuda",
    },
    {
        countryCode: "AR",
        dialCode: "54",
        countryName: "Argentina",
    },
    {
        countryCode: "AM",
        dialCode: "374",
        countryName: "Armenia",
        localizedCountryName: "Հայաստան",
    },
    {
        countryCode: "AW",
        dialCode: "297",
        countryName: "Aruba",
    },
    {
        countryCode: "AU",
        dialCode: "61",
        countryName: "Australia",
    },
    {
        countryCode: "AT",
        dialCode: "43",
        countryName: "Austria",
        localizedCountryName: "Österreich",
    },
    {
        countryCode: "AZ",
        dialCode: "994",
        countryName: "Azerbaijan",
        localizedCountryName: "Azərbaycan",
    },
    {
        countryCode: "BS",
        dialCode: "1",
        countryName: "Bahamas",
    },
    {
        countryCode: "BH",
        dialCode: "973",
        countryName: "Bahrain",
        localizedCountryName: "‫البحرين‬‎",
    },
    {
        countryCode: "BD",
        dialCode: "880",
        countryName: "Bangladesh",
        localizedCountryName: "বাংলাদেশ",
    },
    {
        countryCode: "BB",
        dialCode: "1",
        countryName: "Barbados",
    },
    {
        countryCode: "BY",
        dialCode: "375",
        countryName: "Belarus",
        localizedCountryName: "Беларусь",
    },
    {
        countryCode: "BE",
        dialCode: "32",
        countryName: "Belgium",
        localizedCountryName: "België",
    },
    {
        countryCode: "BZ",
        dialCode: "501",
        countryName: "Belize",
    },
    {
        countryCode: "BJ",
        dialCode: "229",
        countryName: "Benin",
        localizedCountryName: "Bénin",
    },
    {
        countryCode: "BM",
        dialCode: "1",
        countryName: "Bermuda",
    },
    {
        countryCode: "BT",
        dialCode: "975",
        countryName: "Bhutan",
        localizedCountryName: "འབྲུག",
    },
    {
        countryCode: "BO",
        dialCode: "591",
        countryName: "Bolivia",
    },
    {
        countryCode: "BA",
        dialCode: "387",
        countryName: "Bosnia and Herzegovina",
        localizedCountryName: "Босна и Херцеговина",
    },
    {
        countryCode: "BW",
        dialCode: "267",
        countryName: "Botswana",
    },
    {
        countryCode: "BR",
        dialCode: "55",
        countryName: "Brazil",
        localizedCountryName: "Brasil",
    },
    {
        countryCode: "IO",
        dialCode: "246",
        countryName: "British Indian Ocean Territory",
    },
    {
        countryCode: "VG",
        dialCode: "1",
        countryName: "British Virgin Islands",
    },
    {
        countryCode: "BN",
        dialCode: "673",
        countryName: "Brunei",
    },
    {
        countryCode: "BG",
        dialCode: "359",
        countryName: "Bulgaria",
        localizedCountryName: "България",
    },
    {
        countryCode: "BF",
        dialCode: "226",
        countryName: "Burkina Faso",
    },
    {
        countryCode: "BI",
        dialCode: "257",
        countryName: "Burundi",
        localizedCountryName: "Uburundi",
    },
    {
        countryCode: "KH",
        dialCode: "855",
        countryName: "Cambodia",
        localizedCountryName: "កម្ពុជា",
    },
    {
        countryCode: "CM",
        dialCode: "237",
        countryName: "Cameroon",
        localizedCountryName: "Cameroun",
    },
    {
        countryCode: "CA",
        dialCode: "1",
        countryName: "Canada",
    },
    {
        countryCode: "CV",
        dialCode: "238",
        countryName: "Cape Verde",
        localizedCountryName: "Kabu Verdi",
    },
    {
        countryCode: "BQ",
        dialCode: "599",
        countryName: "Caribbean Netherlands",
    },
    {
        countryCode: "KY",
        dialCode: "1",
        countryName: "Cayman Islands",
    },
    {
        countryCode: "CF",
        dialCode: "236",
        countryName: "Central African Republic",
        localizedCountryName: "République centrafricaine",
    },
    {
        countryCode: "TD",
        dialCode: "235",
        countryName: "Chad",
        localizedCountryName: "Tchad",
    },
    {
        countryCode: "CL",
        dialCode: "56",
        countryName: "Chile",
    },
    {
        countryCode: "CN",
        dialCode: "86",
        countryName: "China",
        localizedCountryName: "中国",
    },
    {
        countryCode: "CX",
        dialCode: "61",
        countryName: "Christmas Island",
    },
    {
        countryCode: "CC",
        dialCode: "61",
        countryName: "Cocos (Keeling) Islands",
    },
    {
        countryCode: "CO",
        dialCode: "57",
        countryName: "Colombia",
    },
    {
        countryCode: "KM",
        dialCode: "269",
        countryName: "Comoros",
        localizedCountryName: "جزر القمر‬‎",
    },
    {
        countryCode: "CD",
        dialCode: "243",
        countryName: "Congo (DRC)",
        localizedCountryName: "Jamhuri ya Kidemokrasia ya Kongo",
    },
    {
        countryCode: "CG",
        dialCode: "242",
        countryName: "Congo (Republic)",
        localizedCountryName: "Congo-Brazzaville",
    },
    {
        countryCode: "CK",
        dialCode: "682",
        countryName: "Cook Islands",
    },
    {
        countryCode: "CR",
        dialCode: "506",
        countryName: "Costa Rica",
    },
    {
        countryCode: "HR",
        dialCode: "385",
        countryName: "Croatia",
        localizedCountryName: "Hrvatska",
    },
    {
        countryCode: "CU",
        dialCode: "53",
        countryName: "Cuba",
    },
    {
        countryCode: "CW",
        dialCode: "599",
        countryName: "Curaçao",
    },
    {
        countryCode: "CY",
        dialCode: "357",
        countryName: "Cyprus",
        localizedCountryName: "Κύπρος",
    },
    {
        countryCode: "CZ",
        dialCode: "420",
        countryName: "Czech Republic",
        localizedCountryName: "Česká republika",
    },
    {
        countryCode: "CI",
        dialCode: "225",
        countryName: "Côte d’Ivoire",
    },
    {
        countryCode: "DK",
        dialCode: "45",
        countryName: "Denmark",
        localizedCountryName: "Danmark",
    },
    {
        countryCode: "DJ",
        dialCode: "253",
        countryName: "Djibouti",
    },
    {
        countryCode: "DM",
        dialCode: "1",
        countryName: "Dominica",
    },
    {
        countryCode: "DO",
        dialCode: "1",
        countryName: "Dominican Republic",
        localizedCountryName: "República Dominicana",
    },
    {
        countryCode: "EC",
        dialCode: "593",
        countryName: "Ecuador",
    },
    {
        countryCode: "EG",
        dialCode: "20",
        countryName: "Egypt",
        localizedCountryName: "‫مصر‬‎",
    },
    {
        countryCode: "SV",
        dialCode: "503",
        countryName: "El Salvador",
    },
    {
        countryCode: "GQ",
        dialCode: "240",
        countryName: "Equatorial Guinea",
        localizedCountryName: "Guinea Ecuatorial",
    },
    {
        countryCode: "ER",
        dialCode: "291",
        countryName: "Eritrea",
    },
    {
        countryCode: "EE",
        dialCode: "372",
        countryName: "Estonia",
        localizedCountryName: "Eesti",
    },
    {
        countryCode: "ET",
        dialCode: "251",
        countryName: "Ethiopia",
    },
    {
        countryCode: "FK",
        dialCode: "500",
        countryName: "Falkland Islands",
        localizedCountryName: "Islas Malvinas",
    },
    {
        countryCode: "FO",
        dialCode: "298",
        countryName: "Faroe Islands",
        localizedCountryName: "Føroyar",
    },
    {
        countryCode: "FJ",
        dialCode: "679",
        countryName: "Fiji",
    },
    {
        countryCode: "FI",
        dialCode: "358",
        countryName: "Finland",
        localizedCountryName: "Suomi",
    },
    {
        countryCode: "FR",
        dialCode: "33",
        countryName: "France",
    },
    {
        countryCode: "GF",
        dialCode: "594",
        countryName: "French Guiana",
        localizedCountryName: "Guyane française",
    },
    {
        countryCode: "PF",
        dialCode: "689",
        countryName: "French Polynesia",
        localizedCountryName: "Polynésie française",
    },
    {
        countryCode: "GA",
        dialCode: "241",
        countryName: "Gabon",
    },
    {
        countryCode: "GM",
        dialCode: "220",
        countryName: "Gambia",
    },
    {
        countryCode: "GE",
        dialCode: "995",
        countryName: "Georgia",
        localizedCountryName: "საქართველო",
    },
    {
        countryCode: "DE",
        dialCode: "49",
        countryName: "Germany",
        localizedCountryName: "Deutschland",
    },
    {
        countryCode: "GH",
        dialCode: "233",
        countryName: "Ghana",
        localizedCountryName: "Gaana",
    },
    {
        countryCode: "GI",
        dialCode: "350",
        countryName: "Gibraltar",
    },
    {
        countryCode: "GR",
        dialCode: "30",
        countryName: "Greece",
        localizedCountryName: "Ελλάδα",
    },
    {
        countryCode: "GL",
        dialCode: "299",
        countryName: "Greenland",
        localizedCountryName: "Kalaallit Nunaat",
    },
    {
        countryCode: "GD",
        dialCode: "1",
        countryName: "Grenada",
    },
    {
        countryCode: "GP",
        dialCode: "590",
        countryName: "Guadeloupe",
    },
    {
        countryCode: "GU",
        dialCode: "1",
        countryName: "Guam",
    },
    {
        countryCode: "GT",
        dialCode: "502",
        countryName: "Guatemala",
    },
    {
        countryCode: "GG",
        dialCode: "44",
        countryName: "Guernsey",
    },
    {
        countryCode: "GN",
        dialCode: "224",
        countryName: "Guinea",
        localizedCountryName: "Guinée",
    },
    {
        countryCode: "GW",
        dialCode: "245",
        countryName: "Guinea-Bissau",
        localizedCountryName: "Guiné Bissau",
    },
    {
        countryCode: "GY",
        dialCode: "592",
        countryName: "Guyana",
    },
    {
        countryCode: "HT",
        dialCode: "509",
        countryName: "Haiti",
    },
    {
        countryCode: "HN",
        dialCode: "504",
        countryName: "Honduras",
    },
    {
        countryCode: "HK",
        dialCode: "852",
        countryName: "Hong Kong",
        localizedCountryName: "香港",
    },
    {
        countryCode: "HU",
        dialCode: "36",
        countryName: "Hungary",
        localizedCountryName: "Magyarország",
    },
    {
        countryCode: "IS",
        dialCode: "354",
        countryName: "Iceland",
        localizedCountryName: "Ísland",
    },
    {
        countryCode: "IN",
        dialCode: "91",
        countryName: "India",
        localizedCountryName: "भारत",
    },
    {
        countryCode: "ID",
        dialCode: "62",
        countryName: "Indonesia",
    },
    {
        countryCode: "IR",
        dialCode: "98",
        countryName: "Iran",
        localizedCountryName: "‫ایران‬‎",
    },
    {
        countryCode: "IQ",
        dialCode: "964",
        countryName: "Iraq",
        localizedCountryName: "‫العراق‬‎",
    },
    {
        countryCode: "IE",
        dialCode: "353",
        countryName: "Ireland",
    },
    {
        countryCode: "IM",
        dialCode: "44",
        countryName: "Isle of Man",
    },
    {
        countryCode: "IL",
        dialCode: "972",
        countryName: "Israel",
        localizedCountryName: "‫ישראל‬‎",
    },
    {
        countryCode: "IT",
        dialCode: "39",
        countryName: "Italy",
        localizedCountryName: "Italia",
    },
    {
        countryCode: "JM",
        dialCode: "1",
        countryName: "Jamaica",
    },
    {
        countryCode: "JP",
        dialCode: "81",
        countryName: "Japan",
        localizedCountryName: "日本",
    },
    {
        countryCode: "JE",
        dialCode: "44",
        countryName: "Jersey",
    },
    {
        countryCode: "JO",
        dialCode: "962",
        countryName: "Jordan",
        localizedCountryName: "‫الأردن‬‎",
    },
    {
        countryCode: "KZ",
        dialCode: "7",
        countryName: "Kazakhstan",
        localizedCountryName: "Казахстан",
    },
    {
        countryCode: "KE",
        dialCode: "254",
        countryName: "Kenya",
    },
    {
        countryCode: "KI",
        dialCode: "686",
        countryName: "Kiribati",
    },
    {
        countryCode: "KW",
        dialCode: "965",
        countryName: "Kuwait",
        localizedCountryName: "‫الكويت‬‎",
    },
    {
        countryCode: "KG",
        dialCode: "996",
        countryName: "Kyrgyzstan",
        localizedCountryName: "Кыргызстан",
    },
    {
        countryCode: "LA",
        dialCode: "856",
        countryName: "Laos",
        localizedCountryName: "ລາວ",
    },
    {
        countryCode: "LV",
        dialCode: "371",
        countryName: "Latvia",
        localizedCountryName: "Latvija",
    },
    {
        countryCode: "LB",
        dialCode: "961",
        countryName: "Lebanon",
        localizedCountryName: "‫لبنان‬‎",
    },
    {
        countryCode: "LS",
        dialCode: "266",
        countryName: "Lesotho",
    },
    {
        countryCode: "LR",
        dialCode: "231",
        countryName: "Liberia",
    },
    {
        countryCode: "LY",
        dialCode: "218",
        countryName: "Libya",
        localizedCountryName: "‫ليبيا‬‎",
    },
    {
        countryCode: "LI",
        dialCode: "423",
        countryName: "Liechtenstein",
    },
    {
        countryCode: "LT",
        dialCode: "370",
        countryName: "Lithuania",
        localizedCountryName: "Lietuva",
    },
    {
        countryCode: "LU",
        dialCode: "352",
        countryName: "Luxembourg",
    },
    {
        countryCode: "MO",
        dialCode: "853",
        countryName: "Macau",
        localizedCountryName: "澳門",
    },
    {
        countryCode: "MK",
        dialCode: "389",
        countryName: "Macedonia (FYROM)",
        localizedCountryName: "Македонија",
    },
    {
        countryCode: "MG",
        dialCode: "261",
        countryName: "Madagascar",
        localizedCountryName: "Madagasikara",
    },
    {
        countryCode: "MW",
        dialCode: "265",
        countryName: "Malawi",
    },
    {
        countryCode: "MY",
        dialCode: "60",
        countryName: "Malaysia",
    },
    {
        countryCode: "MV",
        dialCode: "960",
        countryName: "Maldives",
    },
    {
        countryCode: "ML",
        dialCode: "223",
        countryName: "Mali",
    },
    {
        countryCode: "MT",
        dialCode: "356",
        countryName: "Malta",
    },
    {
        countryCode: "MH",
        dialCode: "692",
        countryName: "Marshall Islands",
    },
    {
        countryCode: "MQ",
        dialCode: "596",
        countryName: "Martinique",
    },
    {
        countryCode: "MR",
        dialCode: "222",
        countryName: "Mauritania",
        localizedCountryName: "‫موريتانيا‬‎",
    },
    {
        countryCode: "MU",
        dialCode: "230",
        countryName: "Mauritius",
        localizedCountryName: "Moris",
    },
    {
        countryCode: "YT",
        dialCode: "262",
        countryName: "Mayotte",
    },
    {
        countryCode: "MX",
        dialCode: "52",
        countryName: "Mexico",
        localizedCountryName: "México",
    },
    {
        countryCode: "FM",
        dialCode: "691",
        countryName: "Micronesia",
    },
    {
        countryCode: "MD",
        dialCode: "373",
        countryName: "Moldova",
        localizedCountryName: "Republica Moldova",
    },
    {
        countryCode: "MC",
        dialCode: "377",
        countryName: "Monaco",
    },
    {
        countryCode: "MN",
        dialCode: "976",
        countryName: "Mongolia",
        localizedCountryName: "Монгол",
    },
    {
        countryCode: "ME",
        dialCode: "382",
        countryName: "Montenegro",
        localizedCountryName: "Crna Gora",
    },
    {
        countryCode: "MS",
        dialCode: "1",
        countryName: "Montserrat",
    },
    {
        countryCode: "MA",
        dialCode: "212",
        countryName: "Morocco",
        localizedCountryName: "‫المغرب‬‎",
    },
    {
        countryCode: "MZ",
        dialCode: "258",
        countryName: "Mozambique",
        localizedCountryName: "Moçambique",
    },
    {
        countryCode: "MM",
        dialCode: "95",
        countryName: "Myanmar (Burma)",
        localizedCountryName: "မြန်မာ",
    },
    {
        countryCode: "NA",
        dialCode: "264",
        countryName: "Namibia",
        localizedCountryName: "Namibië",
    },
    {
        countryCode: "NR",
        dialCode: "674",
        countryName: "Nauru",
    },
    {
        countryCode: "NP",
        dialCode: "977",
        countryName: "Nepal",
        localizedCountryName: "नेपाल",
    },
    {
        countryCode: "NL",
        dialCode: "31",
        countryName: "Netherlands",
        localizedCountryName: "Nederland",
    },
    {
        countryCode: "NC",
        dialCode: "687",
        countryName: "New Caledonia",
        localizedCountryName: "Nouvelle-Calédonie",
    },
    {
        countryCode: "NZ",
        dialCode: "64",
        countryName: "New Zealand",
    },
    {
        countryCode: "NI",
        dialCode: "505",
        countryName: "Nicaragua",
    },
    {
        countryCode: "NE",
        dialCode: "227",
        countryName: "Niger",
        localizedCountryName: "Nijar",
    },
    {
        countryCode: "NG",
        dialCode: "234",
        countryName: "Nigeria",
    },
    {
        countryCode: "NU",
        dialCode: "683",
        countryName: "Niue",
    },
    {
        countryCode: "NF",
        dialCode: "672",
        countryName: "Norfolk Island",
    },
    {
        countryCode: "KP",
        dialCode: "850",
        countryName: "North Korea",
        localizedCountryName: "조선 민주주의 인민 공화국",
    },
    {
        countryCode: "MP",
        dialCode: "1",
        countryName: "Northern Mariana Islands",
    },
    {
        countryCode: "NO",
        dialCode: "47",
        countryName: "Norway",
        localizedCountryName: "Norge",
    },
    {
        countryCode: "OM",
        dialCode: "968",
        countryName: "Oman",
        localizedCountryName: "‫عُمان‬‎",
    },
    {
        countryCode: "PK",
        dialCode: "92",
        countryName: "Pakistan",
        localizedCountryName: "‫پاکستان‬‎",
    },
    {
        countryCode: "PW",
        dialCode: "680",
        countryName: "Palau",
    },
    {
        countryCode: "PS",
        dialCode: "970",
        countryName: "Palestine",
        localizedCountryName: "‫فلسطين‬‎",
    },
    {
        countryCode: "PA",
        dialCode: "507",
        countryName: "Panama",
        localizedCountryName: "Panamá",
    },
    {
        countryCode: "PG",
        dialCode: "675",
        countryName: "Papua New Guinea",
    },
    {
        countryCode: "PY",
        dialCode: "595",
        countryName: "Paraguay",
    },
    {
        countryCode: "PE",
        dialCode: "51",
        countryName: "Peru",
        localizedCountryName: "Perú",
    },
    {
        countryCode: "PH",
        dialCode: "63",
        countryName: "Philippines",
    },
    {
        countryCode: "PL",
        dialCode: "48",
        countryName: "Poland",
        localizedCountryName: "Polska",
    },
    {
        countryCode: "PT",
        dialCode: "351",
        countryName: "Portugal",
    },
    {
        countryCode: "PR",
        dialCode: "1",
        countryName: "Puerto Rico",
    },
    {
        countryCode: "QA",
        dialCode: "974",
        countryName: "Qatar",
        localizedCountryName: "‫قطر‬‎",
    },
    {
        countryCode: "RO",
        dialCode: "40",
        countryName: "Romania",
        localizedCountryName: "România",
    },
    {
        countryCode: "RU",
        dialCode: "7",
        countryName: "Russia",
        localizedCountryName: "Россия",
    },
    {
        countryCode: "RW",
        dialCode: "250",
        countryName: "Rwanda",
    },
    {
        countryCode: "RE",
        dialCode: "262",
        countryName: "Réunion",
        localizedCountryName: "La Réunion",
    },
    {
        countryCode: "BL",
        dialCode: "590",
        countryName: "Saint Barthélemy",
        localizedCountryName: "Saint-Barthélemy",
    },
    {
        countryCode: "SH",
        dialCode: "290",
        countryName: "Saint Helena",
    },
    {
        countryCode: "KN",
        dialCode: "1",
        countryName: "Saint Kitts and Nevis",
    },
    {
        countryCode: "LC",
        dialCode: "1",
        countryName: "Saint Lucia",
    },
    {
        countryCode: "MF",
        dialCode: "590",
        countryName: "Saint Martin",
        localizedCountryName: "Saint-Martin (partie française)",
    },
    {
        countryCode: "PM",
        dialCode: "508",
        countryName: "Saint Pierre and Miquelon",
        localizedCountryName: "Saint-Pierre-et-Miquelon",
    },
    {
        countryCode: "VC",
        dialCode: "1",
        countryName: "Saint Vincent and the Grenadines",
    },
    {
        countryCode: "WS",
        dialCode: "685",
        countryName: "Samoa",
    },
    {
        countryCode: "SM",
        dialCode: "378",
        countryName: "San Marino",
    },
    {
        countryCode: "SA",
        dialCode: "966",
        countryName: "Saudi Arabia",
        localizedCountryName: "المملكة العربية السعودية‬‎",
    },
    {
        countryCode: "SN",
        dialCode: "221",
        countryName: "Senegal",
        localizedCountryName: "Sénégal",
    },
    {
        countryCode: "RS",
        dialCode: "381",
        countryName: "Serbia",
        localizedCountryName: "Србија",
    },
    {
        countryCode: "SC",
        dialCode: "248",
        countryName: "Seychelles",
    },
    {
        countryCode: "SL",
        dialCode: "232",
        countryName: "Sierra Leone",
    },
    {
        countryCode: "SG",
        dialCode: "65",
        countryName: "Singapore",
    },
    {
        countryCode: "SX",
        dialCode: "1",
        countryName: "Sint Maarten",
    },
    {
        countryCode: "SK",
        dialCode: "421",
        countryName: "Slovakia",
        localizedCountryName: "Slovensko",
    },
    {
        countryCode: "SI",
        dialCode: "386",
        countryName: "Slovenia",
        localizedCountryName: "Slovenija",
    },
    {
        countryCode: "SB",
        dialCode: "677",
        countryName: "Solomon Islands",
    },
    {
        countryCode: "SO",
        dialCode: "252",
        countryName: "Somalia",
        localizedCountryName: "Soomaaliya",
    },
    {
        countryCode: "ZA",
        dialCode: "27",
        countryName: "South Africa",
    },
    {
        countryCode: "KR",
        dialCode: "82",
        countryName: "South Korea",
        localizedCountryName: "대한민국",
    },
    {
        countryCode: "SS",
        dialCode: "211",
        countryName: "South Sudan",
        localizedCountryName: "السودان‬‎ جنوب",
    },
    {
        countryCode: "ES",
        dialCode: "34",
        countryName: "Spain",
        localizedCountryName: "España",
    },
    {
        countryCode: "LK",
        dialCode: "94",
        countryName: "Sri Lanka",
        localizedCountryName: "ශ්‍රී ලංකාව",
    },
    {
        countryCode: "SD",
        dialCode: "249",
        countryName: "Sudan",
        localizedCountryName: "‫السودان‬‎",
    },
    {
        countryCode: "SR",
        dialCode: "597",
        countryName: "Suriname",
    },
    {
        countryCode: "SJ",
        dialCode: "47",
        countryName: "Svalbard and Jan Mayen",
    },
    {
        countryCode: "SZ",
        dialCode: "268",
        countryName: "Swaziland",
    },
    {
        countryCode: "SE",
        dialCode: "46",
        countryName: "Sweden",
        localizedCountryName: "Sverige",
    },
    {
        countryCode: "CH",
        dialCode: "41",
        countryName: "Switzerland",
        localizedCountryName: "Schweiz",
    },
    {
        countryCode: "SY",
        dialCode: "963",
        countryName: "Syria",
        localizedCountryName: "‫سوريا‬‎",
    },
    {
        countryCode: "ST",
        dialCode: "239",
        countryName: "São Tomé and Príncipe",
        localizedCountryName: "São Tomé e Príncipe",
    },
    {
        countryCode: "TW",
        dialCode: "886",
        countryName: "Taiwan",
        localizedCountryName: "台灣",
    },
    {
        countryCode: "TJ",
        dialCode: "992",
        countryName: "Tajikistan",
    },
    {
        countryCode: "TZ",
        dialCode: "255",
        countryName: "Tanzania",
    },
    {
        countryCode: "TH",
        dialCode: "66",
        countryName: "Thailand",
        localizedCountryName: "ไทย",
    },
    {
        countryCode: "TL",
        dialCode: "670",
        countryName: "Timor-Leste",
    },
    {
        countryCode: "TG",
        dialCode: "228",
        countryName: "Togo",
    },
    {
        countryCode: "TK",
        dialCode: "690",
        countryName: "Tokelau",
    },
    {
        countryCode: "TO",
        dialCode: "676",
        countryName: "Tonga",
    },
    {
        countryCode: "TT",
        dialCode: "1",
        countryName: "Trinidad and Tobago",
    },
    {
        countryCode: "TN",
        dialCode: "216",
        countryName: "Tunisia",
        localizedCountryName: "‫تونس‬‎",
    },
    {
        countryCode: "TR",
        dialCode: "90",
        countryName: "Turkey",
        localizedCountryName: "Türkiye",
    },
    {
        countryCode: "TM",
        dialCode: "993",
        countryName: "Turkmenistan",
    },
    {
        countryCode: "TC",
        dialCode: "1",
        countryName: "Turks and Caicos Islands",
    },
    {
        countryCode: "TV",
        dialCode: "688",
        countryName: "Tuvalu",
    },
    {
        countryCode: "VI",
        dialCode: "1",
        countryName: "U.S. Virgin Islands",
    },
    {
        countryCode: "UG",
        dialCode: "256",
        countryName: "Uganda",
    },
    {
        countryCode: "UA",
        dialCode: "380",
        countryName: "Ukraine",
        localizedCountryName: "Україна",
    },
    {
        countryCode: "AE",
        dialCode: "971",
        countryName: "United Arab Emirates",
        localizedCountryName: "المتحدة‬‎الإمارات العربية",
    },
    {
        countryCode: "GB",
        dialCode: "44",
        countryName: "United Kingdom",
    },
    {
        countryCode: "US",
        dialCode: "1",
        countryName: "United States",
    },
    {
        countryCode: "UY",
        dialCode: "598",
        countryName: "Uruguay",
    },
    {
        countryCode: "UZ",
        dialCode: "998",
        countryName: "Uzbekistan",
        localizedCountryName: "Oʻzbekiston",
    },
    {
        countryCode: "VU",
        dialCode: "678",
        countryName: "Vanuatu",
    },
    {
        countryCode: "VA",
        dialCode: "39",
        countryName: "Vatican City",
        localizedCountryName: "Città del Vaticano",
    },
    {
        countryCode: "VE",
        dialCode: "58",
        countryName: "Venezuela",
    },
    {
        countryCode: "VN",
        dialCode: "84",
        countryName: "Vietnam",
        localizedCountryName: "Việt Nam",
    },
    {
        countryCode: "WF",
        dialCode: "681",
        countryName: "Wallis and Futuna",
    },
    {
        countryCode: "EH",
        dialCode: "212",
        countryName: "Western Sahara",
        localizedCountryName: "‫الصحراء الغربية‬‎",
    },
    {
        countryCode: "YE",
        dialCode: "967",
        countryName: "Yemen",
        localizedCountryName: "‫اليمن‬‎",
    },
    {
        countryCode: "ZM",
        dialCode: "260",
        countryName: "Zambia",
    },
    {
        countryCode: "ZW",
        dialCode: "263",
        countryName: "Zimbabwe",
    },
];

export default countries;
