import { CMSTranslationNode } from "types/CMSTranslations";

/**
 * Maps the cmsTranslations in a format suitable for i18n
 *
 * @param cmsTranslations array of objects to be mapped
 */
const createI18nTranslations = (cmsTranslations: CMSTranslationNode[]): { [key: string]: string } => {
    const translations: { [key: string]: string } = {};

    cmsTranslations.forEach((translation: CMSTranslationNode) => {
        translations[translation.title] = process.env.GATSBY_SHOW_TRANSLATION_KEYS
            ? translation.title
            : translation.translation;
    });

    return translations;
};

export default createI18nTranslations;
