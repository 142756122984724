import { State } from "@redux/create-api-module";
import { Question, QuestionContent } from "@types";

import createApiModule from "../create-api-module";
/**
 * Obtain the ate questions by the expert (id)
 * Spec:
 */
const { reducer, actions } = createApiModule(
    "askTheExpertsQuestions",
    ({ expertId }) => `/ask-the-experts/questions/experts/${expertId}`,
);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Questions {
    expert: {
        id: string | number;
        name: string;
    };
    questions: [Question];
}

export interface QuestionList extends State {
    data: QuestionContent[];
}
