export const codsidArray = [
    "PNL96492",
    "PSK2659",
    "PAT1416",
    "PBE2830",
    "PBE923",
    "PSK2639",
    "PCZ5677",
    "PGB585562",
    "PHR1963",
    "PAT1416",
    "PDE1109942",
    "PTR45301",
    "PAT50747",
    "PNL107440",
    "PCM2",
    "PCZ5126",
    "PPT63331",
    "PBE3388",
    "PFR97573",
    "PIT50317",
    "PGB722165",
    "PGB710858",
    "PNL170154",
    "PFR1138502",
    "PDE731746",
    "PDE1080392",
    "PDE1080391",
    "1-17L1QK8",
    "PGB714706",
    "PRO18472",
    "PRO23001",
    "PH412059",
    "PRS4452",
    "PRO13696",
    "PNL169628",
    "PIT117916",
    "PCH9149",
    "PBE94046",
    "PDE1089329",
    "PBE8122",
    "PDE1055503",
    "PNL6294",
    "PZA193729",
    "PES105263",
    "PIT63959",
    "PE2131086",
    "PBE29",
    "PPT24340",
    "PES138732",
    "PGB770666",
    "PI2138334",
    "PLY1402",
    "PGH1064",
    "PBE104454",
    "PIT146014",
    "PAE12003",
    "PEE2150",
    "PIQ6312",
    "PDZ86679",
    "PES198409",
    "PNG35763",
    "PKE13902",
    "PGH8241",
    "PRU378448",
    "PLB10821",
    "PES198408",
    "PMA40714",
    "PDZ86681",
    "PS42544",
    "PC28811",
    "PCH38687",
    "PGB831534",
    "PGB834195",
    "PRU117953",
    "PRU117955",
    "PRU117954",
    "PDE306914",
    "PIQ6311",
    "PPL18692",
    "PRO8418",
    "PES190565",
    "PGB115305",
    "PC28759",
    "PCZ4735",
    "PCZ5566",
    "PSE145299",
    "PJO7126",
    "PFI63355",
    "PSE143178",
    "PNO35363",
    "PS41331",
    "PBH1969",
    "PGB823673",
    "PGR68476",
    "PDK66346",
    "POM2546",
    "PSK2474",
    "PSA35594",
    "PAT30711",
    "PSI2107",
    "PSK2473",
    "PCZ5182",
    "PKW5269",
    "PH23990",
    "PS23084",
    "PC28211",
    "PI259029",
    "PQA3134",
    "PP227660",
    "PBE46130",
    "PSI2333",
    "PSE153455",
    "PSE202751",
    "PDE1089330",
    "PBE95292",
    "1-OPHRE8",
    "PPL15360",
    "PSI4100",
    "PRO16550",
    "PES194109",
    "PGB841386",
    "PPL13952",
    "PPL15614",
    "PP229934",
    "PPL18877",
    "PI2106405",
    "PDE1109941",
    "PGB715532",
    "PGB1064968",
    "PGB718035",
    "PEG123752",
    "PCZ5646",
    "PPL36124",
    "PCH30423",
    "PS42567",
    "PBE4126",
    "PCZ5673",
    "PSK2626",
    "PLV2279",
    "PIT49467",
    "PI2105677",
    "PGB718912",
    "PGB176897",
    "PCZ2675",
    "PSK1644",
    "PE2131085",
    "PGB122653",
    "PSI2339",
    "1-6Q1I-3",
    "PS42439",
    "PPT12481",
    "PBE66002",
    "PGB56615",
    "1-1LOCC4J",
    "1-6Q1I-30036",
    "PS42644",
    "PSK2355",
    "PBE104453",
    "PDZ86680",
    "PGB84438",
    "PCZ5674",
    "PS42172",
    "PNO10904",
    "PBY3538",
    "PLT4287",
    "PLV2277",
    "PGR59398",
    "PIT146065",
    "PSE202750",
    "PLB9562",
    "PIT208037",
    "PSE145301",
    "PBY2779",
    "PFR1002694",
    "PNL169582",
    "PI274057",
    "PI274058",
    "PI273609",
    "PMA40715",
    "PSI2171",
    "PSK2635",
    "PCZ5164",
    "PSI2166",
    "PUA1740",
    "PCH38688",
    "PI274059",
    "PI273603",
    "PSI2175",
    "PBE88810",
    "PI273762",
    "PRS4451",
    "PI273610",
    "PCZ5579",
    "PS23107",
    "PHR2038",
    "PSK2627",
    "PSA98914",
    "PCZ5563",
    "PSI2173",
    "PS23108",
    "PHR2036",
    "PS42429",
    "PCZ5560",
    "PSK2625",
    "PHR2033",
    "PCZ5573",
    "PSI2169",
    "PQA7349",
    "PI274062",
    "PHR2034",
    "PHR2042",
    "PS42440",
    "PKW14534",
    "PJO7080",
    "PL23808",
    "PL42188",
    "PRU133036",
    "PIL26844",
    "PEG156127",
    "PEG145647",
    "PJO7090",
    "PJO7045",
    "PLY1401",
    "PEG147082",
    "PEG147079",
    "PEG147080",
    "PJO7129",
    "PJO7128",
    "PLY1405",
    "PEG145047",
    "PSK2678",
    "PNL169583",
    "PC28810",
    "PHR1342",
    "PCZ5125",
    "PBE102789",
    "PBE101203",
    "PSK2431",
    "PTN29903",
    "PL42133",
    "PSE200294",
    "PNL137428",
    "PNL169630",
    "PNL169629",
    "PBE100915",
    "PCZ5640",
    "PPL13783",
    "PHU40115",
    "PRO1041",
    "PSI2336",
    "PLB10304",
    "PEG147077",
    "PGH2695",
    "PIT206042",
    "PEG145048",
    "PI2105676",
    "PSD148",
    "PBE103663",
    "PFR121135",
    "PSD145",
    "PBE103146",
    "PNL171745",
    "PTN29902",
    "PIT206040",
    "PIT206041",
    "PPL16536",
    "PLB8713",
    "PE2133722",
    "PSD146",
    "PIT208062",
    "PBG8699",
    "PBG8680",
    "PIT122442",
    "PE42045",
    "PNL146463",
    "PHU64368",
    "PBE103066",
    "PBE103145",
    "PH412293",
    "PH46820",
    "1-1LQA3PZ",
    "PBE105568",
    "PSK1485",
    "PUA4012",
    "PIT207832",
    "PIT207936",
    "PLB10630",
    "1-1LQ6Q6P",
    "PSD144",
    "PNL175695",
    "PZA181598",
    "PIT113354",
    "PBE105567",
    "PS42402",
    "PI270805",
    "1-195EJS8",
    "PNL169882",
    "PAE33437",
    "PGR68477",
    "PAT31494",
    "PIL24949",
    "1-15AAWG",
    "PBH3479",
    "PSD149",
    "PDZ86682",
    "PEG145648",
    "PGH2725",
    "PIQ6409",
    "PJO7127",
    "PKE4818",
    "PKW12268",
    "PLY1404",
    "PLB10569",
    "PMA40716",
    "PNG10750",
    "POM6053",
    "PQA6414",
    "PSA81197",
    "PSD147",
    "PTN29904",
    "PAE28398",
    "PBE101491",
    "PPL16587",
    "PC28775",
    "PBE103346",
    "PGR77909",
    "PGR54685",
    "PKZ12516",
    "PBY2471",
    "PUA2340",
    "PRU63856",
    "PKZ12522",
    "PRU235036",
    "PRU411094",
    "PBY2482",
    "PK27654",
    "PKZ12526",
    "PRU357571",
    "PK2898",
    "PRU416643",
    "PK2680",
    "PKZ12521",
    "PKZ12494",
    "PKZ12894",
    "PKZ12491",
    "PIL24948",
    "PIL26504",
    "PIL26506",
    "PIL26505",
];
