import React, { createContext } from "react";

// Default regulatory status of a page is Medical Content (301)
// commercial content = true, Medical content = false
export const JMCLinkContext = createContext(false);

/**
 * React Context Provider to access the regulatory status of a page in any lower component within the page.
 * Use this in all JMC pages to apply 201/301 navigation rules.
 */
export const JMCLinkProvider = ({
    commercialContent,
    children,
}: {
    commercialContent: boolean;
    children: JSX.Element | JSX.Element[];
}): JSX.Element => <JMCLinkContext.Provider value={commercialContent}>{children}</JMCLinkContext.Provider>;
