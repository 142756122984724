import { JMCLink } from "@components/JMCLink/JMCLink";
import JNJBarImpression from "@jmc/core/src/components/JNJBarImpression/JNJBarImpression";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { JNJBar } from "@jmc/solid-design-system/src/components/atoms/JNJBar/JNJBar";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { default as cmsMapper } from "@utils/cms-mapper";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { CMSJNJBar } from "types/CMSJNJBar";
import { CMSLinkGroupProps } from "types/CMSLinkGroupProps";

export const JMCJNJBar = (props: {
    withJNJBar?: boolean;
    setWithJNJBar?: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    const locale = useLocale() || "en-us";
    const { withJNJBar, setWithJNJBar } = props;

    const { query }: { query: { nodes: CMSJNJBar[] } } = useStaticQuery(graphql`
        {
            query: allContentstackJnjBar {
                nodes {
                    ...jnjBarFields
                }
            }
        }
    `);
    const jnjBar = query?.nodes.filter((item: CMSJNJBar) => item?.publish_details?.locale === locale).pop();
    const links = jnjBar?.links?.map((link: CMSLinkGroupProps, index) => {
        const title = link?.label;
        const linkProps = cmsMapper.getLink(link);

        return (
            <JMCLink
                key={`${title}.${index}`}
                id={`jnj-bar-link-${index}`}
                url={linkProps?.url}
                fullWidth={false}
                external={linkProps?.external}
                commercial={linkProps?.commercial}
                params={linkProps?.params}
                anchor_id={linkProps?.anchor_id}
                isFileAsset={linkProps?.isFileAsset}
            >
                <Typography font="inherit" color="white" size="s">
                    {title}
                </Typography>
            </JMCLink>
        );
    });

    return withJNJBar ? (
        <JNJBarImpression>
            <JNJBar text={jnjBar?.text} links={links} setWithJNJBar={setWithJNJBar} />
        </JNJBarImpression>
    ) : (
        <JNJBar text={jnjBar?.text} links={links} setWithJNJBar={setWithJNJBar} />
    );
};

export default JMCJNJBar;
