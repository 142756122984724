import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { ImagePosition } from "@jmc/solid-design-system/src/components/molecules/Card/Card";

import { CMSDocumentProps } from "./CMSDocumentProps";
import { CMSFootnotes } from "./CMSFootnotes";
import { CMSFragmentProps } from "./CMSFragmentProps";
import { CMSVideoProps } from "./CMSMediaProps";
import { CMSPersonProps } from "./CMSPersonProps";
import { CMSProductProps } from "./CMSProductProps";
import { CMSSMPCProps } from "./CMSSMPCProps";
import { CMSSpecialtyProps } from "./CMSSpecialtyProps";
import { CMSTextblockProps } from "./CMSTextblockProps";

export enum EventType {
    LIVE = "Live",
    ON_DEMAND = "On demand",
    ON_SITE = "On site",
}

export enum EventRegistrationMethod {
    AUTOMATIC = "Automatic",
    MANUAL = "Manual",
}

export enum LiveEventRegistrationTimeframe {
    UNTIL_END_OF_EVENT = "Until end of live event",
    UNTIL_START_OF_EVENT = "Until start of live event",
}

export enum EventStatus {
    UPCOMING_LIVE = "UPCOMING_LIVE",
    LIVE_SOON = "LIVE_SOON",
    LIVE_NOW = "LIVE_NOW",
    LIVE_FINALISED = "LIVE_FINALISED",
    ON_DEMAND = "ON_DEMAND",
}

export interface CMSEventProps {
    __typename?: string;
    id: string;
    uid?: string;
    iconnect_event_number: string;
    eventTitle: string;
    display_title?: string;
    title_background_color?: "JMC primary color (Blue)" | "White";
    summary: string;
    visual: ImageQueryResponse;
    image_focus: { image_focus: ImagePosition };
    type: EventType;
    eventType?: EventType; //alias of type in some graphql queries
    related_to: (CMSProductProps | CMSSpecialtyProps)[];
    live_event_information: {
        registration_method: EventRegistrationMethod;
        registration_timeframe: LiveEventRegistrationTimeframe;
    };
    on_demand_event_information: {
        registration_method: EventRegistrationMethod;
        closed_registration: boolean;
    };
    on_site_event_information: {
        registration_end_date_time: string;
        registration_start_date_time: string;
    };
    event_structure: { track: EventTrack }[];
    details: {
        text: CMSTextblockProps;
        fragment: { fragment: CMSFragmentProps[] };
    }[];
    cross_selling_content: {
        text: CMSTextblockProps;
        fragment: { fragment: CMSFragmentProps[] };
    }[];
    program: CMSDocumentProps[];
    enable_contact_form: boolean;
    contact: CMSPersonProps[];
    fields?: { footnotes: CMSFootnotes[] };
    smpc_reference: CMSSMPCProps[];
    seo_settings?: { meta_title?: string; meta_description?: string };
    publish_details?: { locale?: string; time?: string };
    internal: { type: string };
    // manually mapped from the backend onto the event
    unpublishDate?: string;
    medical?: boolean;
    registered?: boolean;
    attended?: boolean;
}

export enum CventStyle {
    HYBRID = "Hybrid",
    VIRTUAL = "Virtual",
    FACE2FACE = "Face to Face",
}
export interface CMSCventProps {
    id: string;
    uid?: string;
    iconnect_event_number: string;
    eventTitle: string;
    visual: ImageQueryResponse;
    image_focus: { image_focus: ImagePosition };
    related_to: (CMSProductProps | CMSSpecialtyProps)[];
    publish_details?: { locale?: string; time?: string };
    seo_settings?: { meta_title?: string; meta_description?: string };
    internal: { type: string };
    smpc_reference: CMSSMPCProps[];
    // manually mapped from the backend onto the cvent
    medical?: boolean;
    startDate?: string;
    endDate?: string;
    summaryLink?: string;
    registrationLink?: string;
    location?: { city: string; country: string };
    style?: CventStyle;
    type?: EventType;
}

export type CombinedCvent = {
    id: string;
    uid: string;
    iconnect_event_number: string;
    eventTitle: string;
    name: string;
    eventNumber: string;
    startDate: string;
    endDate: string;
    summaryLink: string;
    registrationLink: string;
    location: { city: string; country: string };
    style: CventStyle;
    publish_details: { locale: string };
    seo_settings: { meta_title: string; meta_description: string };
    visual: ImageQueryResponse;
    image_focus: { image_focus: ImagePosition };
    related_to: (CMSProductProps | CMSSpecialtyProps)[];
    type: string;
    internal: { type: string };
};

export interface EventTrack {
    title: string;
    sessions: { session: EventSession }[];
    livestream: EventLivestream;
}

export interface EventSession {
    title: string;
    summary: string;
    start_date_time: string;
    end_date_time: string;
    speakers: CMSPersonProps[];
    documents: CMSDocumentProps[];
    recording: CMSVideoProps[];
}

export interface EventLivestream {
    livestream_id: string;
    livestream_visual: ImageQueryResponse;
    stream_provider: StreamProvider;
    republish: boolean;
    user_id?: string;
    subscription_id?: string;
    ols_environment?: boolean;
}

export type StreamProvider = "brightcove" | "qumu" | "ols" | "zoom" | "bluejeans" | "other";
