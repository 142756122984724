import { JMCLink } from "@components/JMCLink/JMCLink";
import { FootnotesContext } from "@contexts";
import { containsFootnotes } from "@jmc/js-build-utils/src/footnote-checker";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { TransformFunction } from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { Html } from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { Tooltip } from "@jmc/solid-design-system/src/components/molecules/Tooltip/Tooltip";
import { cleanCSSIdentifier } from "@jmc/utils/utils/clean-css-identifier";
import { CMS_PROMOTIONAL_CONTENT, CMSFootnotes, CMSLinkManagement, Footnote } from "@types";
import classnames from "classnames";
import React, { ClassAttributes, useContext, useEffect, useState } from "react";

import style from "./style.module.scss";

export interface PropTypes extends ClassAttributes<React.FunctionComponent> {
    children: string;
    footnotes?: CMSFootnotes[];
    linkManagement?: CMSLinkManagement[] | null;
    [x: string]: unknown;
    fullLengthFootnotes?: boolean;
    placement?: string;
    "data-test-id"?: string;
    imageLoading?: "eager" | "lazy";
    skipExternalLinkDisclaimers?: boolean;
    closeOverlay?: boolean;
    useLargeText?: boolean;
}

export const JMCHtml: React.FunctionComponent<PropTypes> = ({
    children,
    linkManagement,
    footnotes = [],
    fullLengthFootnotes = false,
    placement,
    imageLoading = "lazy",
    skipExternalLinkDisclaimers = false,
    closeOverlay = false,
    useLargeText = false,
    ...other
}: PropTypes) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    useEffect(() => {
        setHeaderHeight(document.getElementById("main-header")?.offsetHeight ?? 0);
    }, []);
    const transformFootnotes: TransformFunction = (element) => {
        const footnoteIndex = element?.props?.children || "0";
        let footnote = null as Footnote;
        footnotes.forEach(({ footnotes: fnotes, mapped_text }: CMSFootnotes) => {
            const match = fnotes.find(({ index }: Footnote) => index === parseInt(footnoteIndex));
            if (match) {
                if (!footnote) {
                    footnote = match;
                } else if (mapped_text === children) {
                    footnote = match;
                }
            }
        });
        if (footnote) {
            // Forcing a rerender of the footnotes section
            const { setFootnoteCount } = useContext(FootnotesContext);
            setFootnoteCount(footnote.index);
            const unique = `footnote-${footnote.index}-${element.key}`;
            const testId = `footnote-${footnote.index}-${footnote.text.replace(/["']/g, "").substr(0, 25)}`;
            return (
                <Tooltip
                    key={unique}
                    text={
                        footnote.text ? (
                            <Typography variant={"span"}>
                                <JMCHtml>{footnote.text.replace(/&lt;/g, "<").replace(/&gt;/g, ">")}</JMCHtml>
                            </Typography>
                        ) : (
                            ""
                        )
                    }
                    onClick={(): void => document.getElementById(`footnote-${footnote.index}`)?.scrollIntoView()}
                    data-test-id={testId}
                    fullLength={fullLengthFootnotes}
                >
                    <span
                        className={classnames(style.footnotelink, `footnote-${footnote.index}-text`)}
                        data-test-id={`footnote-${footnote.index}-text`}
                        style={{ scrollMarginTop: `${headerHeight}px` }}
                        onClick={(): void => document.getElementById(`footnote-${footnote.index}`)?.scrollIntoView()}
                    >
                        <sup>[{footnote.index}]</sup>
                    </span>
                </Tooltip>
            );
        }
    };

    const stripSlash = (raw: string): string => {
        return raw.replace(/\//g, "");
    };

    const parseLink = (raw: string): string => {
        return raw ? raw.replace(/\s+/g, "-") : raw;
    };

    const transformLinks: TransformFunction = (element, color, children) => {
        const firstChild = element?.props?.children?.[0];
        let anchor;
        if (element?.props?.href?.includes("#")) {
            anchor = cleanCSSIdentifier(element?.props?.href.split("#").pop());
        }

        let linkManagementMatch;
        if (linkManagement && Array.isArray(linkManagement)) {
            linkManagementMatch = linkManagement.find(
                (link) => stripSlash(link.url) === stripSlash(element.props.href),
            );
        }

        let commercial = true;
        if (element?.props?.regulatory && element?.props?.regulatory !== "undefined") {
            commercial = element?.props?.regulatory === CMS_PROMOTIONAL_CONTENT;
        } else if (linkManagementMatch) {
            commercial = linkManagementMatch?.regulatory_status?.promotional_or_medical === CMS_PROMOTIONAL_CONTENT;
        }

        const external =
            !skipExternalLinkDisclaimers &&
            element?.props?.href &&
            !element?.props?.href?.startsWith("mailto") &&
            !element?.props?.href?.startsWith("/") &&
            !anchor;

        const getName = (element: JSX.Element): string => {
            let name = "";
            if (typeof element?.props?.children === "string") {
                name = element?.props?.children;
            } else if (element?.props?.children?.[0]) {
                name = getName(element?.props?.children?.[0]);
            } else if (typeof element === "string" || element instanceof String) {
                name = element as unknown as string;
            } else if (element?.props?.href) {
                name = element?.props?.href;
            }
            return name;
        };
        const scientific = element?.props?.href?.match(/\/services\/scientific[-_]publications\/[a-zA-Z0-9]+$/);

        return (
            <JMCLink
                url={
                    element?.props?.href
                        ? parseLink(
                              element?.props?.href?.includes("#")
                                  ? element.props.href.split("#")[0]
                                  : element.props.href,
                          )
                        : "#"
                }
                external={external}
                commercial={commercial}
                fullWidth={false}
                anchor_id={anchor}
                target={element.props.target === "_self" ? null : element.props.target}
                key={element.props.href}
                name={getName(element)}
                placement={placement}
                htmlLink={true}
                id={element.props.id}
                scientific={scientific}
                closeOverlay={closeOverlay}
            >
                {firstChild?.type === "p" ? (
                    <Typography link>{children}</Typography>
                ) : typeof firstChild === "string" ? (
                    <Typography link component="span" size="inherit">
                        {children}
                    </Typography>
                ) : (
                    <>{children}</>
                )}
            </JMCLink>
        );
    };

    const transformImage: TransformFunction = (element) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <img {...element.props} style={{ maxWidth: "100%" }} loading={imageLoading} />;
    };

    const transformer: TransformFunction = (element, color, size, children) => {
        if (element.type === "sup" && element.props.className?.includes("footnote")) {
            return transformFootnotes(element);
        }
        if (element.type === "a") {
            return transformLinks(element, color, children);
        }
        if (element.type === "img") {
            return transformImage(element);
        }
    };

    /*
    We try to resolve fields to their mapped_text, but in the case of components in private pages,
    this doesn't work and we have to do it here as workaround.
    */
    if (typeof children === "string" && containsFootnotes(children)) {
        //footnotes are being found at the onCreateNode step, this result in an object containing some transformed text (mapped text)
        //and the original text. In the resolver we also assign the regulatory status for internal links in a text field.
        //it may also contain an old-url if it is an embedded link where the page url has been updated.
        //this result in a modification of the original text to include the regulatory status and possibly old-url.
        //Normally this component finds the corresponding footnote by matching the original text with the one in the footnote object
        //however since the regulatory status is added to the original text after the footnotes are collected, the two no longer match
        //So now we use regex to find all instance of regulatory status and map it to which url they correspond
        //then we remove the regulatory status/old-url from the text to then go back to the original unmodified text, which we use to find the
        //corresponding footnote. From this we then use the mapped text and reinsert the regulatory status by using the url.
        const matcherRegex = /(href=".*?") (regulatory=".*?") (old-url=".*?")?/g;
        const removalRegex = / regulatory=".*?"( old-url=".*?")?/g;
        const matchArray = [...children.matchAll(matcherRegex)];
        for (const match of matchArray) {
            if (match[3]) children = children.replace(match[1], match[3].replace("old-url", "href"));
        }
        children =
            footnotes?.find(
                (footnote) =>
                    footnote.text.replace(/</g, "&lt;").replace(/>/g, "&gt;") ===
                    children.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(removalRegex, ""),
            )?.mapped_text || children;
        for (const match of matchArray) {
            const currentHref = match[3] ? match[3].replace("old-url", "href") : match[1];
            const replaceRegex = new RegExp(`${currentHref}(?! regulatory=".*?")`);
            children = children.replace(replaceRegex, `${match[1]} ${match[2]}`);
        }
    }

    return (
        <Html onTransform={transformer} useLargeText={useLargeText} {...other}>
            {children}
        </Html>
    );
};
