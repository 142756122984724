/**
 * Possible login types:
 *
 * - ea-token
 *   Marketing Token	Returned when a marketing token is being used (for marketing campaigns)
 *   grating access to some pages and services that are linked to the campaign
 * - Anonymous Login
 *   Returned when the user is logged in as a level 2 user, which means that the user confirmed
 *   that he is a HCP but without entering his/hers credentials
 * - JanRain 2.0
 *   When JanRain 2.0 is used as auth provider for level 3 authentication meaning that the user
 *   actually logged in using his/hers credentials
 *
 */
export enum LoginTypes {
    EA_TOKEN = "ea-token",
    ANONYMOUS_LOGIN = "Anonymous Login",
    JANRAIN_2_0 = "JanRain 2.0",
    PING = "ping id",
    OKTA = "Okta",
}
