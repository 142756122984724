export interface Language {
    name: string;
    dir: 0 | 1;
    native: string;
}

export const getLanguage = (languageCode: string): Language => {
    const languages = require("./language-data").default;
    const lowerCaseLngCode = languageCode?.toLowerCase();
    const allLanguages = Object.keys(languages);
    const result = allLanguages.reduce((out, key) => {
        if (key === lowerCaseLngCode) {
            out = languages[key];
        }
        return out;
    }, []);
    return result as unknown as Language;
};
