import createApiModule from "../create-api-module";

/**
 * Search interactions between drugs
 */
const { reducer, actions } = createApiModule("drugInteractions", ({ ids }) => `/drug-to-drug/interaction?id=${ids}`);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface DrugToDrugInteraction {
    drugId?: string;
    drugName?: string;
    affectedDrugId?: string;
    affectedDrugName?: string;
    description?: string;
    extendedDescription?: string;
    severity?: string;
    references?: References;
}
export interface References {
    literatureReferences?: LiteratureReference[];
    textbooks?: TextbookReference[];
    externalLinks?: ExternalLinkReference[];
}
export interface LiteratureReference {
    id?: string;
    pubmedId?: number;
    citation?: string;
}
export interface TextbookReference {
    id?: string;
    isbn?: string;
    citation?: string;
}
export interface ExternalLinkReference {
    id?: string;
    title?: string;
    url?: string;
}
