import { default as stripSlash } from "../strip-slash/index.ts";

/**
 * join a variable number of string arguments into a url path, making sure slashes are present and deduplicated.
 */
export const join = (...args: string[]): string => args.join("/").replace(/(https?:\/\/)|(\/){2,}/g, "$1$2");

export const isURLWhitelisted = (pattern: string, url: string): boolean => {
    // remove protocol and query parameters and check whether the whitelisted URL appears in the targetUrl
    const whitelist = pattern
        .replace(/^(ht|f|mail)t(p|o)(s?):\/\//, "")
        .replace(/(\?)([a-zA-Z0-9\-.?,'/\\+()&amp@;%$#=_]*)?$/, "");
    const targetUrl = url
        .replace(/^(ht|f|mail)t(p|o)(s?):\/\//, "")
        .replace(/(\?)([a-zA-Z0-9\-.?,'/\\+()&amp@;%$#=_]*)?$/, "");
    return targetUrl?.search(stripSlash.stripTrailing(whitelist).replace("*", "")) > -1;
};

export default { join, isURLWhitelisted };
