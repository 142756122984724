import { MaterialLink, PageLink } from "./CMSLinkGroupProps";

export interface CMSSMPCProps {
    uid: string;
    title: string;
    display_title: string;
    smpc_source: SMPCSource;
    external_link?: string;
    file_asset?: {
        url: string;
    };
    page_reference: Array<PageLink | MaterialLink>;
    exclude_from_automatic_smpc_collection: boolean;
    generate_detail_page?: boolean;
    product_relation: { uid: string }[];
}

export enum SMPCSource {
    EXTERNAL = "External",
    INTERNAL = "Internal",
    FILE_ASSET = "File asset",
}
