import React from "react";

import { useMegaMenuContext } from "../MegaMenuContext";

interface Props {
    children: JSX.Element;
}

/**
 * This wrapper component is used to handle the mouse enter and mouse leave events for the mega menu
 * and update the context accordingly.
 */
export const Wrapper = (props: Props): JSX.Element => {
    const { children } = props;
    const { megaMenuOpen, setMegaMenuOpen } = useMegaMenuContext(true);

    const handleMouseEnter = (): void => {
        setMegaMenuOpen(true);
    };

    const handleMouseLeave = (): void => {
        if (megaMenuOpen) {
            setMegaMenuOpen(false);
        }
    };

    return (
        <div onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
            {children}
        </div>
    );
};
