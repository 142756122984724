import createApiModule, { State } from "../create-api-module";

/**
 * Obtain the ate question by the id
 * Spec:
 */
const { reducer, actions } = createApiModule("askTheExpertsQuestion", ({ questionId }) => {
    return `/ask-the-experts/questions/${questionId}`;
});
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface QuestionState extends State {
    data: Question;
}
export interface Question {
    id: string;
    text: string;
    title: string;
    question: string;
    answer: string;
    feedback: string;
    date: string;
    status: "new" | "draft" | "responded" | "rejected";
    expertId: string;
    specialty: string;
}
