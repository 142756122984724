import React from "react";
import style from "./style.module.scss";
import classnames from "classnames";

export type BreakPoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

interface PropTypes {
    children?: JSX.Element | string;
    min?: BreakPoint;
    max?: BreakPoint;
    only?: BreakPoint;
    className?: Partial<CSSStyleDeclaration>;
}

export function getMediaQueryClass(props: PropTypes) {
    const { min = undefined, max = undefined, only = undefined } = props;
    if (only) {
        return `only-${only}`;
    } else if (min && !max) {
        return `min-${min}`;
    } else if (!min && max) {
        return `max-${max}`;
    } else {
        return `between-${min}-${max}`;
    }
}

export const MediaQuery = (props: PropTypes) => {
    const { children } = props;
    const mediaQueryClassName = getMediaQueryClass(props);
    return (
        <div
            className={classnames(style.component, style[mediaQueryClassName], props.className)}
            data-test-id={mediaQueryClassName}
        >
            {children}
        </div>
    );
};

MediaQuery.displayName = "MediaQuery";
