import createApiModule from "../create-api-module";
/**
 * Obtain the courses available on moodle
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/get-lms-courses
 */
const { reducer, actions } = createApiModule("courses", ({ courses }) => {
    if (courses && typeof courses === "string" && courses !== "") {
        return `/lms/courses?courses=${courses}`;
    }

    return `/lms/courses`;
});

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Course {
    id: number;
    shortname: string;
    fullname: string;
    summary: string;
    tags: { course: { Site?: string; certificationpoints?: number; country?: string; duration?: number } };
    chapters: CourseChapter[];
    startdate: string;
    enddate: string;
    timecreated: string;
}

export interface CourseChapter {
    id: number;
    fullname: string;
    lessons: CourseLesson[];
    timecreated: string;
}

export interface CourseLesson {
    id: string;
    name: string;
    scormSummary: string;
    scormUrl: string;
    sortnum: number;
}
