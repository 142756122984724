/* eslint-disable react/display-name */
import React, { ReactNode } from "react";
import regexifyString from "regexify-string";
import { Tooltip } from "@jmc/solid-design-system/src/components/molecules/Tooltip/Tooltip";
import { ToolTipContext } from "../components/atoms/Typography/ToolTipContext/ToolTipContext";

// Legal requirement to always render ® superscripted, but Verdana Pro's glyph is not, while Karbon's is.
const superscriptCopyrightSymbol = (input: string): ReactNode => {
    return regexifyString({
        pattern: /®|©/gimu,
        decorator: (match) => {
            return <sup data-test-id={`superscripted-${match}`}>{match}</sup>;
        },
        input: input,
    });
};

// Legal requirement to render the pharmacovigilance triangle ▼ in black at all times (https://www.gmp-compliance.org/gmp-news/black-triangle-a-new-eu-requirement)
const blackPharmacovigilanceTriangle = (input: string): ReactNode => {
    return regexifyString({
        pattern: /▼/gimu,
        decorator: (match) => {
            return (
                <ToolTipContext.Consumer>
                    {(value) => {
                        const tooltipText: any = value;
                        return (
                            <Tooltip text={tooltipText} fullLength>
                                <span
                                    ref={(el) => {
                                        // !important can't be used in React inline styles, but we believe this is a valid use case
                                        if (el) {
                                            el.style.setProperty("color", "black", "important");
                                            el.style.setProperty(
                                                "-webkit-text-stroke",
                                                "1px rgba(255, 255, 255, 0.33)",
                                            );
                                        }
                                    }}
                                >
                                    {match}
                                </span>
                            </Tooltip>
                        );
                    }}
                </ToolTipContext.Consumer>
            );
        },
        input: input,
    });
};

export function transformSpecialChars(node: React.ReactNode, ignoreCopyrightSuperscripting: boolean): React.ReactNode {
    let finalChildren = node;

    finalChildren = React.Children.map(finalChildren, (child) => {
        if (typeof child === "string" && !ignoreCopyrightSuperscripting) {
            return superscriptCopyrightSymbol(child);
        }
        return child;
    });

    finalChildren = React.Children.map(finalChildren, (child) => {
        if (typeof child === "string") {
            return blackPharmacovigilanceTriangle(child);
        }
        return child;
    });

    return finalChildren;
}
