import createApiModule from "../create-api-module";
/**
 * Obtain the eligible events for the logged in user
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/get-events-eligible
 */
const { reducer, actions } = createApiModule("eligibleEvents", () => `/events/eligible`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface EligibleEvent {
    id: string;
    eventNumber: string;
    publicationEndDate: string;
    status: "Eligible";
    medical: boolean;
}
