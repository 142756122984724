export type Region = "EMEA" | undefined;

export interface Country {
    countryName: string;
    localizedCountryName?: string;
    countryCode: string;
    dialCode: string;
}

export interface Whitelists {
    EMEA: any;
}

export const getCountries = (region: Region = undefined): Country[] => {
    const whitelists = require("./whitelists").default as any;
    const countries = require("./country-data").default as any as Country[];

    if (region && whitelists[region]) {
        const whitelist = whitelists[region];
        return countries.filter((country) => whitelist.includes(country.countryCode));
    }
    return countries;
};

export const getCountry = (countryCode: string) => {
    const countries = require("./country-data").default as any as Country[];
    return countries.find((country) => country.countryCode === countryCode);
};
