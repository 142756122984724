import createValueModule from "../create-value-module";
//this store slice is used to pass the list of related products from which smpcs must be found from the overview block to the corresponding detail page
const { reducer, actions } = createValueModule("smpcProducts", {
    products: [],
    refs: [],
});
export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
