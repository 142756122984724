import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { ImagePosition } from "@jmc/solid-design-system/src/components/molecules/Card/Card";

import { CMSFootnotes } from "./CMSFootnotes";
import { CMSProductProps } from "./CMSProductProps";
import { CMSRegulatoryStatus } from "./CMSRegulatoryStatus";
import { CMSSMPCProps } from "./CMSSMPCProps";
import { CMSSpecialtyProps } from "./CMSSpecialtyProps";
import { IMR } from "./IMR";

export enum CourseCompletionStatus {
    NOT_STARTED = "not started",
    IN_PROGRESS = "in progress",
    COMPLETED = "completed",
}

export interface CMSCourseProps {
    id: string;
    uid: string;
    title: string;
    display_title?: string;
    moodle_course_number: string;
    visual: ImageQueryResponse;
    image_focus: { image_focus: ImagePosition };
    related_to: (CMSProductProps | CMSSpecialtyProps)[];
    regulatory_status: CMSRegulatoryStatus;
    fields?: { footnotes: CMSFootnotes[] };
    created_at: string;
    publish_details?: { locale: string };
    smpc_reference: CMSSMPCProps[];
    // manually mapped from the backend onto the course
    progress?: number;
    duration?: number;
    fullName?: string;
    summary?: string;
    chapters?: CMSCourseChapter[];
    completionStatus?: CourseCompletionStatus;
    timestamp?: string;
    tags?: string;
    coursePdfs?: { name: string; url: string }[];
    scormThumbnailUrl?: string;
    certificationPoints?: number;
    display_on_detail_page: boolean;
    event_id?: string;
    imedical_review: {
        imr: IMR[];
    };
    medical?: boolean;
}

export interface CMSCourseChapter {
    id: number;
    fullname: string;
    completionStatus?: CourseCompletionStatus;
    lessons: CMSCourseLesson[];
    role?: string;
    available?: number;
    timecreated: string;
    timestamp?: string;
}

export interface CMSCourseLesson {
    id: string;
    name: string;
    scormSummary: string;
    scormUrl: string;
    scormThumbnailUrl?: string;
    certificateUrl?: string;
    completionStatus?: CourseCompletionStatus;
    role?: string;
    available?: number;
    sortnum: number;
    timestamp?: string;
    duration?: number;
}
