import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule("podcast", ({ id }) => `/podcasts/${id}`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Podcast {
    id: string;
    title: string;
    image: string;
    private: boolean;
}
