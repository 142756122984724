import { JMCLink } from "@components/JMCLink/JMCLink";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import React from "react";

import style from "./style.module.scss";
import { isCyrillictext } from "./utils";

interface PropTypes {
    id: string;
    url: string;
    title: string;
    linkname?: string;
    labelStyle?: boolean;
}

const ExternalMenuLink: React.FunctionComponent<PropTypes> = ({
    id,
    url,
    title,
    linkname = null,
    labelStyle = false,
}: PropTypes) => {
    const { jnjFullBranded } = useJnjBranding();
    return (
        <div id={id} data-linkname={linkname} data-test-id={`ExternalLink.${title}`} className={style.link}>
            <JMCLink url={url} external={true} commercial={true} fullWidth={!(labelStyle && jnjFullBranded)}>
                {labelStyle && jnjFullBranded ? (
                    <Typography variant="label-03-link" link>
                        <Html data-test-id="Link.Title" isCyrillic={isCyrillictext(title)}>
                            {title}
                        </Html>
                    </Typography>
                ) : (
                    <Html data-test-id="Link.Title" isCyrillic={isCyrillictext(title)}>
                        {title}
                    </Html>
                )}
            </JMCLink>
        </div>
    );
};

export default ExternalMenuLink;
