import createApiModule from "../create-api-module";
/**
 * Post a question towards janssen's
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/post-hcps-codsId-questions
 */
const { reducer, actions } = createApiModule("askJanssen", () => `/questions`, {
    method: "POST",
});
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface AskJanssen {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    phoneNumber?: string;
    recaptchaValue?: string;
    eventNumber?: string;
}
