import {
    ArticleLink,
    AskTheExpertsLink,
    AudioLink,
    CourseLink,
    DrugbankLink,
    EventCenterLink,
    EventLink,
    ExternalResourceLink,
    IodLink,
    LmsLink,
    MaterialLink,
    MediaCenterLink,
    MyJmcLink,
    NewsCenterLink,
    PageLink,
    PodcastCenterLink,
    PodcastLink,
    ProductGlossaryLink,
    PubmedLink,
    RequestMaterialsLink,
    ScientificPublicationDetailsLink,
    ScientificPublicationLink,
    ServiceOverviewLink,
    SpecialtiesLink,
    TcpHomeLink,
    TcpLink,
    TransferOfValueLink,
    VideoLink,
} from "@types";

import { CMSInterestingLink } from "./CMSInterestingLink";

export enum MenuItemVisibility {
    DISPLAY_ITEM_WITH_LOCK = "Display item with lock",
    HIDE_ITEM = "Hide item",
}

export interface CMSInternalPageMenuItem {
    menu_label: string;
    anchor_id?: string;
    link: [
        | ArticleLink
        | AskTheExpertsLink
        | AudioLink
        | CourseLink
        | DrugbankLink
        | EventCenterLink
        | EventLink
        | ExternalResourceLink
        | IodLink
        | LmsLink
        | MaterialLink
        | MediaCenterLink
        | MyJmcLink
        | NewsCenterLink
        | PageLink
        | PodcastLink
        | PodcastCenterLink
        | ProductGlossaryLink
        | PubmedLink
        | RequestMaterialsLink
        | ScientificPublicationDetailsLink
        | ScientificPublicationLink
        | ServiceOverviewLink
        | SpecialtiesLink
        | TcpLink
        | TcpHomeLink
        | TransferOfValueLink
        | VideoLink,
    ];
    link_continued: string;
    visibility: MenuItemVisibility;
    internal_link_parameters?: {
        value: { key: string; value: string }[];
    };
}

export interface CMSExternalPageMenuItem {
    link: {
        href: string;
        title: string;
    };
}

export interface CMSFileAssetMenuItem {
    menu_label?: string;
    file_asset_upload: {
        url?: string;
    };
    visibility: MenuItemVisibility;
}

export interface CMSSubMenu {
    menu_label: string;
    link: {
        internal_page_link: CMSInternalPageMenuItem;
        external_link?: CMSExternalPageMenuItem;
        file_asset: CMSFileAssetMenuItem;
    };
    menu_item: CMSSiteMenuItem[];
}

export interface CMSSiteMenuItem {
    internal_page_link?: CMSInternalPageMenuItem;
    external_link?: CMSExternalPageMenuItem;
    file_asset?: CMSFileAssetMenuItem;
    submenu?: CMSSubMenu;
}

export interface CMSInterestingMenuLink {
    internal_link?: CMSInternalPageMenuItem;
    external_link?: CMSExternalPageMenuItem;
    file_asset?: CMSFileAssetMenuItem;
}

export interface CMSConfigurationsProps {
    logo: {
        url: string;
    };
    site_menu: CMSSiteMenuItem[];
    interesting_links: CMSInterestingLink[];
    locale: string;
    publish_details: {
        locale: string;
    };
}
