import "./i18n";

import { loadTranslations } from "@utils/translations/translationsLoader";
import { graphql, useStaticQuery } from "gatsby";
import { TFunction } from "i18next";
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TranslateContext = createContext(null);

export const TranslationProvider = ({
    language,
    children,
}: {
    language: string;
    children: JSX.Element;
}): JSX.Element => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.documentElement.dir = language ? i18n.dir() : "ltr";
    }, [language]);

    const { translations } = useStaticQuery(
        graphql`
            query {
                translations: allContentstackLiteral {
                    nodes {
                        title
                        translation
                        context
                        locale
                    }
                }
            }
        `,
    );

    loadTranslations(i18n, translations.nodes);
    if (language !== i18n.language && language) {
        i18n.changeLanguage(language);
    }

    return <TranslateContext.Provider value={t}>{children}</TranslateContext.Provider>;
};

export const withTranslate = <T extends Record<string, unknown>>(
    Component: React.ReactNode,
): ((props: Partial<T>) => JSX.Element) => {
    const WithTranslate = (props: Partial<T>): JSX.Element => (
        <TranslateContext.Consumer>
            {(translate: TFunction): JSX.Element => <Component {...props} t={translate} />}
        </TranslateContext.Consumer>
    );
    return WithTranslate;
};

export const TranslationProviderMock = ({
    language,
    children,
}: {
    language: string;
    children: JSX.Element;
}): JSX.Element => {
    return (
        <TranslateContext.Provider value={(key: string): string => `${language}: ${key}`}>
            {children}
        </TranslateContext.Provider>
    );
};

export default { withTranslate, TranslationProvider };
