import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

export const changeLocalization = async (locale: string): Promise<typeof import("dayjs/locale/en")> => {
    let file;
    if (!locale) {
        return await import("dayjs/locale/en");
    }
    let finalLocale = locale.toLowerCase();
    try {
        file = await import("dayjs/locale/" + finalLocale);
    } catch (e) {
        const localeIdentifier =
            finalLocale.match(/-/g)?.length > 1 ? finalLocale.substring(3) : finalLocale.substring(0, 2);
        try {
            const lang = new Intl.Locale(localeIdentifier).language;
            finalLocale = lang;
        } catch (e) {
            finalLocale = localeIdentifier?.split("-")?.[0];
        }
        try {
            file = await import("dayjs/locale/" + finalLocale);
        } catch (e) {
            finalLocale = "en";
            file = await import("dayjs/locale/en");
        }
    }
    dayjs.locale(finalLocale);
    return file;
};

export const formatDate = (date: string | Date, format?: string): string => {
    dayjs.extend(LocalizedFormat);

    return dayjs(date).format(format ? format : "L");
};

export default formatDate;
