import { useEffect, useState } from "react";
import yn from "yn";
import { CookieConsent } from "@jmc/solid-design-system/src/utils/cookieConsent";

export const SCRIPT_STATUS = {
    IDLE: "idle",
    LOADING: "loading",
    READY: "ready",
    ERROR: "error",
    BLOCKED: "blocked",
};

/**
 * This hook makes it super easy to dynamically load an external script and know when its loaded.
 * This is useful when you need to interact with a 3rd party library (Stripe, Google Analytics, etc)
 * and you'd prefer to load the script when needed rather then include it in the document head for
 * every page request. In the example below we wait until the script has loaded successfully before
 * calling a function declared in the script.
 *
 * @see https://usehooks.com/useScript/
 * @example
 *  import { useScript } from "@hooks";
 *  const MyComponent = () => {
 *      const scriptStatus = useScript(`https://external.js`);
 *      React.useEffect(() => {
 *          if (scriptStatus === "ready") {
 *              // do something
 *          }
 *      }, [scriptStatus])
 *      return (<></>)
 *  }
 */

const useScript = (
    src: string,
    target: "body" | "head" = "body",
    consentCategory:
        | typeof CookieConsent.STRICTLY_NECESSARY
        | typeof CookieConsent.PERFORMANCE_COOKIES
        | typeof CookieConsent.FUNTIONAL_COOKIES
        | typeof CookieConsent.MEDIA_COOKIES
        | typeof CookieConsent.TARGETING_COOKIES = CookieConsent.FUNTIONAL_COOKIES,
    dataDomainScript: string = undefined,
    id: string = undefined,
    innerHTML: string = undefined,
) => {
    // Keep track of script status ("idle", "loading", "ready", "error", "blocked")
    const [status, setStatus] = useState(src ? SCRIPT_STATUS.LOADING : SCRIPT_STATUS.IDLE);

    useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src) {
                setStatus(SCRIPT_STATUS.IDLE);
                return;
            }

            // Fetch existing script element by src
            // It may have been added by another instance of this hook
            let script: HTMLScriptElement = document.querySelector(`script[src="${src}"]`);

            // Script event handler to update status in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event: Event) => {
                setStatus(event.type === "load" ? SCRIPT_STATUS.READY : SCRIPT_STATUS.ERROR);
            };

            const registerStatusEventListeners = () => {
                // Add event listeners
                script.addEventListener("load", setStateFromEvent);
                script.addEventListener("error", setStateFromEvent);
            };

            const insertScript = () => {
                // Onetrust Auto-blocking doesn't work for scripts injected at runtime, so manually block it.
                // see https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca
                // and https://community.cookiepro.com/s/article/UUID-c5122557-2070-65cb-2612-f2752c0cc4aa

                if (
                    // eslint-disable-next-line no-undef
                    yn(process.env.GATSBY_DISABLE_COOKIEBANNER) ||
                    // eslint-disable-next-line no-undef
                    yn(process.env.STORYBOOK) ||
                    (window.OptanonActiveGroups && window.OptanonActiveGroups.split(",").includes(`${consentCategory}`))
                ) {
                    script = document.createElement("script");
                    script.src = src;
                    script.async = true;
                    script.setAttribute("data-status", SCRIPT_STATUS.LOADING);
                    script.setAttribute("data-domain-script", dataDomainScript);

                    if (id) script.id = id;
                    if (innerHTML) script.innerHTML = innerHTML;

                    // Add script to document body or head
                    switch (target) {
                        case "head":
                            document.head.appendChild(script);
                            break;
                        case "body":
                        default:
                            document.body.appendChild(script);
                    }
                    // no need to register it again
                    window.removeEventListener("OptanonActiveGroupsChanged", insertScript);

                    // Store status in attribute on script
                    // This can be read by other instances of this hook
                    const setAttributeFromEvent = (event: Event) => {
                        script.setAttribute(
                            "data-status",
                            event.type === "load" ? SCRIPT_STATUS.READY : SCRIPT_STATUS.ERROR,
                        );
                    };

                    script.addEventListener("load", setAttributeFromEvent);
                    script.addEventListener("error", setAttributeFromEvent);

                    registerStatusEventListeners();
                } else {
                    setStatus(SCRIPT_STATUS.BLOCKED);
                }
            };

            if (!script) {
                // Create script when required consent is provided
                window.addEventListener("OptanonActiveGroupsChanged", insertScript);

                // try now in case consent was already given
                insertScript();
            } else {
                // Grab existing script status from attribute and set to state.
                setStatus(script.getAttribute("data-status"));
                registerStatusEventListeners();
            }

            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.remove();
                    script.removeEventListener("load", setStateFromEvent);
                    script.removeEventListener("error", setStateFromEvent);
                }
                window.removeEventListener("OptanonActiveGroupsChanged", insertScript);
            };
        },
        [src], // Only re-run effect if script src changes
    );
    return status;
};

export default useScript;
