//import styles
import style from "./style.module.scss";

//import libraries
import React from "react";
import { mdiMinus, mdiPlus } from "@mdi/js";

import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Tooltip } from "@jmc/solid-design-system/src/components/molecules/Tooltip/Tooltip";

interface Props {
    min?: number;
    max?: number;
    value?: number;
    decreaseText?: string;
    increaseText?: string;
    maxAmountText?: string;
    onChange?: (amount: number) => void;
}

export const NumberInput = (props: Props) => {
    const [value, setValue] = React.useState(props.value || 0);
    const {
        min = 0,
        max = 9999,
        onChange,
        decreaseText = "Decrease",
        increaseText = "Increase",
        maxAmountText = "Max amount reached",
    } = props;

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div className={style.element} data-test-id="NumberInput">
            <Button
                variant="outlined"
                color="secondary"
                rounded={false}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                    e.preventDefault();
                    if (value > min) {
                        setValue(value - 1);
                        if (onChange) {
                            onChange(value - 1);
                        }
                    }
                }}
                data-test-id={`NumberInput.DecreaseButton`}
                aria-label={decreaseText}
                title={decreaseText}
            >
                <Icon icon={mdiMinus} color="inherit" />
            </Button>
            <input
                data-test-id={`NumberInput.Value`}
                type="number"
                value={value}
                min={min}
                max={max}
                onChange={(event) => {
                    const number = parseInt(event.target.value);
                    if (isNaN(number)) setValue(null);
                    if (number < min) {
                        setValue(min);
                        if (onChange) onChange(min);
                    }
                    if (number > max) {
                        setValue(max);
                        if (onChange) onChange(max);
                    }
                    if (number <= max && number >= min) {
                        setValue(number);
                        if (onChange) onChange(number);
                    }
                }}
            />
            <Tooltip fullLength={true} text={maxAmountText} showToolTip={value === max}>
                <Button
                    variant="outlined"
                    color="secondary"
                    rounded={false}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                        e.preventDefault();
                        if (value < max) {
                            setValue(value + 1);
                            if (onChange) {
                                onChange(value + 1);
                            }
                        }
                    }}
                    data-test-id={`NumberInput.IncreaseButton`}
                    aria-label={increaseText}
                    disabled={value === max}
                    title={value != max && increaseText}
                >
                    <Icon icon={mdiPlus} color="inherit" />
                </Button>
            </Tooltip>
        </div>
    );
};

export default NumberInput;
