export interface AutoCompleteType {
    getListProps: () => void;
    getItemProps: (params: { item: Record<string, unknown>; source: { sourceId: string } }) => void;
    getInputProps: (params: unknown) => { onFocus(e: unknown): void; onBlur(): void };
    getFormProps: (params: unknown) => { onReset(e: unknown): void; onSubmit(e: unknown): void };
    getRootProps: (params: unknown) => { params: unknown };
    getPanelProps: (params: unknown) => { params: unknown };
    refresh: () => void;
    setIsOpen: (state: boolean) => void;
}

export const AUTOCOMPLETE_RESULTS = "autocompleteResults";
export const QUERY_SUGGESTIONS = "querySuggestionsPlugin";
export const RECENT_SEARCHES = "recentSearchesPlugin";
export const RECENT_SEARCHES_PLUGIN_NAME = "aa.localStorageRecentSearchesPlugin";
