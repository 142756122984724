import "intersection-observer";

import { useEffect, useState } from "react";

export default function useOnScreen(ref: { current: Element }, thresholdvalue = 0): boolean {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry?.isIntersecting), {
            threshold: thresholdvalue,
        });

        try {
            observer?.observe(ref?.current);
        } catch {
            /* empty */
        }

        return (): void => {
            observer?.disconnect();
        };
    }, []);

    return isIntersecting;
}
