import analyticstracker from "@jmc/analyticstracker";
import { useScroll } from "@jmc/utils/hooks/useScroll";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { EventTypes } from "../../types/EventTypes";
import React, { useState, useEffect } from "react";
import { globalHistory } from "@reach/router";
import style from "./style.module.scss";

export interface PropTypes {
    children: JSX.Element;
}

export const ScrollImpression: React.FunctionComponent<PropTypes> = ({ children }: PropTypes) => {
    const { interacted } = useCookieInteraction();
    const [scrolled, setScrolled] = useState(0);

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === "PUSH" || action === "POP") {
                setScrolled(0);
            }
        });
    }, []);

    const calculateScrolledPercentage = (): number => {
        const height = document.body.scrollHeight || document.body.offsetHeight;
        const yScrolled = window.pageYOffset || document.body.scrollTop;
        const windowHeight = document.documentElement.clientHeight || window.innerHeight;

        const scrolledPercentage = ((yScrolled + windowHeight) / height) * 100;
        return Math.floor(scrolledPercentage / 25) * 25;
    };

    useScroll(() => {
        const percentage = calculateScrolledPercentage();
        if (
            (document.readyState === "complete" || document.readyState === "interactive") &&
            percentage > scrolled &&
            percentage >= 25
        ) {
            setScrolled(percentage);

            // We only track the 75% scroll depth because we can send an impression only once: https://stitchd.gitbook.io/gddl/the-gddl-framework/triggers#impressions
            if (percentage >= 75 && interacted) {
                setTimeout(() => {
                    analyticstracker().trackImpression(EventTypes.SCROLL_IMPRESSION, {
                        addData: { info: { "scroll-depth": 75 } },
                    });
                }, 1);
            }
        }
    }, 1);

    return (
        <div role="none" data-tracking-event={EventTypes.SCROLL_IMPRESSION} className={style.grow}>
            {children}
        </div>
    );
};

export default ScrollImpression;
