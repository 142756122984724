import React, { ReactNode, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import classNames from "classnames";

interface PropTypes {
    name: string;
    value?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: ({ target: { checked } }: { target: { checked: boolean } }) => void;
    children: ReactNode;
    toggle?: boolean; // Show the control as a toggle switch
    color: "primary" | "secondary";
}

export const CheckBox = React.forwardRef(
    (
        { name, value, checked, disabled, onChange, toggle = false, children, color = "primary", ...other }: PropTypes,
        ref: React.RefObject<HTMLInputElement>,
    ) => {
        const id = `checkbox_${name}_${value}`;
        const checkBoxRef = useRef(null);
        const [focused, setFocused] = useState(false);

        useEffect(() => {
            // updates the tab focus status when clicking outside.
            const checkBoxClickHandler: EventListener = (event: Event) => {
                if (checkBoxRef?.current && !checkBoxRef.current.contains(event.target as Node)) {
                    setFocused(false);
                }
            };

            document.addEventListener("mouseup", checkBoxClickHandler);
            return (): void => {
                document.removeEventListener("mouseup", checkBoxClickHandler);
            };
        }, [checkBoxRef, focused]);

        return (
            <div
                className={classNames(
                    style.checkbox,
                    focused ? style.focused : null,
                    toggle ? style.toggle : null,
                    style[color],
                )}
                ref={checkBoxRef}
                onKeyUp={(e: React.KeyboardEvent) => {
                    if (e.key === "Tab") {
                        setFocused(true);
                    }
                }}
                role="presentation"
            >
                <input
                    className={style.box}
                    type="checkbox"
                    name={name}
                    value={value}
                    disabled={disabled}
                    data-test-id={`CheckBox.Box.${name}.${value}`}
                    onChange={onChange}
                    checked={checked}
                    ref={ref}
                    id={id}
                    {...other}
                />
                <label htmlFor={id} className={style.label} data-test-id={`CheckBox.Label.${name}.${value}`}>
                    <Typography color="inherit">{children}</Typography>
                </label>
            </div>
        );
    },
);

CheckBox.displayName = "CheckBox";
