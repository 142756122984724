import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { useScript } from "@jmc/solid-design-system/src/hooks/useScript";
import { Script } from "gatsby";
import React, { useEffect } from "react";
import { stripIndent } from "common-tags";

interface PropTypes {
    pathname: string;
    profile: ProfileType;
}

interface ProfileType {
    loading: boolean;
    data: { codsId: string; country: string };
}

/**
 * Loads the Medallia script.
 *
 * Can be disabled by setting GATSBY_MEDALLIA_COUNTRY_CODE to false.
 */
const Medallia: React.FunctionComponent<PropTypes> = ({ pathname, profile }: PropTypes): JSX.Element => {
    if (process.env.GATSBY_MEDALLIA_COUNTRY_CODE && process.env.GATSBY_MEDALLIA_COUNTRY_CODE !== "false") {
        const status = useScript(
            "https://resources.digital-cloud.medallia.eu/wdceu/" +
                process.env.GATSBY_MEDALLIA_COUNTRY_CODE +
                "/onsite/embed.js",
        );
        const locale = useLocale();
        const countryCode = locale?.split("-")?.[0];
        const { codsId, country } = profile?.data || {};

        useEffect(() => {
            if (
                codsId &&
                window?.KAMPYLE_ONSITE_SDK &&
                typeof window?.KAMPYLE_ONSITE_SDK?.updatePageView === "function"
            ) {
                window.KAMPYLE_ONSITE_SDK.updatePageView();
            }
        }, [status, pathname, codsId]);

        if (codsId) {
            return (
                <Script
                    id="medallia-vars"
                    key="medallia-vars"
                    type="text/javascript"
                    strategy="post-hydrate"
                    dangerouslySetInnerHTML={{
                        __html: stripIndent(`
                        var loginid = "${codsId}";
                        var locale = "${countryCode}";
                        var country = "${country}";`),
                    }}
                />
            );
        }
    }

    return <></>;
};

export default Medallia;
