/**
 * The 3 states in which redux can be while altering values
 */
export enum VALUE_STAGE_ENUM {
    RESET = "RESET",
    SET = "SET",
    MERGE = "MERGE",
}

/**
 * Used to create the reducer constants as a generic type
 * The string value is the action key for apiModules this would often be value/test/RESET.
 * As it is created by the utils.ts#createReducer method.
 */
export interface ValueStageConstants {
    [VALUE_STAGE_ENUM.RESET]: string;
    [VALUE_STAGE_ENUM.SET]: string;
    [VALUE_STAGE_ENUM.MERGE]: string;
}

/**
 *
 * Lower case array of the value stages used by the reducer to create the constants.
 */
export const LOWER_CASE_VALUE_STAGES = Object.keys(VALUE_STAGE_ENUM as Record<string, unknown>).map((v) =>
    v.toLowerCase(),
);
