import { ApplicationState } from "@redux/modules";
import { load as loadChatToken } from "@redux/modules/chat";
import isEqual from "lodash/isEqual";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

declare global {
    interface Window {
        now4real: any;
    }
}

const Now4Real = (): JSX.Element => {
    const dispatch = useDispatch();
    const jwt = useSelector((state: ApplicationState) => state.chat.data, isEqual);
    const token = jwt?.token;

    React.useEffect(() => {
        dispatch(loadChatToken());
    });

    React.useEffect(() => {
        if (typeof window === "undefined") return;
        if (!token) return;

        const now4real = (window.now4real = window.now4real || {});

        now4real.config = {
            scope: "page",
            widget: {
                align: "right",
                align_mobile: "right",
                vertical_distance: "60px",
                vertical_distance_mobile: "60px",
                horizontal_distance_mobile: "-10px",
                language: "it",
                map_enabled: false,
                ranking_enabled: false,
                logo_url:
                    "https://static.janssen-emea.com/sites/default/files/Italy/uploads/siti/JMC/JCHAT-NOW4REAL/JChat.png",
                welcome_message_img_url:
                    "https://static.janssen-emea.com/sites/default/files/Italy/uploads/siti/JMC/JCHAT-NOW4REAL/icon-blue-JChat.png",
                welcome_message:
                    'JChat è il servizio di messagistica istantanea di Janssen Medical Cloud. Messaggia con gli altri medici che stanno visitando il sito nel tuo stesso momento. Ricorda: i tuoi messaggi saranno visibili agli altri utenti solo per 1 minuto. Clicca <a href="https://static.janssen-emea.com/sites/default/files/Italy/uploads/legal_documents/janssenmedicalcloud/jchat-now4real-regole-ingaggio.html">qui</a> per prendere visione delle regole di ingaggio.',
            },
            custom_auth: {
                enabled: true,
                jwt: token,
                consent_message:
                    'Se vuoi chattare occorre inoltre accettare la <a href="https://static.janssen-emea.com/sites/default/files/Italy/uploads/legal_documents/janssenmedicalcloud/jchat-now4real-informativa-privacy.html">Privacy Policy</a> del widget JChat di Janssen-Cilag SpA.',
            },
        };

        (function () {
            const n4r = document.createElement("script");
            n4r.type = "text/javascript";
            n4r.async = true;
            n4r.src = "https://cdn.now4real.com/now4real.js";
            const s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(n4r, s);
        })();

        now4real.onerror = (error: { code: number; message: string }) => {
            // eslint-disable-nextline no-console
            console.error("Now4real has failed to load", error.code, error.message);
        };
    }, [token]);
    return <></>;
};

export default Now4Real;
