import createApiModule from "../create-api-module";

/**
 * Search for drugs
 */
const { reducer, actions } = createApiModule(
    "drugSearch",
    ({ searchTerm }) => `/drug-to-drug/search?term=${searchTerm}`,
);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Drug {
    drugId: string;
    name: string;
}
