import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import React from "react";

import LogoWhite from "../../images/JJ_IM_Logo_SingleLine_White_RGB.svg";
import JJ_LogoWhite from "../../images/JJ_Logo_White.svg";
import LogoColor from "../../images/JJ_MedicalCloud_Logo.svg";
import JanssenLogoColor from "../../images/JMC-logo-color.svg";
import JanssenLogoFooter from "../../images/JMC-logo-footer.svg";
import JanssenLogoWhite from "../../images/JMC-logo-white.svg";
import style from "./style.module.scss";

const LOGO_MAP = {
    default: LogoColor,
    white: LogoWhite,
    footer: LogoWhite,
};

const JJ_LOGO_MAP = {
    default: LogoColor,
    white: LogoWhite,
    footer: JJ_LogoWhite,
};

const JANSSEN_LOGO_MAP = {
    default: JanssenLogoColor,
    white: JanssenLogoWhite,
    footer: JanssenLogoFooter,
};

interface PropTypes {
    variant?: "default" | "white" | "footer";
    id?: string;
    sizing?: boolean;
    width?: string;
    height?: string;
    loading?: "eager" | "lazy";
    "data-test-id"?: string;
}

export const Logo = ({
    variant = "default",
    sizing = false,
    width,
    height,
    loading = "eager",
    id,
    ...rest
}: PropTypes): JSX.Element => {
    const { jnjBranded, jnjFullBranded } = useJnjBranding();
    const logoMap = jnjFullBranded ? JJ_LOGO_MAP : jnjBranded ? LOGO_MAP : JANSSEN_LOGO_MAP;
    return (
        <img
            className={sizing ? style.elementSize : style.element}
            src={logoMap[variant]}
            alt="Logo"
            height={height}
            width={width}
            loading={loading}
            data-test-id={rest?.["data-test-id"]}
            onClick={rest?.onClick}
            id={id}
        />
    );
};
