import { createReducer } from "./create-value-reducer";
import { createConstants } from "./utils";
import { LOWER_CASE_VALUE_STAGES, ValueStageConstants } from "./value-stages-enum";

export interface Action {
    type: string;
    payload?: Record<string, unknown>;
}

/**
 * Create value module
 */
export default (namespace: string, initialState: Record<string, unknown>): Record<string, unknown> => {
    const constants = createConstants<ValueStageConstants>(LOWER_CASE_VALUE_STAGES, "value", namespace);

    return {
        constants,
        reducer: createReducer(constants, initialState),
        actions: {
            clear: (): Action => ({
                type: constants.RESET,
            }),
            set: (payload: Record<string, unknown>): Action => ({
                type: constants.SET,
                payload,
            }),
            update: (payload: Record<string, unknown>): Action => ({
                type: constants.MERGE,
                payload,
            }),
        },
    };
};
