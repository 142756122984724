import { ApplicationState } from "@redux/modules";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";

export const useIsProfileLoaded = (): boolean => {
    const authData = useSelector((state: ApplicationState) => state.authValid, isEqual);
    const profile = useSelector((state: ApplicationState) => state.profile, isEqual);
    if (
        !authData.loading &&
        !profile.loading &&
        ((authData.data && (profile.data || profile.error)) || (!authData.data && !profile.data))
    ) {
        return true;
    }
    return false;
};
