import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "../../types/EventTypes";
import React, { useRef, useEffect } from "react";
import { useIntersection } from "react-use";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    type: string;
    category?: string;
    eventstatus?: string;
    eventpurpose?: string;
    name: string;
    placement: string;
    position: number;
    targeturl?: string;
    eventType: EventTypes;
    className?: string;
}

export const ImpressionTracking: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, eventType, className, ...trackingProps } = props;

    const trackingRef = useRef<HTMLDivElement>(null);
    const intersection = useIntersection(trackingRef, {
        root: null,
        rootMargin: "0px",
        threshold: 1,
    });
    useEffect(() => {
        if (intersection?.isIntersecting) {
            analyticstracker().trackImpression(eventType, {
                info: { targeturl: trackingProps.targeturl },
            });
        }
    }, [intersection?.isIntersecting]);

    return (
        <div
            role="none"
            data-tracking-event={eventType}
            data-tracking-info={JSON.stringify(trackingProps)}
            style={{ height: "100%", width: "100%" }}
            ref={trackingRef}
            className={className}
        >
            {children}
        </div>
    );
};

export default ImpressionTracking;
