import createApiModule from "../create-api-module";
/**
 * Obtain the event by the id
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/get-event
 */
const { reducer, actions } = createApiModule("event", ({ id }) => `/events/${id}`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Event {
    id: string;
    eventNumber: string;
    publicationEndDate: string;
    status: "Eligible";
    medical: boolean;
    attended: boolean;
    registered: boolean;
    meetingStyle: string;
}
