import createApiModule from "../create-api-module";
/**
 * Obtain the podcasts defined in Castos
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/get-podcasts
 */
const { reducer, actions } = createApiModule("podcasts", () => `/podcasts`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Podcast {
    id: string;
    title: string;
    image: string;
    private: boolean;
}
