import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import React, { useEffect, useRef } from "react";
import { useMegaMenuContext } from "../MegaMenuContext";

import style from "./style.module.scss";
import { CLOSE_DELAY, OPEN_DELAY } from "./SubMenu";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

interface InterestingLinksProps {
    children: JSX.Element | JSX.Element[];
    title: string;
}

export const InterestingLinks = (props: InterestingLinksProps): JSX.Element => {
    const { children, title } = props;
    const { secondLevelHeight, thirdLevelHeight, interestingLinksHeight, setInterestingLinksHeight } =
        useMegaMenuContext();
    const elementRef = useRef(null);
    const isVisible = useOnScreen(elementRef);
    const [height, setHeight] = React.useState(0);
    const [minHeight, setMinHeight] = React.useState("unset");
    const isMobile = useMediaQuery(BreakPoint.md);
    const { jnjFullBranded } = useJnjBranding();

    let linkList = [] as JSX.Element[];
    if (Array.isArray(children)) {
        linkList = children;
    } else if (children) {
        linkList = [children];
    }

    useEffect(() => {
        // Make sure that the set to visible effects always happens last and after the submenus
        const timeout = isVisible ? OPEN_DELAY * 2 : CLOSE_DELAY * 2;

        setTimeout(() => {
            const elementHeight = elementRef?.current?.offsetHeight || 0;
            if (isVisible) {
                setInterestingLinksHeight(elementHeight);
            } else {
                if (height !== 0) setHeight(0);
                if (interestingLinksHeight !== 0) setInterestingLinksHeight(0);
            }
        }, timeout);
    }, [isVisible]);

    useEffect(() => {
        const computedHeight = Math.max(secondLevelHeight, thirdLevelHeight, interestingLinksHeight);
        if (isVisible && computedHeight !== height) setHeight(computedHeight);
    }, [secondLevelHeight, thirdLevelHeight, interestingLinksHeight]);

    useEffect(() => {
        setMinHeight(height > 0 ? `${height}px` : "unset");
    }, [height]);

    return linkList?.length !== 0 ? (
        <div className={style.interestingLinks} ref={elementRef} style={isMobile ? null : { minHeight: minHeight }}>
            <div className={style.content}>
                <Typography size="s" color="primary" font={jnjFullBranded ? "title" : "text"}>
                    {title}
                </Typography>
                <ul role="menu">
                    {linkList?.map((link, index) => (
                        <li key={`Interesting.Link.${link?.props?.id | index}`}>{link}</li>
                    ))}
                </ul>
            </div>
        </div>
    ) : null;
};
