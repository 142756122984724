import createApiModule from "../create-api-module";

/**
 * Registers the given user with the information supplied as part of the body.
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/get-events-eventNumber-eligible
 */
const { reducer, actions } = createApiModule(
    "eligibleEventNumber",
    ({ eventNumber }) => `/events/${eventNumber}/eligible`,
);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface EligibleEventNumber {
    isEligible: boolean;
}
