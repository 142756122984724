import createApiModule from "../create-api-module";

/**
 * Registers the given user with the information supplied as part of the body.
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/post-events-eventId-registration
 */
const { reducer, actions } = createApiModule(
    "eventPreAllowRegistration",
    ({ eventNumber }) => `/events/${eventNumber}/eligible`,
    {
        method: "POST",
    },
);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface EventPreAllowRegistration {
    success: string;
}
