import React, { ReactNode } from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import { ModalConsumer } from "./Modal";

export interface PropTypes {
    children: ReactNode | ReactNode[];
    align?: "start" | "center" | "end";
}

export const Actions = ({ align = "start", children }: PropTypes): JSX.Element => (
    <ModalConsumer>
        {() => (
            <div className={classnames(style.modal_actions, style[`align_${align}`])} data-test-id="Modal.Actions">
                {children}
            </div>
        )}
    </ModalConsumer>
);
