import createApiModule from "../create-api-module";

/**
 * Search for medical publications
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/get-publications
 */
const { reducer, actions } = createApiModule("pubMeds", ({ searchTerm }) => `/publications?searchTerm=${searchTerm}`);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface PubMeds {
    counters: { total: number; start: number; max: number };
    results: PubMed[];
}

export interface PubMed {
    abstract: string;
    authors: PubMedAuthor[];
    links: PubMedLink[];
    pmId: string;
    title: string;
    type: string;
}

export interface PubMedAuthor {
    affiliation: string;
    foreName: string;
    initials: string;
    lastName: string;
}

export interface PubMedLink {
    isFree: boolean;
    isFullText: boolean;
    url: string;
}
