import { CourseCompletionStatus } from "@types";

import createApiModule from "../create-api-module";
/**
 * Obtain the courses available on moodle
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/get-lms-courses-courseId
 */
const { reducer, actions } = createApiModule("course", ({ courseId }) => `/lms/courses/${courseId}`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Course {
    id: number;
    shortname: string;
    fullname: string;
    summary: string;
    tags: { course: { Site?: string; certificationpoints?: number; country?: string; duration?: number } };
    completionStatus: CourseCompletionStatus;
    duration: number;
    progress: number;
    chapters: CourseChapter[];
    available: number;
    startdate: string;
    enddate: string;
    timecreated: string;
    timestamp?: string;
    coursePdfs?: { name: string; url: string }[];
    display_on_detail_page: boolean;
}

export interface CourseChapter {
    id: number;
    fullname: string;
    completionStatus: CourseCompletionStatus;
    lessons: CourseLesson[];
    role: string;
    available: number;
    timecreated: string;
    timestamp?: string;
}

export interface CourseLesson {
    id: string;
    name: string;
    scormSummary: string;
    scormUrl: string;
    scormTumbnailUrl: string;
    certificateUrl: string;
    completionStatus: CourseCompletionStatus;
    role: string;
    available: number;
    sortnum: number;
    timestamp?: string;
    duration?: number;
}
