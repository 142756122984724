import { CourseCompletionStatus } from "@types";

import createApiModule from "../create-api-module";
/**
 * Obtain the courses from moodle that the user is enrolled in
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/get-lms-currentCourse
 */
const { reducer, actions } = createApiModule("currentCourses", () => `/lms/current-courses`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface CurrentCourse {
    id: number;
    completionStatus: string;
    progress: number;
    role: string;
    timestamp: string;
    available: number;
    chapters: CurrentCourseChapter[];
}

export interface CurrentCourseChapter {
    id: number;
    completionStatus: CourseCompletionStatus;
    role: string;
    timestamp?: string;
    available: number;
    lessons: CurrentCourseLesson[];
}

export interface CurrentCourseLesson {
    id: number;
    completionStatus: CourseCompletionStatus;
    role: string;
    timestamp?: string;
    available: number;
}
