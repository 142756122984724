import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { ImagePosition } from "@jmc/solid-design-system/src/components/molecules/Card/Card";
import { CMSButtonProps } from "@types";

export interface CMSCarouselProps {
    interval: { automatic: boolean; speed: number };
    size: CarouselSize;
    full_width: boolean;
    internal: {
        type: string;
    };
    slides: CMSCarouselSlide[];
}

export interface CMSCarouselSlide {
    title: string;
    text: string;
    image: ImageQueryResponse;
    buttons: CMSButtonProps[];
    display_options: {
        view_mode?: CarouselViewMode;
        title_color?: CarouselTextColor;
        text_color?: CarouselTextColor;
        background_color?: CarouselBackgroundColor;
        align_content?: CarouselAlignContent;
        align_content_vertically?: CarouselAlignContentVertically;
        overlay: "none" | "dark" | "light";
        image_focus: { image_focus: ImagePosition };
    };
}

export enum CarouselSize {
    SMALL = "Small",
    MEDIUM = "Medium",
    LARGE = "Large",
}

export enum CarouselViewMode {
    BACKGROUND = "Background",
    TEXT_IMAGE = "Text-Image",
    IMAGE_TEXT = "Image-Text",
}

export enum CarouselTextColor {
    DARK = "Dark",
    WHITE = "White",
    PRIMARY = "Primary",
    SECONDARY = "Secondary",
    ACCENT1 = "Accent 1",
    ACCENT2 = "Accent 2",
}

export enum CarouselBackgroundColor {
    WHITE = "White",
    DARK = "Dark",
    PRIMARY = "Primary",
    SECONDARY = "Secondary",
    ACCENT1 = "Accent 1",
    ACCENT2 = "Accent 2",
}

export enum CarouselAlignContent {
    START = "Start",
    CENTER = "Center",
    END = "End",
}

export enum CarouselAlignContentVertically {
    TOP = "Top",
    CENTER = "Center",
    BOTTOM = "Bottom",
}
