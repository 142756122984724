export enum AuthProviders {
    hcp = "JANRAIN_HCP",
    okta_hcp = "OKTA_HCP",
    tcp = "JANRAIN_TCP",
    okta_tcp = "OKTA_TCP",
    marketing = "PROVIDED_MARKETING",
    anonymous = "PROVIDED_ANONYMOUS",
    ping = "PING",
    pingTcp = "PING_TCP",
}
