const Footer = React.lazy(() => import("@components/Footer/Footer"));
const IssueCollector = React.lazy(() => import("@components/IssueCollector/IssueCollector"));
import { JMCLinkProvider } from "@components/JMCLink/JMCLinkProvider/JMCLinkProvider";
const LoginComponent = React.lazy(() =>
    import("@components/LoginToView/LoginComponent/LoginComponent").then((module) => ({
        default: module.LoginComponent,
    })),
);
import { LogoutWhenIdle } from "@components/LogoutWhenIdle/LogoutWhenIdle";
import { MarketingToken } from "@components/MarketingToken/MarketingToken";
import { NavigationImpression } from "@components/NavigationImpression/NavigationImpression";
import Now4Real from "@components/Now4Real/Now4Real";
import { PageImpression } from "@components/PageImpression/PageImpression";
const ShowErrorMessage = React.lazy(() => import("@components/ShowErrorMessage/ShowErrorMessage"));
const ShowActionMessage = React.lazy(() => import("@components/ShowActionMessage/ShowActionMessage"));
const ShowPrivacyPolicyUpdate = React.lazy(
    () => import("@components/ShowPrivacyPolicyNotification/ShowPrivacyPolicyNotification"),
);
const BackToTop = React.lazy(() => import("@jmc/core/src/components/BackToTop/BackToTop"));
import { JMCHeader } from "@components/JMCHeader/JMCHeader";
import { JMCJNJBar } from "@components/JMCJNJBar/JMCJNJBar";
import { ErrorBoundary } from "@jmc/core/src/components/ErrorBoundary/index";
import { FontWrapper } from "@jmc/core/src/components/FontWrapper/index";
import { Medallia } from "@jmc/core/src/components/Medallia/index";
import { ScrollImpression } from "@jmc/core/src/components/ScrollImpression/index";
import { WebVitalsTracking } from "@jmc/core/src/components/Tracking";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { Spinner } from "@jmc/solid-design-system/src/components/atoms/Spinner/Spinner";
import { ToolTipContext } from "@jmc/solid-design-system/src/components/atoms/Typography/ToolTipContext/ToolTipContext";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { ClientOnly } from "@jmc/utils/utils/client-only";
import { ApplicationState } from "@redux/modules";
import { ValidAuthResults } from "@redux/modules/authValid";
import { AuthProviders } from "@types/Auth";
import { CMSPage } from "@types/CMSPage";
import { IMR } from "@types/IMR";
import { NumericAccessLevel } from "@types/NumericAccessLevel";
import { PageContext } from "@types/PageContext";
import { isAuthorizedForProductColors } from "@utils/authorizer";
import classnames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import isEqual from "lodash/isEqual";
import React, { Suspense, useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-use";
import yn from "yn";

import { SEARCH_URL } from "../../../plugins/JMCPageCreator/Constants";
import { iMRContext } from "./imrContext/imrContext";
import style from "./style.module.scss";

const getIMRFromPage = (page: CMSPage): IMR | Record<string, never> => {
    if (
        page?.maintenance_mode?.temporary_maintenance_mode ||
        page?.site_reference?.[0]?.maintenance_mode?.temporary_maintenance_mode
    ) {
        return {};
    }
    if (page?.imedical_review?.imr && page?.imedical_review.imr?.[0]) {
        return page.imedical_review.imr[0];
    } else if (
        page?.site_reference &&
        page?.site_reference?.[0] &&
        page?.site_reference[0].imedical_review?.imr &&
        page?.site_reference[0]?.imedical_review?.imr[0]
    ) {
        return page?.site_reference[0].imedical_review.imr[0];
    }
    return {};
};

export interface MyPropTypes {
    children: JSX.Element | JSX.Element[];
    pageContext: PageContext;
    commercialContent: boolean;
    hasUnsupportedBrowser?: boolean;
}

interface ProfileType {
    loading: boolean;
    data: { codsId: string; country: string };
}

declare global {
    interface Window {
        recap: boolean;
    }
}

/**
 * The layout component acts as a wrapper around the content and navigation elements which are rendered
 * for "every" page.
 */
export const MyLayout = ({
    children,
    pageContext,
    commercialContent = true,
    hasUnsupportedBrowser = false,
}: MyPropTypes): JSX.Element => {
    const authData = useSelector(
        (state: ApplicationState) => state.authValid.data as unknown as ValidAuthResults,
        isEqual,
    );
    const profile = useSelector(({ profile }: { profile: ProfileType }) => profile, isEqual);
    const [showLogin, setShowLogin] = useState(false);
    const [withJNJBar, setWithJNJBar] = useState(true);
    const [commercial, setCommercial] = useState(commercialContent);
    const [shouldAddProductTheme, setShouldAddProductTheme] = useState(false);
    const [authenticated, setAuthenticated] = useState(
        !!authData?.results.find((r: { provider: AuthProviders }) =>
            [AuthProviders.hcp, AuthProviders.ping, AuthProviders.okta_hcp].includes(r.provider),
        ),
    );
    const { page } = pageContext;

    const { t } = useTranslation();
    const location = useLocation();
    const { jnjFullBranded } = useJnjBranding();

    const imr = getIMRFromPage(page);

    const keyHandler = (e: KeyboardEvent): void => {
        const n = 30;
        let top = document.documentElement.scrollTop || window.pageYOffset;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        switch (e.key) {
            case "ArrowUp":
                window.scroll({ top: (top -= n), behavior: "smooth" });
                break;
            case "ArrowDown":
                window.scroll({ top: (top += n), behavior: "smooth" });
                break;
            case "Home":
                window.scroll({ top: 0, behavior: "smooth" });
                break;
            case "End":
                window.scroll({ top: height, behavior: "smooth" });
                break;
        }
    };

    useEffect(() => {
        setAuthenticated(
            !!authData?.results.find((r) =>
                [AuthProviders.hpc, AuthProviders.okta_hcp, AuthProviders.ping].includes(r.provider),
            ),
        );
    }, [authData]);

    useEffect(() => {
        window.addEventListener("keydown", keyHandler);

        return () => {
            window.removeEventListener("keydown", keyHandler);
        };
    });

    useEffect(() => {
        setCommercial(commercialContent);
    }, [commercialContent]);

    useEffect(() => {
        //setting and exposing variable globally for recaptcha on stg
        if (process.env.GATSBY_ENVIRONMENT === "qa") {
            window.recap = false;
        }
    }, []);

    const isProductPage = (): boolean => {
        const relatedTo = pageContext?.page?.site_reference?.[0]?.relation?.[0];
        return relatedTo?.__typename === "Contentstack_product";
    };

    const listenerFunction = (): void => {
        const { page, gatedUrl, url } = pageContext;
        setShouldAddProductTheme(
            isAuthorizedForProductColors(
                authData,
                page?.access_control?.access_level,
                isProductPage(),
                gatedUrl ?? url,
            ),
        );
    };

    useEffect(() => {
        const { page, gatedUrl, url } = pageContext;
        const direct =
            typeof window !== "undefined"
                ? window?.previousPath === "" ||
                  (!window?.previousPath?.includes(process.env.GATSBY_APP_DOMAIN) &&
                      !window?.previousPath?.includes("webaccess-janssen.com") &&
                      !window?.previousPath?.includes("pre-allow"))
                : true;
        if (direct && !pageContext.commercial_content) {
            document.addEventListener("disclaimerAccepted", listenerFunction);
        } else {
            setShouldAddProductTheme(
                isAuthorizedForProductColors(
                    authData,
                    page?.access_control?.access_level,
                    isProductPage(),
                    gatedUrl ?? url,
                ),
            );
        }
        return (): void => {
            if (direct) {
                document.removeEventListener("disclaimerAccepted", listenerFunction);
            }
        };
    }, [authData, pageContext]);

    const { level } =
        authData?.results?.find((v: { provider: AuthProviders }) =>
            [
                AuthProviders.hpc,
                AuthProviders.okta_hcp,
                AuthProviders.ping,
                AuthProviders.marketing,
                AuthProviders.anonymous,
            ].includes(v.provider),
        ) || {};
    const hasMarketingToken = authData?.results?.some((v) => v.provider === AuthProviders.marketing);

    //Passing BackToTopWrapper as a prop to BackToTop component to avoid sticky issues while wrapping BackToTop with navigation impression
    const BackToTopWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
        return <NavigationImpression name="btt_navigation">{children}</NavigationImpression>;
    };

    return (
        <ErrorBoundary>
            <FontWrapper locale={pageContext.locale}>
                <Medallia pathname={location?.pathname} profile={profile} />
                <JMCLinkProvider commercialContent={commercial}>
                    <Div100vh>
                        <ToolTipContext.Provider
                            value={t("This product is under extra pharmaco-vigilance monitoring", { ns: "common" })}
                        >
                            <ClientOnly>
                                <div
                                    key="authStatus"
                                    style={{ display: "none" }}
                                    data-test-id="authStatus"
                                    data-test-value={authenticated}
                                />
                            </ClientOnly>

                            <div
                                className={classnames(style.container, withJNJBar ? style.withJNJBar : null)}
                                id="content-container"
                            >
                                <Suspense fallback={<div id={`suspense-div-jmc-header`} />}>
                                    <JMCHeader
                                        pageContext={pageContext}
                                        shouldAddProductTheme={shouldAddProductTheme}
                                        medical={!commercial}
                                    />
                                    <JMCJNJBar withJNJBar={withJNJBar} setWithJNJBar={setWithJNJBar} />
                                </Suspense>

                                {!jnjFullBranded && (
                                    <Suspense fallback={<div id={`suspense-div-privacy-policy`} />}>
                                        <ShowPrivacyPolicyUpdate currentPage={pageContext?.page?.title} />
                                    </Suspense>
                                )}

                                <ScrollImpression>
                                    <div
                                        className={classnames(
                                            style.flexContainer,
                                            pageContext.url === `${SEARCH_URL}/` && style.flexContainerSearch,
                                        )}
                                    >
                                        <div
                                            id="body"
                                            className={style.body}
                                            data-theme={shouldAddProductTheme && pageContext.emea_product}
                                            key="Shell.Body"
                                            data-test-id="Shell.Body"
                                        >
                                            <MarketingToken />
                                            <PageImpression key={"shell.content"} context={pageContext}>
                                                <iMRContext.Provider
                                                    value={{ imr: imr, marginXL: pageContext?.marginXl }}
                                                >
                                                    {children}
                                                </iMRContext.Provider>

                                                <Suspense fallback={<div id={`suspense-div-messages`} />}>
                                                    <ShowErrorMessage onSignIn={() => setShowLogin(true)} />
                                                    <ShowActionMessage
                                                        onSignIn={() => setShowLogin(true)}
                                                        profile={profile}
                                                    />
                                                </Suspense>
                                                <Suspense fallback={<div id={`suspense-div-back-to-top`} />}>
                                                    <BackToTop BackToTopWrapper={BackToTopWrapper} />
                                                </Suspense>
                                            </PageImpression>
                                        </div>
                                        {!hasUnsupportedBrowser && (
                                            <div
                                                className={classnames(style.footer)}
                                                key="Shell.Footer"
                                                data-test-id="Shell.Footer"
                                                data-nosnippet
                                            >
                                                <NavigationImpression
                                                    name="footer_navigation"
                                                    type={EventTypes.FOOTER_IMPRESSION}
                                                    checkVisibility={false}
                                                >
                                                    <Suspense fallback={<div id={`suspense-div-footer`} />}>
                                                        <Footer
                                                            updatedAt={page?.updated_at}
                                                            context={pageContext}
                                                            commercialContent={commercialContent}
                                                        />
                                                    </Suspense>
                                                </NavigationImpression>
                                            </div>
                                        )}
                                    </div>
                                </ScrollImpression>
                            </div>
                        </ToolTipContext.Provider>
                    </Div100vh>

                    <LogoutWhenIdle
                        timeout={Math.min(parseInt(process.env.GATSBY_LOGOUT_TIMEOUT), 2 * 60 * 60)}
                        providers={[
                            AuthProviders.marketing,
                            AuthProviders.hcp,
                            AuthProviders.okta_hcp,
                            AuthProviders.ping,
                            AuthProviders.anonymous,
                        ]}
                        platform="Jmc"
                    />

                    {/* Separate logout timer for TCP which should log out earlier than the other auth providers. Maximum of 15 minutes. */}
                    <LogoutWhenIdle
                        timeout={Math.min(parseInt(process.env.GATSBY_TCP_LOGOUT_TIMEOUT) || 15 * 60, 15 * 60)}
                        providers={[AuthProviders.tcp]}
                    />

                    {yn(process.env.GATSBY_ISSUE_COLLECTOR_ENABLED) && (
                        <Suspense fallback={<div id={`suspense-div-issue-collector`} />}>
                            <IssueCollector />
                        </Suspense>
                    )}
                    {showLogin && (
                        <Suspense fallback={<div id={`suspense-div-login-layout`} />}>
                            <LoginComponent value="Login" onCancel={() => setShowLogin(false)} />
                        </Suspense>
                    )}
                </JMCLinkProvider>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <div id="loadingOverlay" popover="auto" className={style.popover}>
                    <Spinner size="xl" />
                </div>
            </FontWrapper>
            <WebVitalsTracking />
            {yn(process.env.GATSBY_NOW4REAL_ENABLED) && (level === NumericAccessLevel.level3 || hasMarketingToken) && (
                <Now4Real />
            )}
        </ErrorBoundary>
    );
};

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    pageContext: PageContext;
}
export const LayoutForExport = ({ children, pageContext, ...props }: PropTypes): JSX.Element => {
    const dynamicPageContext = useSelector((state: ApplicationState) => state.pageContext as PageContext, isEqual);
    //pagecontext is the initial context fetched via graphql, dynamic page context is the context obtained via redux. We therefore merge both context
    const mergedPageContext = {
        ...pageContext,
        ...dynamicPageContext,
    };
    const [commercialContent, setCommercialContent] = useState(mergedPageContext.commercial_content);

    useEffect(() => {
        setCommercialContent(mergedPageContext.commercial_content);
    }, [mergedPageContext]);

    const services = useStaticQuery(
        graphql`
            query {
                eventCenter: contentstackEventCenter {
                    enable_service
                }
                specialties: contentstackSpecialties {
                    enable_service
                }
                productGlossary: contentstackProductGlossary {
                    enable_service
                }
                tcp: contentstackTcp {
                    hidden_service_card
                    enable_service
                }
            }
        `,
    );

    return (
        //the merged context is passed as a prop to the mylayout component.
        <MyLayout {...props} commercialContent={commercialContent} services={services} pageContext={mergedPageContext}>
            {children}
        </MyLayout>
    );
};

export const Layout = LayoutForExport;
export default Layout;
