import { MaterialTargetAudience, MaterialType } from "@types";
import { TFunction } from "i18next";

const getMaterialTypeString = (type: MaterialType, t: TFunction): string => {
    const TYPE_MAP = new Map<string, string>([
        [MaterialType.PRESCRIBING_INFORMATION, t("Prescribing information", { ns: "materials" })],
        [MaterialType.SAFETY_INFORMATION, t("Safety information", { ns: "materials" })],
        [MaterialType.LEAFLET_BOOKLET, t("Leaflet / booklet", { ns: "materials" })],
        [MaterialType.SLIDEDECK_PRESENTATION, t("Slidedeck / presentation", { ns: "materials" })],
        [MaterialType.GUIDE_BOOK, t("Guide / book", { ns: "materials" })],
        [MaterialType.POSTER, t("Poster", { ns: "materials" })],
        [MaterialType.OTHER, t("Other", { ns: "materials" })],
    ]);

    return TYPE_MAP.get(type);
};

const getMaterialTargetAudienceString = (targetAudience: MaterialTargetAudience, t: TFunction): string => {
    const TARGET_AUDIENCE_MAP = new Map<string, string>([
        [MaterialTargetAudience.HCP, t("HCP", { ns: "materials" })],
        [MaterialTargetAudience.Patient, t("Patient", { ns: "materials" })],
    ]);

    return TARGET_AUDIENCE_MAP.get(targetAudience);
};

export default {
    getMaterialTypeString,
    getMaterialTargetAudienceString,
};
