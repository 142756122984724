/*
 * Handles both Enter and Spacebar keys for accessibility
 */
export const keyHandler = (key: string, handler: () => void): void => {
    if (key === "Enter" || key === " " || key === "Spacebar") {
        handler();
    }
};

export default keyHandler;
