/**
 * Get a key from an object without case sensitivity
 *
 * @param key the key that you want to get from the target object
 * @param target the target object
 */
export const getCaseInsensitiveKey = (key: string, target: Record<string, any>) => {
    if (!target) {
        return undefined;
    }
    const keys = Object.keys(target);
    for (const k of keys) {
        if (k.toLowerCase() === key.toLowerCase()) {
            const result = target[k];
            delete target[k];
            return result;
        }
    }
    return undefined;
};
