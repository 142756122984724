import { AutocompleteState, BaseItem, createAutocomplete } from "@algolia/autocomplete-core";
import { SearchClient } from "algoliasearch/lite";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { createVoiceSearchPlugin } from "./VoiceSearch/voiceSearchPlugin";

export const buildAutocomplete = (
    setAutocompleteState: (state: AutocompleteState<BaseItem>) => void,
    plugins: Record<string, unknown>[],
    initialState?: unknown,
) => {
    return createAutocomplete({
        initialState,
        onStateChange({ state }) {
            setAutocompleteState(state);
        },
        plugins,
        reshape({ sources, sourcesBySourceId, state }) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { recentSearchesPlugin, ...rest } = sourcesBySourceId;
            // If the query is empty we are including recent searches and query suggestions plugin, But if there is a query we are ignoring recent searches plugin to make sure the keyboard navigation works fine
            if (state.query) {
                return [Object.values(rest)];
            } else {
                return sources;
            }
        },
        detachedMediaQuery: "none",
        openOnFocus: true,
    });
};

export const buildPlugins = (
    searchClient: SearchClient,
    indexName: string,
    onSelectCallback: (value: string) => void,
    voiceSearchButton?: (children: JSX.Element) => void,
    locale?: string,
    enablePreviousSearches = true,
    enablePopularSearches = true,
    enableAutocomplete = true,
) => {
    const plugins = [];

    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
        key: "search",
        limit: 5,
        transformSource({ source }) {
            return {
                ...source,
                onSelect(params) {
                    onSelectCallback(params?.item?.label);
                },
            };
        },
    });

    if (enablePreviousSearches) {
        plugins.push(recentSearchesPlugin);
    }

    if (enablePopularSearches) {
        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName,
            getSearchParams({ state }) {
                return {
                    hitsPerPage: 10,
                    query: state?.query,
                    highlightPreTag: "<mark>",
                    highlightPostTag: "</mark>",
                };
            },
            transformSource({ source, state }) {
                if (state.query && !enableAutocomplete) {
                    return {
                        ...source,
                        getItems() {
                            return [];
                        },
                    };
                }
                return {
                    ...source,
                    onSelect(params) {
                        onSelectCallback(params?.item?.query);
                    },
                };
            },
        });
        plugins.push(querySuggestionsPlugin);
    }

    if (voiceSearchButton) {
        const voiceSearchPlugin = createVoiceSearchPlugin(locale, voiceSearchButton);
        plugins.push(voiceSearchPlugin);
    }

    return plugins;
};
