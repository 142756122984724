// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

//as of 04/01/2022 there is no hook equivalent to errorboundaries, this component should therefore stay as a class component
class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // eslint-disable-next-line no-console
        console.error("Uncaught error:", error, errorInfo);
    }

    public render(): ReactNode {
        // if (process.env.GATSBY_ENVIRONMENT === "prod" && this.state.hasError) {
        //     return <h1>Sorry.. there was an error</h1>;
        // }

        return this.props.children;
    }
}

export default ErrorBoundary;
