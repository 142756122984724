import createApiModule from "../create-api-module";
/**
 * Obtain the courses available on moodle
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v3/index.html#operation/get-lms-certificates
 */
const { reducer, actions } = createApiModule("certificates", () => `/lms/certificates`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface Certificate {
    id: string;
    courseId: number;
    title: string;
    url: string;
    timestamp: string;
}
