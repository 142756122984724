import { globalHistory } from "@reach/router";

const cachedRegex = /^(https?:)?(?:\/\/)?(([^:/?#]+)(?::(\d+))?)([/]?[^?#]*)(\?[^#]*|)(#.*|)$/;

const stripLeading = (path: string): string => (path && path.startsWith("/") ? path.slice(1) : path);
const stripTrailing = (path: string): string => (path && path.endsWith("/") ? path.slice(0, -1) : path);
const strip = (path: string): string => stripLeading(stripTrailing(path));

/**
 * Returns a parsed result of the given URL
 *
 * @param url the url to parse
 */
const parseUrl = (url: string) => {
    const match = url?.match(cachedRegex);
    return (
        match && {
            href: url,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7],
        }
    );
};

const getParamStr = (params?: { [key: string]: string | string[] } | string | string[][]): string => {
    if (!params || Object.keys(params).length === 0 || params === "?=") {
        return "";
    }
    return `?${new URLSearchParams(
        params as string | string[][] | Record<string, string> | URLSearchParams,
    ).toString()}`;
};

const isUrlAbsolute = (url: string): boolean => {
    const ABSOLUTE_URL_REGEX = new RegExp("^(?:[a-z]+:)?//|mailto:", "i");

    return ABSOLUTE_URL_REGEX.test(url);
};

const getUrl = (
    url: string,
    locale: string,
    external: boolean,
    params?: { [key: string]: string | string[] } | string | string[][],
): string => {
    if (url && url.startsWith("#") && url.length > 1) {
        return globalHistory?.location?.pathname;
    }
    if (url === "#") {
        return url;
    }
    let prefixedUrl = url ? `${strip(url)}${getParamStr(params)}` : "";
    if (url && !isUrlAbsolute(url) && !url?.includes("api/page") && !external) {
        if (locale) {
            const allLocales = process.env.GATSBY_LOCALES?.split(",");

            const langCode = prefixedUrl?.split("/")?.[0];
            // If locale is already present in relative URL then priority is given to that locale
            const hasLangCode = allLocales?.find((item) => item === langCode);
            if (!hasLangCode) {
                prefixedUrl = `${strip(locale)}/${strip(url)}${getParamStr(params)}`;
            }
        }
    }

    /**
     * When an url was originally an absolute url then make it so again
     * Still avoid using relative urls, as this will be hard to do on dev
     * environments as the locale is always added and is just bug prone by default.
     */
    return url?.startsWith("/") ? `/${prefixedUrl}` : prefixedUrl;
};

const getFullUrl = (
    url: string,
    locale: string,
    external: boolean,
    params?: { [key: string]: string | string[] },
): string => {
    const domain = process.env.GATSBY_APP_DOMAIN.endsWith("/")
        ? process.env.GATSBY_APP_DOMAIN
        : `${process.env.GATSBY_APP_DOMAIN}/`;

    return `${domain}${getUrl(strip(url), locale, external, params)}`;
};

const getApiBaseUrl = (): string => {
    return `${strip(process.env.GATSBY_API_ORIGIN_URL ?? process.env.GATSBY_API_BASE_URL)}/${strip(
        process.env.GATSBY_API_VERSION,
    )}/`;
};

const getSearchParam = (param: string): string => {
    const searchParams = new URLSearchParams(globalHistory?.location?.search);
    return searchParams.get(param);
};

const getNonLocalizedPathname = (pathname: string): string => {
    if (!pathname) return "";
    const i = pathname.indexOf(`/`, 1);
    return i < 0 ? "" : pathname.substring(i);
};

export default {
    getParamStr,
    isUrlAbsolute,
    getUrl,
    getFullUrl,
    parseUrl,
    getApiBaseUrl,
    getSearchParam,
    getNonLocalizedPathname,
};
