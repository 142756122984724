import createValueModule from "../create-value-module";

export interface SalesRepModal {
    showModal: boolean;
}

const { reducer, actions } = createValueModule("salesRepModal", {
    showModal: false,
});
export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
