import { Language } from "./languages";

const languages: { [key: string]: Language } = {
    aa: {
        name: "Afar",
        dir: 1,
        native: "Afar",
    },
    ab: {
        name: "Abkhazian",
        dir: 1,
        native: "Аҧсуа",
    },
    af: {
        name: "Afrikaans",
        dir: 1,
        native: "Afrikaans",
    },
    ak: {
        name: "Akan",
        dir: 1,
        native: "Akana",
    },
    am: {
        name: "Amharic",
        dir: 1,
        native: "አማርኛ",
    },
    an: {
        name: "Aragonese",
        dir: 1,
        native: "Aragonés",
    },
    ar: {
        name: "Arabic",
        dir: 0,
        native: "العربية",
    },
    as: {
        name: "Assamese",
        dir: 1,
        native: "অসমীয়া",
    },
    av: {
        name: "Avar",
        dir: 1,
        native: "Авар",
    },
    ay: {
        name: "Aymara",
        dir: 1,
        native: "Aymar",
    },
    az: {
        name: "Azerbaijani",
        dir: 1,
        native: "Azərbaycanca / آذربايجان",
    },
    ba: {
        name: "Bashkir",
        dir: 1,
        native: "Башҡорт",
    },
    be: {
        name: "Belarusian",
        dir: 1,
        native: "Беларуская",
    },
    bg: {
        name: "Bulgarian",
        dir: 1,
        native: "Български",
    },
    bh: {
        name: "Bihari",
        dir: 1,
        native: "भोजपुरी",
    },
    bi: {
        name: "Bislama",
        dir: 1,
        native: "Bislama",
    },
    bm: {
        name: "Bambara",
        dir: 1,
        native: "Bamanankan",
    },
    bn: {
        name: "Bengali",
        dir: 1,
        native: "বাংলা",
    },
    bo: {
        name: "Tibetan",
        dir: 1,
        native: "བོད་ཡིག / Bod skad",
    },
    br: {
        name: "Breton",
        dir: 1,
        native: "Brezhoneg",
    },
    bs: {
        name: "Bosnian",
        dir: 1,
        native: "Bosanski",
    },
    ca: {
        name: "Catalan",
        dir: 1,
        native: "Català",
    },
    ce: {
        name: "Chechen",
        dir: 1,
        native: "Нохчийн",
    },
    ch: {
        name: "Chamorro",
        dir: 1,
        native: "Chamoru",
    },
    co: {
        name: "Corsican",
        dir: 1,
        native: "Corsu",
    },
    cr: {
        name: "Cree",
        dir: 1,
        native: "Nehiyaw",
    },
    cs: {
        name: "Czech",
        dir: 1,
        native: "Česky",
    },
    cu: {
        name: "Old Church Slavonic / Old Bulgarian",
        dir: 1,
        native: "словѣньскъ / slověnĭskŭ",
    },
    cv: {
        name: "Chuvash",
        dir: 1,
        native: "Чăваш",
    },
    cy: {
        name: "Welsh",
        dir: 1,
        native: "Cymraeg",
    },
    da: {
        name: "Danish",
        dir: 1,
        native: "Dansk",
    },
    de: {
        name: "German",
        dir: 1,
        native: "Deutsch",
    },
    dv: {
        name: "Divehi",
        dir: 0,
        native: "ދިވެހިބަސް",
    },
    dz: {
        name: "Dzongkha",
        dir: 1,
        native: "ཇོང་ཁ",
    },
    ee: {
        name: "Ewe",
        dir: 1,
        native: "Ɛʋɛ",
    },
    el: {
        name: "Greek",
        dir: 1,
        native: "Ελληνικά",
    },
    en: {
        name: "English",
        dir: 1,
        native: "English",
    },
    eo: {
        name: "Esperanto",
        dir: 1,
        native: "Esperanto",
    },
    es: {
        name: "Spanish",
        dir: 1,
        native: "Español",
    },
    et: {
        name: "Estonian",
        dir: 1,
        native: "Eesti",
    },
    eu: {
        name: "Basque",
        dir: 1,
        native: "Euskara",
    },
    fa: {
        name: "Persian",
        dir: 0,
        native: "فارسی",
    },
    ff: {
        name: "Peul",
        dir: 1,
        native: "Fulfulde",
    },
    fi: {
        name: "Finnish",
        dir: 1,
        native: "Suomi",
    },
    fj: {
        name: "Fijian",
        dir: 1,
        native: "Na Vosa Vakaviti",
    },
    fo: {
        name: "Faroese",
        dir: 1,
        native: "Føroyskt",
    },
    fr: {
        name: "French",
        dir: 1,
        native: "Français",
    },
    fy: {
        name: "West Frisian",
        dir: 1,
        native: "Frysk",
    },
    ga: {
        name: "Irish",
        dir: 1,
        native: "Gaeilge",
    },
    gd: {
        name: "Scottish Gaelic",
        dir: 1,
        native: "Gàidhlig",
    },
    gl: {
        name: "Galician",
        dir: 1,
        native: "Galego",
    },
    gn: {
        name: "Guarani",
        dir: 1,
        native: "Avañe'ẽ",
    },
    gu: {
        name: "Gujarati",
        dir: 1,
        native: "ગુજરાતી",
    },
    gv: {
        name: "Manx",
        dir: 1,
        native: "Gaelg",
    },
    ha: {
        name: "Hausa",
        dir: 0,
        native: "هَوُسَ",
    },
    he: {
        name: "Hebrew",
        dir: 0,
        native: "עברית",
    },
    hi: {
        name: "Hindi",
        dir: 1,
        native: "हिन्दी",
    },
    ho: {
        name: "Hiri Motu",
        dir: 1,
        native: "Hiri Motu",
    },
    hr: {
        name: "Croatian",
        dir: 1,
        native: "Hrvatski",
    },
    ht: {
        name: "Haitian",
        dir: 1,
        native: "Krèyol ayisyen",
    },
    hu: {
        name: "Hungarian",
        dir: 1,
        native: "Magyar",
    },
    hy: {
        name: "Armenian",
        dir: 1,
        native: "Հայերեն",
    },
    hz: {
        name: "Herero",
        dir: 1,
        native: "Otsiherero",
    },
    ia: {
        name: "Interlingua",
        dir: 1,
        native: "Interlingua",
    },
    id: {
        name: "Indonesian",
        dir: 1,
        native: "Bahasa Indonesia",
    },
    ie: {
        name: "Interlingue",
        dir: 1,
        native: "Interlingue",
    },
    ig: {
        name: "Igbo",
        dir: 1,
        native: "Igbo",
    },
    ii: {
        name: "Sichuan Yi",
        dir: 1,
        native: "ꆇꉙ / 四川彝语",
    },
    ik: {
        name: "Inupiak",
        dir: 1,
        native: "Iñupiak",
    },
    io: {
        name: "Ido",
        dir: 1,
        native: "Ido",
    },
    is: {
        name: "Icelandic",
        dir: 1,
        native: "Íslenska",
    },
    it: {
        name: "Italian",
        dir: 1,
        native: "Italiano",
    },
    iu: {
        name: "Inuktitut",
        dir: 1,
        native: "ᐃᓄᒃᑎᑐᑦ",
    },
    ja: {
        name: "Japanese",
        dir: 1,
        native: "日本語",
    },
    jv: {
        name: "Javanese",
        dir: 1,
        native: "Basa Jawa",
    },
    ka: {
        name: "Georgian",
        dir: 1,
        native: "ქართული",
    },
    kg: {
        name: "Kongo",
        dir: 1,
        native: "KiKongo",
    },
    ki: {
        name: "Kikuyu",
        dir: 1,
        native: "Gĩkũyũ",
    },
    kj: {
        name: "Kuanyama",
        dir: 1,
        native: "Kuanyama",
    },
    kk: {
        name: "Kazakh",
        dir: 1,
        native: "Қазақша",
    },
    kz: {
        name: "Kazakh",
        dir: 1,
        native: "Қазақша",
    },
    kl: {
        name: "Greenlandic",
        dir: 1,
        native: "Kalaallisut",
    },
    km: {
        name: "Cambodian",
        dir: 1,
        native: "ភាសាខ្មែរ",
    },
    kn: {
        name: "Kannada",
        dir: 1,
        native: "ಕನ್ನಡ",
    },
    ko: {
        name: "Korean",
        dir: 1,
        native: "한국어",
    },
    kr: {
        name: "Kanuri",
        dir: 1,
        native: "Kanuri",
    },
    ks: {
        name: "Kashmiri",
        dir: 0,
        native: "कश्मीरी / كشميري",
    },
    ku: {
        name: "Kurdish",
        dir: 0,
        native: "Kurdî / كوردی",
    },
    kv: {
        name: "Komi",
        dir: 1,
        native: "Коми",
    },
    kw: {
        name: "Cornish",
        dir: 1,
        native: "Kernewek",
    },
    ky: {
        name: "Kirghiz",
        dir: 1,
        native: "Kırgızca / Кыргызча",
    },
    la: {
        name: "Latin",
        dir: 1,
        native: "Latina",
    },
    lb: {
        name: "Luxembourgish",
        dir: 1,
        native: "Lëtzebuergesch",
    },
    lg: {
        name: "Ganda",
        dir: 1,
        native: "Luganda",
    },
    li: {
        name: "Limburgian",
        dir: 1,
        native: "Limburgs",
    },
    ln: {
        name: "Lingala",
        dir: 1,
        native: "Lingála",
    },
    lo: {
        name: "Laotian",
        dir: 1,
        native: "ລາວ / Pha xa lao",
    },
    lt: {
        name: "Lithuanian",
        dir: 1,
        native: "Lietuvių",
    },
    lv: {
        name: "Latvian",
        dir: 1,
        native: "Latviešu",
    },
    mg: {
        name: "Malagasy",
        dir: 1,
        native: "Malagasy",
    },
    mh: {
        name: "Marshallese",
        dir: 1,
        native: "Kajin Majel / Ebon",
    },
    mi: {
        name: "Maori",
        dir: 1,
        native: "Māori",
    },
    mk: {
        name: "Macedonian",
        dir: 1,
        native: "Македонски",
    },
    ml: {
        name: "Malayalam",
        dir: 1,
        native: "മലയാളം",
    },
    mn: {
        name: "Mongolian",
        dir: 1,
        native: "Монгол",
    },
    mo: {
        name: "Moldovan",
        dir: 1,
        native: "Moldovenească",
    },
    mr: {
        name: "Marathi",
        dir: 1,
        native: "मराठी",
    },
    ms: {
        name: "Malay",
        dir: 1,
        native: "Bahasa Melayu",
    },
    mt: {
        name: "Maltese",
        dir: 1,
        native: "bil-Malti",
    },
    my: {
        name: "Burmese",
        dir: 1,
        native: "Myanmasa",
    },
    na: {
        name: "Nauruan",
        dir: 1,
        native: "Dorerin Naoero",
    },
    nd: {
        name: "North Ndebele",
        dir: 1,
        native: "Sindebele",
    },
    ne: {
        name: "Nepali",
        dir: 1,
        native: "नेपाली",
    },
    ng: {
        name: "Ndonga",
        dir: 1,
        native: "Oshiwambo",
    },
    nl: {
        name: "Dutch",
        dir: 1,
        native: "Nederlands",
    },
    nn: {
        name: "Norwegian Nynorsk",
        dir: 1,
        native: "Norsk (nynorsk)",
    },
    no: {
        name: "Norwegian",
        dir: 1,
        native: "Norsk (bokmål / riksmål)",
    },
    nr: {
        name: "South Ndebele",
        dir: 1,
        native: "isiNdebele",
    },
    nv: {
        name: "Navajo",
        dir: 1,
        native: "Diné bizaad",
    },
    ny: {
        name: "Chichewa",
        dir: 1,
        native: "Chi-Chewa",
    },
    oc: {
        name: "Occitan",
        dir: 1,
        native: "Occitan",
    },
    oj: {
        name: "Ojibwa",
        dir: 1,
        native: "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin",
    },
    om: {
        name: "Oromo",
        dir: 1,
        native: "Oromoo",
    },
    or: {
        name: "Oriya",
        dir: 1,
        native: "ଓଡ଼ିଆ",
    },
    os: {
        name: "Ossetian / Ossetic",
        dir: 1,
        native: "Иронау",
    },
    pa: {
        name: "Panjabi / Punjabi",
        dir: 1,
        native: "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
    },
    pi: {
        name: "Pali",
        dir: 1,
        native: "Pāli / पाऴि",
    },
    pl: {
        name: "Polish",
        dir: 1,
        native: "Polski",
    },
    ps: {
        name: "Pashto",
        dir: 0,
        native: "پښتو",
    },
    pt: {
        name: "Portuguese",
        dir: 1,
        native: "Português",
    },
    qu: {
        name: "Quechua",
        dir: 1,
        native: "Runa Simi",
    },
    rm: {
        name: "Raeto Romance",
        dir: 1,
        native: "Rumantsch",
    },
    rn: {
        name: "Kirundi",
        dir: 1,
        native: "Kirundi",
    },
    ro: {
        name: "Romanian",
        dir: 1,
        native: "Română",
    },
    ru: {
        name: "Russian",
        dir: 1,
        native: "Русский",
    },
    rw: {
        name: "Rwandi",
        dir: 1,
        native: "Kinyarwandi",
    },
    sa: {
        name: "Sanskrit",
        dir: 1,
        native: "संस्कृतम्",
    },
    sc: {
        name: "Sardinian",
        dir: 1,
        native: "Sardu",
    },
    sd: {
        name: "Sindhi",
        dir: 1,
        native: "सिनधि",
    },
    se: {
        name: "Northern Sami",
        dir: 1,
        native: "Davvisámegiella",
    },
    sg: {
        name: "Sango",
        dir: 1,
        native: "Sängö",
    },
    sh: {
        name: "Serbo-Croatian",
        dir: 1,
        native: "Srpskohrvatski / Српскохрватски",
    },
    si: {
        name: "Sinhalese",
        dir: 1,
        native: "සිංහල",
    },
    sk: {
        name: "Slovak",
        dir: 1,
        native: "Slovenčina",
    },
    sl: {
        name: "Slovenian",
        dir: 1,
        native: "Slovenščina",
    },
    sm: {
        name: "Samoan",
        dir: 1,
        native: "Gagana Samoa",
    },
    sn: {
        name: "Shona",
        dir: 1,
        native: "chiShona",
    },
    so: {
        name: "Somalia",
        dir: 1,
        native: "Soomaaliga",
    },
    sq: {
        name: "Albanian",
        dir: 1,
        native: "Shqip",
    },
    sr: {
        name: "Serbian",
        dir: 1,
        native: "Српски",
    },
    ss: {
        name: "Swati",
        dir: 1,
        native: "SiSwati",
    },
    st: {
        name: "Southern Sotho",
        dir: 1,
        native: "Sesotho",
    },
    su: {
        name: "Sundanese",
        dir: 1,
        native: "Basa Sunda",
    },
    sv: {
        name: "Swedish",
        dir: 1,
        native: "Svenska",
    },
    sw: {
        name: "Swahili",
        dir: 1,
        native: "Kiswahili",
    },
    ta: {
        name: "Tamil",
        dir: 1,
        native: "தமிழ்",
    },
    te: {
        name: "Telugu",
        dir: 1,
        native: "తెలుగు",
    },
    tg: {
        name: "Tajik",
        dir: 1,
        native: "Тоҷикӣ",
    },
    th: {
        name: "Thai",
        dir: 1,
        native: "ไทย / Phasa Thai",
    },
    ti: {
        name: "Tigrinya",
        dir: 1,
        native: "ትግርኛ",
    },
    tk: {
        name: "Turkmen",
        dir: 1,
        native: "Туркмен / تركمن",
    },
    tl: {
        name: "Tagalog",
        dir: 1,
        native: "Tagalog",
    },
    tn: {
        name: "Tswana",
        dir: 1,
        native: "Setswana",
    },
    to: {
        name: "Tonga",
        dir: 1,
        native: "Lea Faka-Tonga",
    },
    tr: {
        name: "Turkish",
        dir: 1,
        native: "Türkçe",
    },
    ts: {
        name: "Tsonga",
        dir: 1,
        native: "Xitsonga",
    },
    tt: {
        name: "Tatar",
        dir: 1,
        native: "Tatarça",
    },
    tw: {
        name: "Twi",
        dir: 1,
        native: "Twi",
    },
    ty: {
        name: "Tahitian",
        dir: 1,
        native: "Reo Mā`ohi",
    },
    ug: {
        name: "Uyghur",
        dir: 1,
        native: "Uyƣurqə / ئۇيغۇرچە",
    },
    uk: {
        name: "Ukrainian",
        dir: 1,
        native: "Українська",
    },
    ur: {
        name: "Urdu",
        dir: 0,
        native: "اردو",
    },
    uz: {
        name: "Uzbek",
        dir: 1,
        native: "Ўзбек",
    },
    ve: {
        name: "Venda",
        dir: 1,
        native: "Tshivenḓa",
    },
    vi: {
        name: "Vietnamese",
        dir: 1,
        native: "Việtnam",
    },
    vo: {
        name: "Volapük",
        dir: 1,
        native: "Volapük",
    },
    wa: {
        name: "Walloon",
        dir: 1,
        native: "Walon",
    },
    wo: {
        name: "Wolof",
        dir: 1,
        native: "Wollof",
    },
    xh: {
        name: "Xhosa",
        dir: 1,
        native: "isiXhosa",
    },
    yi: {
        name: "Yiddish",
        dir: 0,
        native: "ייִדיש",
    },
    yo: {
        name: "Yoruba",
        dir: 1,
        native: "Yorùbá",
    },
    za: {
        name: "Zhuang",
        dir: 1,
        native: "Cuengh / Tôô / 壮语",
    },
    zh: {
        name: "Chinese",
        dir: 1,
        native: "中文",
    },
    zu: {
        name: "Zulu",
        dir: 1,
        native: "isiZulu",
    },
    nb: {
        name: "Norwegian Bokmål",
        dir: 1,
        native: "Norsk (bokmål)",
    },
};

export default languages;
