const FOOTNOTE_REG_EX = /\[(\^|ˆ)(\d+)\]/g;

const containsFootnotes = (text) => {
    return text.match(FOOTNOTE_REG_EX);
};

module.exports = {
    containsFootnotes,
    FOOTNOTE_REG_EX,
};
