import createApiModule from "../create-api-module";
const { reducer, actions } = createApiModule("requestArticles", () => `/publications`, {
    method: "POST",
});
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface RequestArticles {
    items: RequestArticleItem[];
    orderDetails: {
        firstName: string;
        lastName: string;
        email: string;
        comment: string;
    };
}

export interface RequestArticleItem {
    pmid: string;
    description: string;
    amountOfAuthors: number;
    title: string;
}
