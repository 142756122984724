import React from "react";
import style from "./style.module.scss";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { mdiMagnify } from "@mdi/js";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { useMediaQuery, BreakPoint } from "@jmc/solid-design-system/src/hooks/useMediaQuery";
import { BaseItem } from "@algolia/autocomplete-core";

export const Highlight = ({
    attribute,
    item,
}: {
    attribute: string;
    item: { _highlightResult: BaseItem };
}): JSX.Element => {
    const isMobile = useMediaQuery(BreakPoint.md);

    return (
        <div className={style.result}>
            <Icon
                data-test-id={`search-box-result-icon`}
                icon={mdiMagnify}
                verticalAlignMiddle
                color="text-light"
                size={isMobile ? "medium" : "xs"}
            />
            <Typography color="light" size={isMobile ? "m" : "s"}>
                <div
                    className={style.highlight}
                    dangerouslySetInnerHTML={{ __html: item?._highlightResult[attribute]?.value }}
                />
            </Typography>
        </div>
    );
};

export default Highlight;
