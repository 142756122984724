import { useEffect } from "react";

import { debounce } from "../../utils/debounce";

export default function useScroll(
    callback: Function, // eslint-disable-line @typescript-eslint/ban-types
    delay = 250,
    element: Window | Document | Element = typeof window !== "undefined" ? window : undefined,
    bubbling = true,
): void {
    const debouncedCallback = debounce(callback, delay);
    useEffect((): (() => void) => {
        element?.addEventListener("scroll", debouncedCallback, bubbling);
        return (): void => {
            element?.removeEventListener("scroll", debouncedCallback, bubbling);
        };
    });
}
