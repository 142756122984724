import classnames from "classnames";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import React from "react";
import style from "./style.module.scss";
import { ModalConsumer } from "./Modal";
import useJnjBranding from "@jmc/utils/hooks/useJnjBranding";

export interface PropTypes {
    title: string | JSX.Element;
    align?: "start" | "center" | "end";
}

export const Title = ({ title, align = "start" }: PropTypes): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();

    return (
        <ModalConsumer>
            {() => (
                <div className={classnames(style.modal_title, style[`align_${align}`])}>
                    <Typography
                        variant={jnjFullBranded ? "h3" : "h5"}
                        color={jnjFullBranded ? "dark" : "primary"}
                        data-test-id="Modal.Title"
                    >
                        {title}
                    </Typography>
                </div>
            )}
        </ModalConsumer>
    );
};
