import { Button, PropTypes as ButtonProps } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Spinner } from "@jmc/solid-design-system/src/components/atoms/Spinner/Spinner";
import React from "react";

interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
    children: string | JSX.Element;
}

const renderSpinner = ({ loading = false }: LoadingButtonProps): JSX.Element => {
    return loading && <Spinner size="small" color="white" />;
};

export const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loading, ...other } = props;
    return (
        <Button {...other} accessoryRight={renderSpinner(props)}>
            {props.children}
        </Button>
    );
};
