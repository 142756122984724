import { CMSMaterialProps } from "@types";
import { Action } from "redux";

import createValueModule from "../create-value-module";

const STORAGE_KEY = "jmc.requestMaterialMarketingUrl";

export interface RequestMaterialMarketingUrl {
    webId: string;
    material: CMSMaterialProps;
}

const updateRequestMaterial = (
    requests: RequestMaterialMarketingUrl[] | null,
    newRequest: RequestMaterialMarketingUrl,
): RequestMaterialMarketingUrl[] => {
    const requestList = requests || [];
    const index = requestList.findIndex((req) => req.material.id === newRequest.material.id);
    if (index !== -1) {
        requestList[index] = newRequest;
    } else {
        requestList.push(newRequest);
    }
    return requestList;
};

const { reducer, actions } = createValueModule(
    "requestMaterialMarketingUrl",
    typeof sessionStorage !== "undefined" && sessionStorage.getItem(STORAGE_KEY)
        ? JSON.parse(sessionStorage.getItem(STORAGE_KEY))
        : [],
);

export default reducer;

export const set = (value: RequestMaterialMarketingUrl): Action => {
    if (typeof sessionStorage !== "undefined") {
        const requestMaterialMarketingUrlList = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
        const updatedRequestMaterialMarketingUrlList = updateRequestMaterial(requestMaterialMarketingUrlList, value);
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(updatedRequestMaterialMarketingUrlList));
        return actions.set(updatedRequestMaterialMarketingUrlList);
    }
    return actions.set([value]);
};

export const clear = (): Action => {
    if (typeof sessionStorage !== "undefined") {
        sessionStorage.removeItem(STORAGE_KEY);
    }
    return actions.clear();
};

export const update = actions.update;
