import React, { ReactNode } from "react";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { ModalConsumer } from "./Modal";
import style from "./style.module.scss";
import useJnjBranding from "@jmc/utils/hooks/useJnjBranding";

export interface PropTypes {
    children: ReactNode | ReactNode[];
    align?: "start" | "center" | "end";
}

export const Content = ({ children, align = "start" }: PropTypes): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();

    return (
        <ModalConsumer>
            {() => (
                <div data-test-id="Modal.Content" className={style[`align_${align}`]}>
                    <Typography variant={jnjFullBranded ? "body-02" : "body"}>{children}</Typography>
                </div>
            )}
        </ModalConsumer>
    );
};
