import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { ImagePosition } from "@jmc/solid-design-system/src/components/molecules/Card/Card";

import { CMSButtonCollectionProps } from "./CMSButtonCollectionProps";
import { CMSButtonProps } from "./CMSButtonProps";

export const CMS_CARD_VARIANT_STACKED = "Image stacked vertical";
export const CMS_CARD_VARIANT_BACKGROUND = "Image as background";

type Colors = "primary" | "secondary" | "accent 1" | "accent 2" | "white" | "dark";
type Overlay = "none" | "dark" | "light";
type ImagePlacement = "default" | "50_img_left" | "50_img_right";
type Padding = "default" | "m" | "l" | "xl";

export interface CMSCardProps {
    id: string;
    internal: { type: string };
    title: string;
    display_title: string;
    card_image: ImageQueryResponse;
    card_body: string;
    display_options: {
        background_color: Colors;
        title_color: Colors;
        text_color: Colors;
        variant_type: typeof CMS_CARD_VARIANT_STACKED | typeof CMS_CARD_VARIANT_BACKGROUND;
        image_focus: {
            image_focus: ImagePosition;
        };
        overlay: Overlay;
        image_placement: ImagePlacement;
        padding: Padding;
    };
    call_to_action: [{ button: CMSButtonProps; button_collection: CMSButtonCollectionProps }];
}
