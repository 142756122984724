import { useEffect, useState } from "react";
import yn from "yn";

export const useCookieInteraction = (): { interacted: boolean; accepted: string } => {
    const [interacted, setInteracted] = useState(false);
    const [accepted, setAccepted] = useState("");

    useEffect(() => {
        const isAlertBoxClosed = (): void => {
            try {
                process.env.GATSBY_DISABLE_COOKIEBANNER === "false" && process.env.GATSBY_ONETRUST_DATA_DOMAIN
                    ? setInteracted((window as any)?.OneTrust?.IsAlertBoxClosed())
                    : setInteracted(true);

                setAccepted((window as any)?.OptanonActiveGroups);
            } catch (error) {
                //will end up here if is alert box closed in onetrust is missing
                process.env.GATSBY_DISABLE_COOKIEBANNER === "false" && process.env.GATSBY_ONETRUST_DATA_DOMAIN
                    ? setInteracted(yn(process.env.GATSBY_DEFAULT_ACCEPT_COOKIES))
                    : setInteracted(true);

                setAccepted((window as any)?.OptanonActiveGroups);
            }
        };
        isAlertBoxClosed();
        window.addEventListener("OptanonActiveGroupsChanged", isAlertBoxClosed);
        return (): void => {
            window.removeEventListener("OptanonActiveGroupsChanged", isAlertBoxClosed);
        };
    });

    return { interacted, accepted };
};
