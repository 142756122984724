// source: https://2ality.com/2015/08/es6-map-json.html + https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string/3710226#3710226

const checkIfJson = (value) => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return JSON.parse(value);
};

const mapToJson = (map: Map<string, object>): string => {
    const obj = Object.create(null);
    for (const [k, v] of map) {
        obj[k] = v;
    }
    return JSON.stringify(obj);
};

const jsonToMap = (json: string): Map<string, object> => {
    const map = new Map();
    const object = JSON.parse(json);

    for (const key of Object.keys(object)) {
        map.set(key, object[key]);
    }
    return map;
};

export default {
    checkIfJson,
    jsonToMap,
    mapToJson,
};
