import { Question } from "@types";

import createValueModule from "../create-value-module";

export interface AskTheExperts {
    questions: { new: { newQuestions: [Question] }; responded: { respondedQuestions: [Question] } };
}

const { reducer, actions } = createValueModule("askTheExperts", {
    questions: null,
} as AskTheExperts);

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
