import createValueModule from "../create-value-module";
const { reducer, actions } = createValueModule("search", {
    searchTerm: "",
    searchResults: [],
    loading: false,
});
export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
