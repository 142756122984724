import createApiModule from "../create-api-module";

/**
 * Capture attendance of the user for an event
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/post-events-eventId-registration-automatically
 */
const { reducer, actions } = createApiModule(
    "automaticallyRegisterForEvent",
    ({ eventNumber }) => `/events/${eventNumber}/registration/automatically`,
    {
        method: "POST",
    },
);

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
