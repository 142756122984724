import React, { createContext, useContext } from "react";

interface Props {
    interestingLinks: JSX.Element;
    height?: number;
    backLiteral?: string;
    children: JSX.Element;
}

export const MegaMenuContext = createContext(null);

export const MegaMenuContextProvider = ({ interestingLinks, backLiteral, children }: Props): JSX.Element => {
    const [megaMenuOpen, setMegaMenuOpen] = React.useState(false);
    const [interestingLinksState, setInterestingLinks] = React.useState(interestingLinks);
    const [secondLevelHeight, setSecondLevelHeight] = React.useState(0);
    const [thirdLevelHeight, setThirdLevelHeight] = React.useState(0);
    const [interestingLinksHeight, setInterestingLinksHeight] = React.useState(0);
    const [closeHandler, setCloseHandler] = React.useState(() => (): void => null);
    const [activeMenuIndex, setActiveMenuIndex] = React.useState("");

    return (
        <MegaMenuContext.Provider
            value={{
                megaMenuOpen,
                setMegaMenuOpen,
                interestingLinks: interestingLinksState,
                setInterestingLinks,
                secondLevelHeight,
                setSecondLevelHeight,
                thirdLevelHeight,
                setThirdLevelHeight,
                interestingLinksHeight,
                setInterestingLinksHeight,
                closeHandler,
                setCloseHandler,
                backLiteral,
                activeMenuIndex,
                setActiveMenuIndex,
            }}
        >
            {children}
        </MegaMenuContext.Provider>
    );
};

export const useMegaMenuContext = (): {
    megaMenuOpen: boolean;
    setMegaMenuOpen: (data: JSX.Element) => null;
    interestingLinks?: JSX.Element;
    setInterestingLinks?: (data: JSX.Element) => null;
    secondLevelHeight?: number;
    setSecondLevelHeight?: (data: number) => null;
    thirdLevelHeight?: number;
    setThirdLevelHeight?: (data: number) => null;
    interestingLinksHeight?: number;
    setInterestingLinksHeight?: (data: number) => null;
    closeHandler?: () => null;
    setCloseHandler?: () => () => null;
    backLiteral?: string;
    activeMenuIndex?: string;
    setActiveMenuIndex?: (data: string) => null;
} => {
    const {
        megaMenuOpen = false,
        setMegaMenuOpen = null,
        interestingLinks = null,
        setInterestingLinks = null,
        secondLevelHeight = 0,
        setSecondLevelHeight = null,
        thirdLevelHeight = 0,
        setThirdLevelHeight = null,
        interestingLinksHeight = 0,
        setInterestingLinksHeight = null,
        closeHandler = null,
        setCloseHandler = null,
        backLiteral = null,
        activeMenuIndex = null,
        setActiveMenuIndex = null,
    } = useContext(MegaMenuContext);
    return {
        megaMenuOpen,
        setMegaMenuOpen,
        interestingLinks,
        setInterestingLinks,
        secondLevelHeight,
        setSecondLevelHeight,
        thirdLevelHeight,
        setThirdLevelHeight,
        interestingLinksHeight,
        setInterestingLinksHeight,
        closeHandler,
        setCloseHandler,
        backLiteral,
        activeMenuIndex,
        setActiveMenuIndex,
    };
};

export default { MegaMenuContextProvider, MegaMenuContext, useMegaMenuContext };
