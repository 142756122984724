import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { changeLocalization, ModuleType } from "@jmc/utils/utils/format-date";
import React, { ReactNode, useEffect, useState } from "react";
import yn from "yn";

interface PropType {
    children: ReactNode;
}

export const DayjsWrapper = ({ children }: PropType): JSX.Element => {
    const locale = useLocale();
    const [loaded, setLoaded] = useState(false);

    // await async DayJS configuration before rerendering everything
    useEffect(() => {
        setLoaded(false);
        async function changeLocale(loc: string): Promise<ModuleType> {
            return await changeLocalization(loc);
        }
        changeLocale(locale).then(() => setLoaded(true));
    }, [locale]);

    return !loaded && !yn(process.env.EVERYTHING_PUBLIC, { default: false }) ? null : <>{children}</>;
};
