import createApiModule from "../create-api-module";
/**
 * Obtain the codsId for a given webId
 * Used to track users who navigate to the site with a webId query parameter
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/get-hcps-codsId-profile
 */
const { reducer, actions } = createApiModule("codsId", ({ webId }) => `/hcps/codsid?webId=${webId}`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
