import React from "react";

import classnames from "classnames";

import { createVoiceSearch } from "./voiceSearchApi";
import { VoiceButton } from "./VoiceButton/VoiceButton";

import style from "./style.module.scss";

type CreateVoiceSearchPluginParams = {
    /**
     * Sets the language of the speech recognition.
     *
     * If not specified, this defaults to the HTML `lang` attribute value, or the user agent's language setting if that isn't set either.
     *
     * @example "en-US"
     */
    language?: string;
    renderButton?: (children: JSX.Element) => void;
};

export const createVoiceSearchPlugin = (
    language: string,
    renderButton: (children: JSX.Element) => void,
): CreateVoiceSearchPluginParams => {
    return {
        subscribe({ setQuery, setIsOpen, refresh }) {
            const isBrowser = typeof document !== "undefined";
            isBrowser &&
                requestAnimationFrame(() => {
                    const input = document.querySelector<HTMLInputElement>(".aa-Input");

                    const voiceSearch = createVoiceSearch({
                        language,
                        onTranscript(transcript) {
                            setQuery(transcript);
                            setIsOpen(true);
                            refresh();
                        },
                        onStateChange(state) {
                            if (state.status === "LISTENING" || state.status === "REQUESTING_PERMISSION") {
                                document && document?.querySelector(".aa-VoiceSearch").classList.add("active");
                            } else {
                                if (state.status === "INITIAL") {
                                    requestAnimationFrame(() => {
                                        input.focus();
                                    });
                                }
                                document && document?.querySelector(".aa-VoiceSearch").classList.remove("active");
                            }
                        },
                    });

                    if (!voiceSearch?.isBrowserSupported()) {
                        return;
                    } else {
                        renderButton &&
                            renderButton(
                                <div className={classnames(style.buttonWrapper, "aa-VoiceSearch")}>
                                    <VoiceButton voiceSearch={voiceSearch} />
                                </div>,
                            );
                        window.addEventListener("keydown", (event) => {
                            if (!document.querySelector(".aa-VoiceSearchOverlay") || event.key !== "Escape") {
                                return;
                            }
                            voiceSearch.stop();
                        });
                    }
                });
        },
        getSources() {
            return [];
        },
    };
};
