import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import axios, { AxiosInstance } from "axios";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MockAdapter from "axios-mock-adapter";
export { default as auth } from "./auth";

export const create = (): AxiosInstance => {
    const API_BASE = urlHelper.getApiBaseUrl();
    if (!API_BASE) {
        throw new ReferenceError("NO API DEFINED");
    }
    // workaround for Axios issue: https://github.com/axios/axios/issues/1661
    axios.defaults.withCredentials = true;
    const axiosInstance = axios.create({
        baseURL: API_BASE,
        headers: {
            "Content-Type": "application/json",
            "X-Country": process.env.GATSBY_COUNTRY_CODE,
            "X-ApiVersion": process.env.GATSBY_API_VERSION || "api",
        },
        validateStatus: (status) => status >= 200 && status < 400,
    });

    // Interceptor for mocking network requests during developemnt.
    // The example below will simulate the Googlebot accessing the website for indexing
    /*
        const mock = new MockAdapter(axiosInstance, { onNoMatch: "passthrough", delayResponse: 500 });
        mock.onGet(/auth\/valid/).reply(200, {
            results: [
                {
                    level: 3,
                    codsId: "GOOGLEBOT",
                    loginType: "JanRain 2.0",
                    provider: "JANRAIN_HCP",
                },
            ],
        });
    */

    return axiosInstance;
};
