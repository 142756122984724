import { Logo as LogoComponent } from "@components/Logo/Logo";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import React from "react";

import style from "./style.module.scss";

interface PropTypes {
    logo?: {
        url: string;
        gatsbyImageData?: GatsbyImageProps;
    };
}

export const Logo = ({ logo }: PropTypes): JSX.Element => {
    const getLogoFileName = (url: string): string => {
        const parts = url?.split("/");
        return parts.pop();
    };

    if (!logo) {
        return (
            <LogoComponent
                data-test-id="Logo.janssenmedicalcloud"
                id="janssenmedicalcloud"
                sizing
                width="100"
                height="30"
            />
        );
    }

    if (logo.gatsbyImageData) {
        return (
            <span data-test-id="Logo" className={style.gatsbyImageSize}>
                <GatsbyImage image={logo.gatsbyImageData} alt={"Logo"} />
            </span>
        );
    }

    return (
        <img
            src={logo.url}
            data-test-id={`Logo.${getLogoFileName(logo.url)}`}
            alt="Logo"
            className={style.imgSize}
            width={100}
            height={30}
        />
    );
};

export default Logo;
