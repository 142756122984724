import { useEffect } from "react";

const useEventListener = (
    type: string,
    listener: (e: Event) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    target: React.RefObject<any>,
    ...options: unknown[]
): void => {
    useEffect(() => {
        const currentTarget: EventTarget = target?.current ? target.current : target;
        if (currentTarget) currentTarget.addEventListener(type, listener, ...options);
        return (): void => {
            if (currentTarget) currentTarget.removeEventListener(type, listener, ...options);
        };
    }, [target, type, listener, options]);
};

export default useEventListener;
