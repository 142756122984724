export enum AccessLevel {
    level1 = "Anonymous (L1)",
    level2 = "Unverified HCP (L2)",
    level3 = "Verified HCP (L3)",
}

const getFileName = (url: string): string => {
    const urlPieces = url?.split("?");
    const parsedUrl = urlPieces?.[0];

    return parsedUrl ? parsedUrl.substring(parsedUrl.lastIndexOf("/") + 1) : "";
};

const getFileType = (fileName: string): string => {
    const regExp = /(?:\.([^.]+))?$/;

    return regExp.exec(fileName)[1] || "";
};

const getFileAccessLevel = (url: string): AccessLevel => {
    const accessLevel = new URLSearchParams(new URL(url).search).get("access_level");

    if (accessLevel) {
        return accessLevel as AccessLevel;
    }

    switch (getFileName(url).toLowerCase().substring(0, 3)) {
        case "l3-":
            return AccessLevel.level3;
        case "l2-":
            return AccessLevel.level2;
        default:
            return AccessLevel.level1;
    }
};

export default { getFileName, getFileType, getFileAccessLevel };
