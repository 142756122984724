import { default as stripSlash } from "@jmc/utils/utils/strip-slash";
import { navigate as _nav } from "gatsby";
// We want our navigation to be a function call because we want to keep our design system seperated
// Meaning we can't use <Link ... /> in our components
// We wrap it here so that gatsby build still works.
// https://github.com/gatsbyjs/gatsby/issues/10421

const navigate: (link: string | number) => void = (link: string | number) => {
    if (typeof window !== "undefined") {
        if (isNaN(link)) {
            _nav(stripSlash.stripTrailing(link));
        } else {
            _nav(link);
        }
    }
};

/**
 * Returns a function that can be used to append paths
 * with if needed a locale prefixed to them
 *
 * @param locale locale to use as prefix if the locale is known
 */
export const getLocalisedUrlFunc = (locale: string): ((arg0: string) => string) => {
    const prefixUrl = locale ? `/${stripSlash.stripLeading(locale)}` : "";
    return (path: string): string => prefixUrl + path;
};

export default navigate;
