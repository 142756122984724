import sanitizeHtml from "sanitize-html";
/**
 * Remove special characters from a string and return a safe CSS class or id.
 *
 * @param {string} raw
 */
export const cleanCSSIdentifier = (raw: string): string => {
    let id = "";
    const nonlatin = /[^\u0000-\u007f]/;
    if (nonlatin.test(raw)) {
        id = sanitizeHtml(raw, { allowedTags: [] }).replace(/\s+/g, "-");
    } else {
        id = sanitizeHtml(raw, { allowedTags: [] })
            ?.replace(/^[^a-z0-9]+|[^\w:.-]+/gi, "-")
            .replace(/^[-]+/, "");
    }

    return id || "";
};

export default cleanCSSIdentifier;
