import { ApiStageConstants } from "@redux/api-stages-enum";
import { Options, State } from "@redux/create-api-module";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import { Dispatch } from "redux";

const log = require("loglevel");

/**
 * Returns a proper request object
 *
 * @param getPath
 * @param params
 * @param options
 */
export const createRequest = (
    getPath: (params: { [x: string]: Record<string, unknown> }) => string,
    params: { [x: string]: Record<string, unknown> },
    options: Options,
): AxiosRequestConfig => ({
    url: getPath(params),
    method: options.method,
    headers: params.headers,
    data: params.data,
});
interface ParamsTypes {
    headers?: { [x: string]: string };
    data?: { [x: string]: string | Record<string, unknown> };
}

/**
 * Create the load action
 *
 * DISCLAIMER: Omitting per method caching since backend is inconsistent
 * TODO: Add support for cancellation when forcing a reload
 */
export const createLoadAction =
    (
        namespace: string,
        getPath: (params: { [x: string]: Record<string, unknown> }) => string,
        constants: ApiStageConstants,
        options: Options,
    ) =>
    (params: ParamsTypes = {}, force = false, merging = false) =>
    (
        // force is used to forcefully refetch data even if it is identical
        //merging is used to tell the redux store to merge data with existing data instead of replacing it
        //see myjmc.tsx for example of how to pass those parameters
        dispatch: Dispatch,
        getState: () => { [x: string]: State },
        client: AxiosInstance,
    ): Promise<string> => {
        const state = getState()[namespace];
        // Merge with current params
        params = merge({}, state.params, params);
        // Skip if loading still
        if (state.loading) {
            log.debug(`Still Loading ${getPath}`);
            return Promise.resolve("LOADING");
        }
        // Skip if same
        if (!force && isEqual(state.params, params)) {
            return state.error ? Promise.reject(state.error) : Promise.resolve("SUCCESS");
        }
        // Dispatch loading action
        log.debug(`Loading ${getPath}`);
        dispatch({
            type: constants.PENDING,
            payload: params,
        });
        const request = createRequest(getPath, params, options);
        return client
            .request(request)
            .then((response: { data: Record<string, unknown> }) => {
                log.debug(`Loaded ${getPath}`);
                return merging
                    ? dispatch({
                          type: constants.MERGING,
                          payload: response.data,
                      })
                    : dispatch({
                          type: constants.SUCCESS,
                          payload: response.data,
                      });
            })
            .catch((error: { response?: { data?: Record<string, unknown> } }) => {
                log.debug(`Failed Loading ${getPath}`);

                return dispatch({
                    type: constants.FAILURE,
                    payload: error?.response?.data || {},
                });
            });
    };
