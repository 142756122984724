import React, { useEffect, useRef } from "react";
import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction";
import { useEventListener } from "@jmc/utils/hooks/useEventListener";
import { useLocation } from "@reach/router";

export interface PropTypes {
    children: JSX.Element;
    name?: string;
}

const JNJBarImpression: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, name = "JNJBarImpression" } = props;
    const impressionContainer = useRef<HTMLDivElement>(null);
    const { interacted } = useCookieInteraction();
    const location = useLocation();

    useEffect(() => {
        if (interacted) {
            setTimeout(() => {
                analyticstracker().trackImpression(EventTypes.NOTIFICATION_IMPRESSION, { checkVisibility: false });
            }, 1000);
        }
    }, [impressionContainer, interacted, location.href]);

    const dataTrackingInfo = {
        name: "",
        type: "jnj-bar",
        position: "bottom",
        trigger: "default",
    };

    // track clicks registering the id of the clicked element
    useEventListener(
        "click",
        (e: Event) => {
            const path = (e as any)?.composedPath() || [];

            // keep the clicks within this navigation container
            for (let x = 0; x < path.length; x++) {
                const step = path[x];
                const container = step?.dataset?.trackingJnjbarContainer;
                if (container && container === name) {
                    // if the first found container is this one then we continue
                    break;
                } else if (container && container !== name) {
                    // if the first found container is not this one then we exit
                    return;
                }
            }

            // look for the first id in  the click path to use as linkname
            let linkname: string = undefined;
            for (let x = 0; x < path.length; x++) {
                const step = path[x];

                // don't look outside of the navigation container
                if (step === impressionContainer.current) {
                    break;
                }
                // return the first found id
                if (step.getAttribute("id") !== null) {
                    linkname = step.getAttribute("id");
                    break;
                }
            }
            // only if an id is found with the click path we track it
            if (linkname) {
                analyticstracker().trackInteraction(
                    {
                        currentTarget: e?.target,
                    },
                    {
                        changeEvent: EventTypes.NOTIFICATION_CLICK,
                        addData: { info: { ...dataTrackingInfo, name: linkname } },
                    },
                );
            }
        },
        impressionContainer,
    );

    return (
        <div
            role="none"
            data-tracking-event={EventTypes.NOTIFICATION_IMPRESSION}
            data-tracking-info={JSON.stringify(dataTrackingInfo)}
            data-test-id={`JNJBarIpression`}
            data-tracking-jnjbar-container={name}
            ref={impressionContainer}
        >
            {children}
        </div>
    );
};

export default JNJBarImpression;
