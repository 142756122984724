import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { graphql, useStaticQuery } from "gatsby";
import { CMSConfigurationsProps } from "types/CMSShellProps";

export const useShell = (): CMSConfigurationsProps => {
    const locale = useLocale() || "en-us";

    const { allContentstackShell }: { allContentstackShell: { nodes: CMSConfigurationsProps[] } } =
        useStaticQuery(graphql`
            {
                allContentstackShell {
                    nodes {
                        ...shellFields
                    }
                }
            }
        `);
    const shell = allContentstackShell?.nodes
        .filter((item: CMSConfigurationsProps) => item?.publish_details?.locale === locale)
        .pop();

    return shell;
};

export default useShell;
