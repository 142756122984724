import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";

export type Sizes = "small" | "medium" | "large" | "xl";
export type Colors = "primary" | "secondary" | "white";

interface Props {
    color?: Colors;
    size?: Sizes;
    inline?: boolean;
    className?: string;
    noMargin?: boolean;
}

export const Spinner = ({
    color = "primary",
    size = "medium",
    inline,
    className,
    noMargin = false,
    ...rest
}: Props) => (
    <div
        className={classnames(
            style.ring,
            style[`ring__${color}`],
            style[`ring__${size}`],
            inline && style.inline,
            noMargin && style.noMargin,
            className,
        )}
        data-test-id="Spinner"
        {...rest}
    >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);
