import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";

import { Icon, IconColorsType } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { mdiMicrophone } from "@mdi/js";

interface Props {
    color?: IconColorsType;
    listening?: boolean;
}

export const VoiceIcon = ({ color = "text-dark", listening = false }: Props) => (
    <>
        <Icon color={color} icon={mdiMicrophone} />
        <div className={classnames(style.recording, listening ? style.voiceListening : null)}>
            <div className={style.inner}>
                <div className={style.dot}></div>
            </div>
        </div>
    </>
);
