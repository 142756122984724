import { useState, useEffect } from "react";

export enum BreakPoint {
    xs = 0,
    sm = 576,
    md = 768,
    lg = 992,
    xl = 1200,
    xxl = 1600,
}

export const useMediaQuery = (breakPoint: BreakPoint): boolean => {
    const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (width === 0) setWidth(window.innerWidth);
            const handleWindowResize = (): void => setWidth(window.innerWidth);
            window.addEventListener("resize", handleWindowResize);
            return (): void => window.removeEventListener("resize", handleWindowResize);
        }
    }, []);

    return width <= breakPoint;
};
