import { Action, Options, State } from "@redux/create-api-module";
import merge from "deepmerge";

import { ApiStageConstants } from "./api-stages-enum";

/**
 * Returns a proper initial state object
 *
 * @param options
 */
const createInitialState = (options: Options): State => ({
    ...options.initialState,
    loading: false,
    error: null,
    data: null,
    params: null,
});

/**
 * Returns a proper reduce pending state object
 *
 * @param state
 * @param payload
 * @param options
 */
const reducePending = (state: State, payload: Record<string, unknown>, options: Options): State => ({
    ...state,
    params: payload,
    loading: true,
    error: null,
    data: options.clearOnFetch ? null : state.data,
});

/**
 * Returns a proper merging of state object
 *
 * @param state
 * @param payload
 */
const reduceMerge = (state: State, payload: Record<string, unknown>): State => ({
    ...state,
    loading: false,
    error: null,
    data: merge(state.data, payload),
});

/**
 * Returns a proper success state object
 *
 * @param state
 * @param payload
 */
const reduceSuccess = (state: State, payload: Record<string, unknown>): State => ({
    ...state,
    loading: false,
    error: null,
    data: payload,
});

/**
 * Returns a proper initial state object
 *
 * @param state
 * @param payload
 * @param options
 */
const reduceFailure = (
    state: State,
    payload: { response?: { data?: Record<string, unknown> } },
    options: Options,
): State => ({
    ...state,
    loading: false,
    error: payload?.response && payload.response.data ? payload.response.data : payload,
    data: options.clearOnError ? null : state.data,
});

/**
 * Returns a function which returns the initial state.
 *
 * @param initialState
 */
const initialStateWrapper = (initialState: State) => (): State => ({ ...initialState });

/**
 * Create the module reducer for the API states
 */
export const createReducer = (
    constants: ApiStageConstants,
    options: Options,
): ((state: State, action: Action) => State) => {
    const initialStateFn = initialStateWrapper(createInitialState(options));

    return (state: State = initialStateFn(), action: Action): State => {
        const { type, payload } = action;

        if (options.reducer) {
            const [reducedState, proceed] = options.reducer(state, action);
            if (!proceed) {
                return reducedState;
            }
            state = reducedState;
        }

        if (type === constants.RESET) {
            return initialStateFn();
        }
        if (type === constants.PENDING) {
            return reducePending(state, payload, options);
        }
        if (type === constants.MERGING) {
            return reduceMerge(state, payload);
        }
        if (type === constants.SUCCESS) {
            return reduceSuccess(state, payload);
        }
        if (type === constants.FAILURE) {
            return reduceFailure(state, payload as { response?: { data?: Record<string, unknown> } }, options);
        }

        return state;
    };
};
