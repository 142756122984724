import React, { Fragment } from "react";

import useIsClient from "../hooks/useIsClient/useIsClient";

/**
 * Wrapper component to enable Two Pass Rendering, as described at https://github.com/gatsbyjs/gatsby/discussions/17914
 * Re-render a component which is expected to change between the client and the server when it first mounts, and attach
 * a different key attribute to the component the second time around.
 *
 * This is a workaround for React Server Side Rendering and rehydration issues (see https://reactjs.org/docs/react-dom.html#hydrate)
 */
export const ClientOnly = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
    const isClient = useIsClient();

    if (!isClient) return null;
    return <Fragment key={isClient.toString()}>{children}</Fragment>;
};

export default ClientOnly;
