import { InterestingLinks } from "@jmc/solid-design-system/src/components/molecules/MegaMenu/MegaMenu";
import { ValidAuthResults } from "@redux/modules/authValid";
import { CMSInterestingMenuLink } from "@types";
import React from "react";
import { useTranslation } from "react-i18next";

import ExternalMenuLink from "./ExternalMenuLink";
import { FileAssetMenuLink } from "./FileAssetMenuLink";
import { InternalMenuLink } from "./InternalMenuLink";

interface PropTypes {
    interestingLinks: CMSInterestingMenuLink[];
    authData: ValidAuthResults;
}

const JMCInterestingLinks = ({ interestingLinks, authData }: PropTypes): JSX.Element | null => {
    const { t } = useTranslation();

    if (interestingLinks && interestingLinks?.length === 0) return null;

    return (
        <InterestingLinks title={t("Interesting Links", { ns: "common" })}>
            {interestingLinks?.map((link: CMSInterestingMenuLink, index: number) => {
                if (link.internal_link) {
                    return (
                        <InternalMenuLink
                            key={`interesting_links.${link.internal_link.menu_label}.${index}`}
                            title={link.internal_link.menu_label}
                            reference={link.internal_link.link?.[0]}
                            visibility={link.internal_link.visibility}
                            authData={authData}
                            linkname={`interesting_links.${link.internal_link.menu_label}`}
                        />
                    );
                } else if (link.external_link) {
                    return (
                        <ExternalMenuLink
                            key={`interesting_links.${link.external_link.link.title}.${index}`}
                            url={link.external_link.link.href}
                            title={link.external_link.link.title}
                            id={`interesting_links.${link.external_link.link.title}`}
                        />
                    );
                } else if (link.file_asset) {
                    return (
                        <FileAssetMenuLink
                            key={`interesting_links.${link.file_asset.menu_label}.${index}`}
                            url={link.file_asset.file_asset_upload.url}
                            title={link.file_asset.menu_label}
                            visibility={link.file_asset.visibility}
                            authData={authData}
                            id={`interesting_links.${link.file_asset.menu_label}`}
                        />
                    );
                }
            })}
        </InterestingLinks>
    );
};

export default JMCInterestingLinks;
